import * as types from '../types'

export const submitIndividualLeaseDataReducer = (state = {data: []}, action) => {
    switch(action.type) {
        case types.SUBMIT_INDIVIDUAL_LEASE_DATA_REQUEST:
            return { 
                loading: true, data: []
            }
        case types.SUBMIT_INDIVIDUAL_LEASE_DATA_SUCCESS:
            return { 
                loading: false, data: action.payload
            }
        case types.SUBMIT_INDIVIDUAL_LEASE_DATA_FAIL:
            return { 
                loading: false, error: action.payload 
            }
       
        default:
            return state;
    }
}

export const submitCompanyLeaseDataReducer = (state = {companyData: []}, action) => {
    switch(action.type) {
        case types.SUBMIT_COMPANY_LEASE_DATA_REQUEST:
            return { 
                load: true, companyData: []
            }
        case types.SUBMIT_COMPANY_LEASE_DATA_SUCCESS:
            return { 
                load: false, companyData: action.payload
            }
        case types.SUBMIT_COMPANY_LEASE_DATA_FAIL:
            return { 
                load: false, err: action.payload 
            }
       
        default:
            return state;
    }
}

export const getLeaseOrderReducer = (state = {allLeaseOrders: []}, action) => {
    switch(action.type) {
        case types.FETCH_LEASE_ORDERS_REQUEST:
            return { 
                loading: true, allLeaseOrders: []
            }
        case types.FETCH_LEASE_ORDERS_SUCCESS:
            return { 
                loading: false, allLeaseOrders: action.payload
            }
        case types.FETCH_LEASE_ORDERS_FAIL:
            return { 
                loading: false, error: action.payload 
            }
       
        default:
            return state;
    }
}

export const payLeaseDepositReducer = (state = {deposit: []}, action) => {
    switch(action.type) {
        case types.PAY_LEASE_DEPOSIT_REQUEST:
            return { 
                depositLoading: true, deposit: []
            }
        case types.PAY_LEASE_DEPOSIT_SUCCESS:
            return { 
                depositLoading: false, deposit: action.payload
            }
        case types.PAY_LEASE_DEPOSIT_FAIL:
            return { 
                depositLoading: false, error: action.payload 
            }
       
        default:
            return state;
    }
}

export const getLeaseHistoryReducer = (state = {history: []}, action) => {
    switch(action.type) {
        case types.GET_LEASE_HISTORY_REQUEST:
            return { 
                historyLoading: true, history: []
            }
        case types.GET_LEASE_HISTORY_SUCCESS:
            return { 
                historyLoading: false, history: action.payload
            }
        case types.GET_LEASE_HISTORY_FAIL:
            return { 
                historyLoading: false, historyError: action.payload 
            }
       
        default:
            return state;
    }
}