import React from 'react';
import { Form } from 'react-bootstrap';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  {
    qty: 0,
    amount: 0,
  },
  {
    qty: 0,
    amount: 0
  },
  {
    qty: 0,
    amount: 0
  },
  {
    qty: 0,
    amount: 0
  },
  {
    qty: 0,
    amount: 0
  },
  {
    qty: 0,
    amount: 0
  },
];

// const getMonth = (e) =>{
//   console.log(e.target.value)
// }

const Chart = ({onSelect}) => {
    return (
      <>
        <div className='chart-header d-flex justify-content-between'>
          <div className='d-flex mt-4 ml-4'>
            <p>Total Purchase</p>
            {/* <span className="material-icons">info</span> */}
          </div>
          <Form.Control onChange={onSelect} className='w-50 cursor-pointer border-light-grey mt-3 mx-4 ' type='month'/>
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              bottom: 60,
              left: 0
            }}
          >
            <CartesianGrid strokeDasharray="0" horizontal="true" vertical="" />
            <XAxis dataKey='qty' axisLine="false" tickLine=""  />
            <YAxis orientation="left"  axisLine="false" tickLine="" padding={{ top: 20 }}  type="number" domain={[0,'auto']}/>
            <Tooltip />
            {/* <Legend verticalAlign="top" align='right' iconType="rect" /> */}
            <Area name="qty" type="monotone" dataKey="qty" stroke="#3503AC" fill='#F7F8FB' />
            <Area name="amount" type="monotone" dataKey="amount" stroke="#DFE0EB" fill="#F7F8FB" />
          </AreaChart>
        </ResponsiveContainer>
      </>
    );
}

export default Chart