import React from "react";
import { Container } from "react-bootstrap";
import customer from "../../Assets/demo-customer.jpg";
// import Swiper core and required modules
import SwiperCore, { Pagination, Scrollbar, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

// install Swiper modules
SwiperCore.use([Pagination, Scrollbar, Autoplay]);

function Testimonial() {
  const testimonialData = [
    {
      id: 1,
      name: "DAYO",
      details:
        "I work for a corporate organisation here in Lagos, Nigeria. In need of a new car, I approached Leasehouz. I CHOSE and took DELIVERY of a Toyota Camry. With an initial payment of 30% of the cost of the asset, I paid for the car in CONVENIENT MONTHLY INSTALMENTS over an agreed period.I PAID SMALL SMALL.The offering comes with registration, tracking and insurance all embedded in the deal.The process was seamless.The experience, a DELIGHT",
    },
    {
      id: 2,
      name: "ATINUKE",
      details:
        "I work for a private company here in Lagos. My husband and I decided to buy a new TV set, a washing machine, and some air conditioners for our home.Via Leasehouz, we took delivery and now enjoy the use of these items while we PAY SMALL SMALL.The process is easy. The experience is a DELIGHT.",
    },
    {
      id: 3,
      name: "DAYO",
      details:
        "I work for a corporate organisation here in Lagos, Nigeria. In need of a new car, I approached Leasehouz. I CHOSE and took DELIVERY of a Toyota Camry. With an initial payment of 30% of the cost of the asset, I paid for the car in CONVENIENT MONTHLY INSTALMENTS over an agreed period. I PAID SMALL SMALL. The offering comes with registration, tracking and insurance all embedded in the deal. The process was seamless. The experience, a DELIGHT.",
    },
    {
      id: 4,
      name: "ORL",
      details:
        "A medium corporate here in Lagos, we approached  Leasehouz for our vehicle requirements. Within a few days, we concluded a deal. The service included asset inspection, financing, insurance, tracking, registration, and delivery. Via Leasehouz , we have taken delivery of our choice vehicles. We paid in CONVENIENT MONTHLY INSTALMENTS over 24 months. The service is outstanding.",
    },
    {
      id: 5,
      name: "DEJI",
      details:
        "I am the promoter of a modern-day hotel here in Lagos. To support the expansion of our hotel facilities, we approached Leasehouz for the purchase of various items such as FRIDGES, TV SETS, VEHICLES, COOKERS, WASHING MACHINES etc. required to furnish the new extension. Via Leasehouz, we now enjoy the use of all the items required to run our new facility. NO COLLATERAL required; we pay in convenient monthly instalments. We PAY SMALL SMALL.",
    },
    {
      id: 6,
      name: "OKEY",
      details:
        "On my second deal with Leasehouz, I have taken delivery of a pre-owned Toyota Sienna. With an initial payment of 30% of the cost of the vehicle, I will pay for the asset in equal monthly instalments over a period of 15 months. It was a bundled service that I enjoyed. The process was easy and the payments, very convenient.",
    },
    {
      id: 7,
      name: "AHMED",
      details:
        "I took delivery of a new generator set. I paid for it in convenient monthly installments, while I enjoyed the possession and use of the item. Having concluded with the payments, I still enjoy the use of this equipment. The team supported me in selecting, purchasing, insuring and the eventual asset delivery. With Leasehouz, it is easy to select and take delivery of that required asset. The payment plan is very convenient.",
    },
  ];

  return (
    <>
      <div className="bg-white py-5">
        <Container className="" fluid="md">
          <h3>What our customers are saying</h3>
          <Swiper
            spaceBetween={100}
            slidesPerView={1}
            centeredSlides
            autoplay={{ delay: 3000 }}
            speed={3000}
            pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
          >
            {testimonialData.map((data) => {
              return (
                <SwiperSlide key={data.id} className="mt-5 testimonial-wrapper">
                  <div className="d-flex">
                    <span className="material-icons text-blue font-large">
                      format_quote
                    </span>
                    <p className="ml-2 mw-500">{data.details}</p>
                  </div>
                  <div className="d-flex">
                    {/* <div className="width-50 height-50 bg-blue rounded-circle">
                      <img
                        src={customer}
                        alt="customer"
                        className="w-100 h-100 block object-fit-cover rounded-circle"
                      />
                    </div> */}
                    <div className="ml-3">
                      <span className="text-bold block">{data.name}</span>
                      {/* <span>CEO, Doe Electronics</span> */}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Container>
      </div>
    </>
  );
}

export default Testimonial;
