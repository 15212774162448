import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changePassword, logout } from "../redux/actions/authActions";
import { Cookies } from "react-cookie";
import { useHistory } from "react-router";
import Message from "../Components/Reusable/Message";
import Loader from "../Components/Reusable/Loader";
import { Row, Col, Form } from "react-bootstrap";

function ChangePassword() {
  const [errors, setErrors] = useState({});
  const [toggleOldPassword, settoggleOldPassword] = useState("password");
  const [toggleConfirmPassword, settoggleConfirmPassword] =
    useState("password");
  const [toggleNewPassword, settoggleNewPassword] = useState("password");
  const handleOldPassword = () => {
    toggleOldPassword === "password"
      ? settoggleOldPassword("text")
      : settoggleOldPassword("password");
  };
  const handleConfirmPassword = () => {
    toggleConfirmPassword === "password"
      ? settoggleConfirmPassword("text")
      : settoggleConfirmPassword("password");
  };
  const handleNewPassword = () => {
    toggleNewPassword === "password"
      ? settoggleNewPassword("text")
      : settoggleNewPassword("password");
  };

  const dispatch = useDispatch();
  const cookies = new Cookies();
  const history = useHistory();

  const [passwordDetails, setpasswordDetails] = useState({
    old_password: "",
    new_password: "",
    confirmPassword: "",
  });
  const { old_password, new_password, confirmPassword } = passwordDetails;

  const fetchNewPassword = useSelector((state) => state.fetchNewPassword);
  const { updatedPassword, loading, error } = fetchNewPassword;

  //form validation
  const findFormErrors = () => {
    const newErrors = {};
    if (!old_password || old_password === "")
      newErrors.old_password = "Old Password is required!";

    if (!new_password || new_password === "")
      newErrors.new_password = "New Password is required!";

    if (!confirmPassword || confirmPassword === "")
      newErrors.confirmPassword = "Confirm Password is required!";
    else if (confirmPassword !== new_password)
      newErrors.confirmPassword = "Password does not match!";

    return newErrors;
  };

  const getFormValues = (e) => {
    setpasswordDetails({ ...passwordDetails, [e.target.name]: e.target.value });

    // Check and see if errors exist and remove them from the error object:
    if (!!errors[e.target.name])
      setErrors({
        ...errors,
        [e.target.name]: null,
      });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
      console.log(newErrors);
    } else {
      console.log(passwordDetails);
      dispatch(changePassword(old_password, new_password, confirmPassword));
    }
  };

  useEffect(() => {
    if (updatedPassword !== undefined) {
      cookies.remove("token");
      dispatch(logout());
      setTimeout(() => {
        history.push("/login");
        window.location.reload();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPassword !== undefined]);

  return (
    <>
      <Col className="pt-2 px-0">
        <h5 className="text-bold">Change Password</h5>
        {error ? (
          <Message variant="danger">{error}</Message>
        ) : updatedPassword ? (
          <Message variant="success">Password changed successfully!</Message>
        ) : null}
        <Form className="mt-4">
          <Row>
            <Col className="pl-0 profile-form-mobile" xs={12} lg={6}>
              <Form.Group as={Col} className="mb-3" id="old_password">
                <Form.Label>Old Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={toggleOldPassword}
                    name="old_password"
                    id="old_password"
                    onChange={getFormValues}
                    value={old_password}
                    isInvalid={!!errors.old_password}
                    className="bg-light-grey"
                  />
                  <i
                    onClick={handleOldPassword}
                    className="material-icons position-absolute cursor-pointer"
                    style={{ right: 10, top: "10px" }}
                  >
                    {toggleOldPassword === "password"
                      ? "visibility_off"
                      : "visibility"}
                  </i>
                  <Form.Control.Feedback type="invalid">
                    {errors.old_password}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group as={Col} className="mb-3" id="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={toggleConfirmPassword}
                    name="confirmPassword"
                    id="confirmPassword"
                    onChange={getFormValues}
                    value={confirmPassword}
                    isInvalid={!!errors.confirmPassword}
                    className="bg-light-grey"
                  />
                  <i
                    onClick={handleConfirmPassword}
                    className="material-icons position-absolute cursor-pointer"
                    style={{ right: 10, top: "10px" }}
                  >
                    {toggleConfirmPassword === "password"
                      ? "visibility_off"
                      : "visibility"}
                  </i>
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
            <Col className="pl-0 profile-form-mobile" xs={12} lg={6}>
              <Form.Group as={Col} className="mb-3" id="new_password">
                <Form.Label>New Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={toggleNewPassword}
                    name="new_password"
                    onChange={getFormValues}
                    value={new_password}
                    isInvalid={!!errors.new_password}
                    className="bg-light-grey"
                  />
                  <i
                    onClick={handleNewPassword}
                    className="material-icons position-absolute cursor-pointer"
                    style={{ right: 10, top: "10px" }}
                  >
                    {toggleNewPassword === "password"
                      ? "visibility_off"
                      : "visibility"}
                  </i>
                  <Form.Control.Feedback type="invalid">
                    {errors.new_password}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
            <div className="mx-auto my-3 ">
              <button
                className="bg-blue text-white border-blue rounded-3 py-1 px-4 mt-2"
                onClick={handleChangePassword}
              >
                {loading ? <Loader /> : "Change Password"}
              </button>
            </div>
          </Row>
        </Form>
      </Col>
    </>
  );
}

export default ChangePassword;
