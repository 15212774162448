import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Badge, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Components/Reusable/Loader";
import NoData from "../Components/Vendor/Overview/NoData";
import {
  fetchLeaseOrders,
  getLeaseHistory,
  payLeaseDeposit,
} from "../redux/actions/leaseActions";
import arrowRight from "../Assets/arrow-right.png";
import Message from "../Components/Reusable/Message";
import ShowLeaseHistory from "../Components/Payments/Lease/ShowLeaseHistory";

function Leases() {
  const [openModal, setopenModal] = useState(false);
  const [leaseHistoryData, setLeaseHistoryData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLeaseOrders());
  }, [dispatch]);

  const leaseOrders = useSelector((state) => state.leaseOrders);
  const { allLeaseOrders, loading } = leaseOrders;

  const leaseDeposit = useSelector((state) => state.leaseDeposit);
  const { deposit, depositLoading, error } = leaseDeposit;

  const makeLeaseDeposit = (leaseType, trackingCode) => {
    const data = {
      order_code: trackingCode,
      loan_type: leaseType,
    };
    dispatch(payLeaseDeposit(data));
  };

  const leaseHistory = useSelector((state) => state.leaseHistory);
  const { history } = leaseHistory;

  const fetchLeaseHistory = (trackingCode) => {
    dispatch(getLeaseHistory(trackingCode));
  };

  useEffect(() => {
    if (history.length) {
      setLeaseHistoryData(history);
      setopenModal(true);
    }
  }, [history]);

  useEffect(() => {
    if (deposit && deposit.success) {
      window.open(deposit.data.payment.url);
    }
  }, [deposit]);

  console.log(allLeaseOrders?.length);

  return (
    <>
      <ShowLeaseHistory
        historyData={leaseHistoryData}
        open={openModal}
        onClose={() => setopenModal(false)}
      />
      {error ? <Message variant="danger">{error.message}</Message> : null}
      {loading ? (
        <Loader />
      ) : allLeaseOrders?.data && allLeaseOrders?.data?.length === 0 ? (
        <NoData name="Lease Orders" />
      ) : allLeaseOrders.data && allLeaseOrders.data.length ? (
        <>
          <div className="bg-white m-auto container-scroll">
            <Col xs={12} md={12} lg={12} className="">
              <Table responsive="lg" className="table-spacing">
                <thead>
                  <tr className="">
                    <th className="text-muted">Date</th>
                    <th className="text-muted">Product</th>
                    <th className="text-muted">Type</th>
                    <th className="text-muted">Price</th>
                    <th className="text-muted">Deposit</th>
                    <th className="text-muted">Monthly Payment</th>
                    <th className="text-muted">Tenure</th>
                    <th className="text-muted">Shipping</th>
                    <th className="text-muted">Status</th>
                    <th className="text-muted"></th>
                  </tr>
                </thead>
                <tbody>
                  {allLeaseOrders &&
                    allLeaseOrders?.data?.map((item) => (
                      <tr key={item.id} className="">
                        <td>{dayjs(item.created_at).format("D/MM/YYYY")}</td>
                        <td>{item.carts[0]?.product.name}</td>
                        <td>
                          {item.individual_lease_profile
                            ? "Individual Lease"
                            : "Company Lease"}
                        </td>
                        <td>{item.amount.toLocaleString("en")}</td>
                        {item && item.individual_lease_profile !== null && (
                          <td>
                            {item.individual_lease_profile.deposit_amount.toLocaleString(
                              "en"
                            )}
                          </td>
                        )}
                        {item && item.company_lease_profile !== null && (
                          <td>
                            {item.company_lease_profile.deposit_amount.toLocaleString(
                              "en"
                            )}
                          </td>
                        )}
                        {item && item.individual_lease_profile !== null && (
                          <td>
                            {item.individual_lease_profile.repayment_amount.toLocaleString(
                              "en"
                            )}
                          </td>
                        )}
                        {item && item.company_lease_profile !== null && (
                          <td>
                            {item.company_lease_profile.repayment_amount.toLocaleString(
                              "en"
                            )}
                          </td>
                        )}
                        {item && item.individual_lease_profile !== null && (
                          <td>
                            {item.individual_lease_profile.proposed_tenure}{" "}
                            months
                          </td>
                        )}
                        {item && item.company_lease_profile !== null && (
                          <td>
                            {item.company_lease_profile.proposed_tenure} months
                          </td>
                        )}
                        <td>{item.shipping_fee.toLocaleString("en")}</td>
                        {
                          <td>
                            <Badge
                              pill
                              className={
                                (item.status_id === 1
                                  ? "bg-success"
                                  : item.status_id === 2
                                  ? "bg-warning"
                                  : item.status_id === 8
                                  ? "bg-warning text-light-grey"
                                  : item.status_id === 4 || 6
                                  ? "bg-success"
                                  : null) + " text-light py-2 px-2"
                              }
                            >
                              {item.status_id === 1
                                ? "Active"
                                : item.status_id === 2
                                ? "Inactive"
                                : item.status_id === 4
                                ? "Paid"
                                : item.status_id === 6
                                ? "Completed"
                                : item.status_id === 7
                                ? "Loan"
                                : item.status_id === 8
                                ? "In progress"
                                : null}
                            </Badge>
                          </td>
                        }
                        <td className="w-auto">
                          {item.status_id === 8 && item.transaction === null ? (
                            <div className="d-flex align-items-start">
                              <button
                                onClick={() =>
                                  makeLeaseDeposit(
                                    item.individual_lease_profile
                                      ? item.individual_lease_profile.lease_type
                                      : "company",
                                    item.tracking_code
                                  )
                                }
                                type="submit"
                                style={{ width: "130px", padding: "0 11px" }}
                                className="border-0 py-2"
                              >
                                {depositLoading ? <Loader /> : "make deposit"}
                              </button>
                            </div>
                          ) : null}
                        </td>
                        {item.transaction && (
                          <td className="cursor-pointer">
                            <img
                              src={arrowRight}
                              onClick={() =>
                                fetchLeaseHistory(item.tracking_code)
                              }
                              alt="right arrow"
                            />
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </div>
        </>
      ) : null}
    </>
  );
}

export default Leases;
