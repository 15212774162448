import React from "react";
import { Nav, Row, Tab } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Footer from "../Components/Reusable/Footer";
import Header from "../Components/Reusable/Header";
import Financial from "../Assets/financial.png";
import Leases from "../Components/OurServices/Leases";
import OperatingLease from "../Components/OurServices/OperatingLease";
import FleetManagement from "../Components/OurServices/FleetManagement";
import FinancialIntermediary from "../Components/OurServices/FinancialIntermediary";
import Investments from "../Components/OurServices/Investments";
import Car from "../Assets/apple-products.jpg";

function OurServices() {
  return (
    <>
      <div className="bg-light-grey w-100">
        <Container className="py-3 mb-5" fluid="md">
          <Header />
          <div style={{ height: "350px" }}>
            <img
              src={Car}
              alt=""
              className="w-100 h-100 object-fit-cover"
              style={{ objectPosition: "center bottom" }}
            />
          </div>
        </Container>
        <div className="bg-white">
          <Container className="pb-5" fluid="md">
            <Row className="text-left my-5">
              <Col sm={12} lg={6} className="pt-5">
                <h3 className="underline-heading mb-4">What We Offer</h3>
                <p>
                  We offer a wide range of services, both financial and non
                  financial tailored to meet the customer needs. We sell
                  convenience. Our core services include:
                  <strong>
                    {" "}
                    Leasing, Fleet Management, Financial Intermediary &
                    Advisory, Investments
                  </strong>{" "}
                </p>
                <p>Working with us, you enjoy all of the following:</p>
                <div>
                  <div className="d-flex">
                    <span class="material-icons-outlined text-blue">
                      task_alt{" "}
                    </span>
                    <p>
                      &nbsp;&nbsp;&nbsp;Immediate possession of choice assets
                    </p>
                  </div>
                  <div className="d-flex">
                    <span class="material-icons-outlined text-blue">
                      task_alt{" "}
                    </span>
                    <p> &nbsp;&nbsp;&nbsp;Convenient repayment plan</p>
                  </div>
                  <div className="d-flex">
                    <span class="material-icons-outlined text-blue">
                      task_alt{" "}
                    </span>
                    <p>&nbsp;&nbsp;&nbsp;Enhanced lifestyle</p>
                  </div>
                  <div className="d-flex">
                    <span class="material-icons-outlined text-blue">
                      task_alt{" "}
                    </span>
                    <p>&nbsp;&nbsp;&nbsp;Better financial reporting</p>
                  </div>
                  <div className="d-flex">
                    <span class="material-icons-outlined text-blue">
                      task_alt{" "}
                    </span>
                    <p>&nbsp;&nbsp;&nbsp;Focus on core business</p>
                  </div>
                  <div className="d-flex">
                    <span class="material-icons-outlined text-blue">
                      task_alt{" "}
                    </span>
                    <p>&nbsp;&nbsp;&nbsp;Retained capital</p>
                  </div>
                  <div className="d-flex">
                    <span class="material-icons-outlined text-blue">
                      task_alt{" "}
                    </span>
                    <p>&nbsp;&nbsp;&nbsp;Better Investment options</p>
                  </div>
                </div>
              </Col>
              <Col sm={12} lg={6} className="pt-5">
                <div className="w-100 h-450">
                  <img
                    src={Financial}
                    alt=""
                    className="w-100 h-100 object-fit-cover"
                  />
                </div>
              </Col>
            </Row>
            <Tab.Container
              id="left-tabs-example"
              className="mt-5"
              defaultActiveKey="Leasing"
            >
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="Leasing">Leasing</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Operating Lease">
                        Operating Lease
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Fleet Management">
                        Fleet Management
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Financial Intermediary">
                        Financial Intermediary
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Investments">Investments</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="Leasing">
                      <Leases />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Operating Lease">
                      <OperatingLease />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Fleet Management">
                      <FleetManagement />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Financial Intermediary">
                      <FinancialIntermediary />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Investments">
                      <Investments />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </div>
        {/* <Container className='py-3 mb-5 bg-white' fluid='md'>
                    
                </Container> */}
        <Footer />
      </div>
    </>
  );
}

export default OurServices;
