import React, { useState, useEffect } from "react";
//import { useParams } from 'react-router-dom'
import {
  Alert,
  Button,
  Form,
  Row,
  Col,
  Toast,
  Container,
  InputGroup,
} from "react-bootstrap";
import OnBoardNavbar from "../Reusable/OnBoardNavbar";
import { useSelector, useDispatch } from "react-redux";
import RegisterModal from "./RegisterModal";
import Loader from "../Reusable/Loader";
import Message from "../Reusable/Message";
import { useCookies } from "react-cookie";
import { verifyUser, resendCode } from "../../redux/actions/authActions";
import { Formik } from "formik";
import * as yup from "yup";
import { resendCodeReducer } from "../../redux/reducers/userReducers";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  token: yup.string().min(4, "Must be at least 4 characters"),
});

const Verification = ({ location, history }) => {
  const [cookies, setCookie] = useCookies(["token"]);
  const [popup, setPopup] = useState(false);

  const [user, setUser] = useState({
    email: "",
    token: "",
  });

  const { email, token } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  //SHOW WARNING ALERT
  const [visible, setvisible] = useState(true);
  const [message, setMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [emailForResend, setEmailForResend] = useState("");
  const dispatch = useDispatch();

  const userVerify = useSelector((state) => state.userVerify);
  const resendVerifyCode = useSelector((state) => state.resendVerifyCode);

  const { userInfo, error, loading } = userVerify;
  const codeUserInfo = resendVerifyCode.userInfo;
  const codeError = resendVerifyCode.error;
  const codeLoading = resendVerifyCode.loading;

  //const [isOpen, setIsOpen] = useState(true)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //DISMISS WARNING ALERT
  const onDismiss = () => setvisible(false);

  useEffect(() => {
    // if logged in, then redirect
    if (userInfo) {
      const accessToken = userInfo.data.accessToken;
      setCookie("token", accessToken, { maxAge: 518400 });
      if (location.state.url && location.state.url !== undefined) {
        history.push(location.state.url);
      } else {
        handleShow(true);
        setTimeout(() => {
          history.push("/");
        }, 1000);
      }
    } else {
      // if resending code produces error
      if (codeError) {
        setMessage(codeError);
        setTimeout(() => {
          setMessage("");
        }, 1000);
      }

      if (codeUserInfo) {
        // if successfully delivered
        // message from server response
        setSuccessMessage(codeUserInfo.message);
        setTimeout(() => {
          setSuccessMessage("");
        }, 1000);
      }
    }

    // eslint-disable-next-line
  }, [history, userInfo, resendVerifyCode]);

  //submit the form
  const submitHandler = (value) => {
    const { email, token } = value;
    if (email === "" || token === "") {
      setMessage("Fill in all the forms to complete verification");

      setTimeout(() => {
        setMessage("");
      }, 1000);

      return;
    }

    // DISPATCH verifyUser ACTION
    dispatch(verifyUser(email, token));
  };

  const resend = () => {
    // if email is not in location state
    if (location.state !== undefined) {
      dispatch(resendCode(location.state.email));
    }
    // now check if email is inside input
    else if (location.state === undefined && emailForResend === "") {
      setMessage("Please enter your email only");
      setTimeout(() => {
        setMessage("");
      }, 1000);
    }
    // use email inside input
    else {
      dispatch(resendCode(emailForResend));
    }
  };

  return (
    <>
      <RegisterModal show={show} onClose={handleClose} />
      <OnBoardNavbar navlink={"Register"} />
      <div className="mt-5">
        <h1 className="text-center text-responsive">Verify Your Account</h1>
        <p className="text-center">Kindly enter verification code</p>
      </div>
      {message && <Message variant="danger">{message}</Message>}
      {successMessage && <Message variant="success">{successMessage}</Message>}
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}

      {/* TO notify the user that and otp has been sent, this is the popup modal */}
      <Container className="mw-400 mx-auto my-0" fluid>
        <Row>
          <Col>
            <Toast
              onClose={() => setPopup(false)}
              show={popup}
              delay={3000}
              autohide
            >
              <Toast.Body className="bg-success text-light">
                OTP sent! check your email
              </Toast.Body>
            </Toast>
          </Col>
        </Row>
      </Container>
      <Formik
        validationSchema={schema}
        onSubmit={submitHandler}
        initialValues={{
          email: "",
          token: "",
        }}
      >
        {({
          handleSubmit,
          validate,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="mw-400 mx-auto">
            <Alert
              className="my-5"
              variant="warning"
              show={visible}
              onClick={onDismiss}
            >
              Do not refresh or close page until verification is complete!
            </Alert>
            <Form.Group controlId="validationFormik03">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                className="bg-light border-0"
                type="text"
                name="email"
                value={values.email}
                // onChange={handleChange}
                onChange={(e) => {
                  // call the built-in handleChange for formik
                  handleChange(e);
                  // and do something about e
                  let email = e.currentTarget.value;
                  setEmailForResend(email);
                }}
                isInvalid={!!errors.email}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationFormik04" className="mt-3">
              <Form.Label>Token</Form.Label>
              <Form.Control
                className="bg-light border-0"
                type="text"
                name="token"
                value={values.token}
                onChange={handleChange}
                isInvalid={!!errors.token}
              />
              <Form.Control.Feedback type="invalid">
                {errors.token}
              </Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" block className="bg-blue border-0 mt-3 py-2">
              Verify and sign in
            </Button>
            {codeLoading ? (
              <Loader />
            ) : (
              <p className="border-0 mt-3 py-2">
                Didn't get token?{" "}
                <span onClick={resend} className="text-blue cursor-pointer">
                  {" "}
                  resend{" "}
                </span>{" "}
              </p>
            )}
            {/* {show ? '' : <RegisterModal show={show} onClose={handleClose} />} */}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Verification;
