import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Form } from "react-bootstrap";
import { getShippingInfo } from "../../../redux/actions/productActions";
import { getStates } from "../../../redux/actions/authActions";
import { Link } from "react-router-dom";

const ShippingDetails = ({ onSelect, shippingDetails, setShippingDetails }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getShippingInfo());
    dispatch(getStates(160));
  }, [dispatch]);

  const shippingInfo = useSelector((state) => state.shippingInfo);
  const { shipping } = shippingInfo;

  //FETCH STATES
  const allStates = useSelector((state) => state.getStates);
  const { states } = allStates;

  const getValues = (e) => {
    setShippingDetails({ ...shippingDetails, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Row className="bg-white p-3 my-4">
        <Col xs={12} md={6} className="py-2">
          <h5>Shipping Method</h5>
          <Card className="shipping-card">
            {shipping?.map((data) => {
              return (
                <Card.Body
                  key={data.name}
                  className="shipping-info-card cursor-pointer d-flex justify-content-between align-items-center"
                >
                  <div>
                    <Card.Title>{data.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-black">
                      {data.from}-{data.to} {data.duration} delivery
                    </Card.Subtitle>
                  </div>
                  <Form.Group controlId="shipping-fee">
                    <Form.Label className="mx-2 text-bold">
                      &#8358; {data?.price?.toLocaleString("en")}
                    </Form.Label>
                    <Form.Check
                      inline
                      name="fee"
                      type="radio"
                      id="radio-1"
                      value={data.price}
                      className="cursor-pointer"
                      onChange={getValues}
                    />
                  </Form.Group>
                </Card.Body>
              );
            })}
            <Form.Check type="checkbox" id="terms-checkbox" className="my-4">
              <Form.Check.Input
                type="checkbox"
                className="cursor-pointer"
                isValid
              />
              <Form.Check.Label>
                I agree to Leasehouz{" "}
                <Link to="/terms" className="text-decoration-none">
                  Terms and Conditions
                </Link>
              </Form.Check.Label>
            </Form.Check>
          </Card>
        </Col>
        <Col xs={12} md={6} className="py-2 shipping-address">
          <Form className="">
            <h5>Shipping Address</h5>
            <Form.Group className="mt-4 mb-3" controlId="state">
              <Form.Label>State</Form.Label> <span className="text-red">*</span>
              <Form.Control
                as="select"
                name="state_id"
                onChange={getValues}
                type="text"
                className="bg-light-grey"
                aria-label="Default select example"
              >
                <option>Select a state</option>
                {states?.map((state) => {
                  return (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="city">
              <Form.Label>City</Form.Label>
              <span className="text-red">*</span>
              <Form.Control
                type="text"
                className="bg-light-grey"
                name="city"
                onChange={getValues}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="address">
              <Form.Label> Address</Form.Label>
              <span className="text-red">*</span>
              <Form.Control
                type="text"
                className="bg-light-grey"
                name="address"
                onChange={getValues}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="landmark">
              <Form.Label>Nearest Landmark</Form.Label>
              <span className="text-red">*</span>
              <Form.Control
                type="text"
                className="bg-light-grey"
                name="landmark_nearest_bus_stop"
                onChange={getValues}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <span className="text-red">*</span>
              <Form.Control
                as="textarea"
                className="bg-light-grey"
                name="description"
                onChange={getValues}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <div className="d-flex justify-content-end my-5">
        <button onClick={onSelect} className="border-0 px-5 py-2">
          Next
        </button>
      </div>
    </>
  );
};

export default ShippingDetails;
