import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Components/Reusable/Footer";
import Header from "../Components/Reusable/Header";
import shopping from "../Assets/shoppingcart.jpg";
import Ifeanyi from "../Assets/Ifeanyi.jpg";
import Tosin from "../Assets/tosin.jpg";
import Martina from "../Assets/Martina.jpg";
import Oreoluwa from "../Assets/Oreoluwa.jpg";
import Saheed from "../Assets/saheed.jpg";
import TeamInfoModal from "../Components/OurServices/TeamInfoModal";
import Team from "../Assets/team.jpg";

function AboutUs() {
  const [showModal, setshowModal] = useState(false);
  const [teamInfo, setteamInfo] = useState();

  const showTeamInfoModal = (teamInfo) => {
    setshowModal(true);
    setteamInfo(teamInfo);
  };

  const teamData = [
    {
      id: 1,
      name: "Ifeanyi Ogbor",
      position: "Chief Executive",
      linkedin: "https://twitter.com",
      image: Ifeanyi,
    },
    {
      id: 2,
      name: "Tosin Mamowora",
      position: "Head, Leasing & Investments",
      linkedin: "https://twitter.com",
      image: Tosin,
    },
    {
      id: 3,
      name: "Martina Umoh",
      position: "Head, Treasury",
      linkedin: "https://twitter.com",
      image: Martina,
    },
    {
      id: 4,
      name: "Oreoluwa Oladele",
      position: "Head of Accounts",
      linkedin: "https://twitter.com",
      image: Oreoluwa,
    },
    {
      id: 5,
      name: "Saheed Oladiran",
      position: "CE, Heed Advisory Services",
      linkedin: "https://twitter.com",
      image: Saheed,
    },
  ];

  return (
    <>
      <TeamInfoModal
        teamInfo={teamInfo}
        open={showModal}
        onClose={() => setshowModal(false)}
      />
      <div className="bg-light-grey w-100">
        <Container className="py-3 mb-5" fluid="md">
          <Header />
          <div style={{ height: "350px" }}>
            <img src={Team} alt="" className="w-100 h-100 object-fit-cover" />
          </div>
          <Row className="text-left my-5 pt-5">
            <Col sm={12} lg={6}>
              <h3 className="underline-heading mb-4">Who We Are</h3>
              <p>
                Leasehouz is an online market where assets, goods and services,
                sold by carefully selected vendors and other partners are
                displayed for sale. As a business, we offer finance leases to
                customers who require financing to buy the assets on display,
                paying conveniently over an agreed period of time. We also
                support the outright purchase of goods and services.
              </p>
              <p>
                At Leasehouz, customers enjoy the convenience of browsing for
                choice products, selecting preferred items and processing leases
                with the aim to gain possession, use and eventually own the
                assets. Also available are other financial and non financial
                services products e.g. motor insurance, plant and machinery,
                home insurance, life insurance, medical insurance, investments
                etc. It is a place where vendors, insurers, financing partners
                and other support service providers come together to provide
                very attractive offerings to the market.
              </p>
              <p>Leasehouz is powered by Assetkap Investment Limited</p>
            </Col>
            <Col sm={12} lg={6} className="">
              <div className="w-100 h-450">
                <img
                  src={shopping}
                  alt=""
                  className="w-100 h-100 object-fit-cover"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="bg-white">
          <Container>
            <div className="my-5 py-5">
              <div className="my-5 w-100">
                <Row className="text-left my-5">
                  <Col sm={12} md={6} lg={6}>
                    <h3 className="underline-heading mb-3">Our Mission</h3>
                    <p className="d-flex align-items-start">
                      To offer the Nigerian market modern day asset solutions
                      and other non financial services, providing leasing and
                      other related services, relying on today’s technology and
                      expertise.
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="">
                    <h3 className="underline-heading mb-3">Our Vision</h3>
                    <p className="d-flex align-items-end">
                      To become the preferred asset solution provider in the
                      Nigerian market. We will position to be the number one (1)
                      platform for leasing and other related business solutions.
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="py-5">
                <h3 className="underline-heading">Our Core Values</h3>
                <Row className="mt-4 justify-content-md-center">
                  <Col
                    sm={12}
                    md={4}
                    lg={3}
                    className="shadow-lg m-3 py-5 rounded-3 text-center height-auto"
                  >
                    <span className="material-icons-outlined p-3 bg-light-green text-green rounded-full">
                      done
                    </span>
                    <h5 className="font-bold mt-3">Integrity</h5>
                    <p>We always deliver on our promises</p>
                  </Col>
                  <Col
                    sm={12}
                    md={4}
                    lg={3}
                    className="shadow-lg m-3 py-5 rounded-3 text-center height-auto"
                  >
                    <span className="material-icons-outlined p-3 bg-light-pink text-pink rounded-full">
                      thumb_up_off_alt
                    </span>
                    <h5 className="font-bold mt-3">Delighting our customer</h5>
                    <p>We exceed customer expectations</p>
                  </Col>
                  <Col
                    sm={12}
                    md={4}
                    lg={3}
                    className="shadow-lg m-3 py-5 rounded-3 text-center height-auto"
                  >
                    <span className="material-icons-outlined p-3 bg-light-yellow text-yellow rounded-full">
                      sentiment_very_satisfied
                    </span>
                    <h5 className="font-bold mt-3">
                      Promoting fulfilling lives
                    </h5>
                    <p>We ensure our team is comfortable.</p>
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="shadow-lg m-3 py-5 rounded-3 text-center height-auto"
                  >
                    <span className="material-icons-outlined p-3 bg-light-blue text-blue rounded-full">
                      verified
                    </span>
                    <h5 className="font-bold mt-3">Ownership</h5>
                    <p> Every team member an entrepreneur.</p>
                  </Col>
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="shadow-lg m-3 py-5 rounded-3 text-center height-auto"
                  >
                    <span className="material-icons-outlined p-3 bg-light-grey text-gray rounded-full">
                      lightbulb
                    </span>
                    <h5 className="font-bold mt-3">Innovation</h5>
                    <p>
                      We continue to seek new and better ways to deliver to the
                      customer.
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <div className="my-5 py-5">
            <h3 className="underline-heading">Key Management</h3>
            <p className="mt-4">
              With a dedicated team of experts having in-depth knowledge and
              industry experience in related fields, innovative products and
              selected support services partners, the promise is to provide
              relevant, efficient and up to date solutions, packaged to meet the
              needs of our customers, relying on modern day technology.
            </p>
            <Row className="mt-4 mb-5 justify-content-md-center ">
              {teamData.map((member) => {
                return (
                  <Col
                    key={member.name}
                    md={4}
                    lg={2}
                    className=" mt-2 p-0 rounded-3 flex justify-content-center mx-3"
                    style={{ display: "flex" }}
                  >
                    <div className="">
                      <div
                        className="width-100 height-100 rounded-full mx-auto"
                        style={{ width: "200px", height: "200px" }}
                      >
                        <img
                          onClick={() => showTeamInfoModal(member)}
                          src={member.image}
                          alt=""
                          className="cursor-pointer rounded-circle object-fit-cover"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      <div className="text-center p-3">
                        <h5>{member.name}</h5>
                        <p>{member.position}</p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>

        <Footer />
      </div>
    </>
  );
}

export default AboutUs;
