import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";

function UserChoiceModal({ open, onClose }) {
  const [user, setuser] = useState();

  const getUserChoice = (e) => {
    setuser(e.target.value);
  };

  const cookies = new Cookies();
  const token = cookies.get("token");

  const location = useLocation();
  if (!open) return null;
  return (
    <>
      {/* <EligibilityCalculatorModal open={modalState} onClose={()=>setModalState(false)}/> */}
      <Modal size="md" centered show={open} onHide={onClose}>
        <div>
          <Modal.Header className="my-4" closeButton>
            <h5 className="text-bold">Customer Information</h5>
          </Modal.Header>
          <p className="mx-3">Please tell us what kind of customer you are:</p>
          <Form className="mx-3">
            <Form.Check
              inline
              onChange={getUserChoice}
              type="radio"
              value="individual"
              name="radio"
              label="Individual"
            />
            <Form.Check
              inline
              onChange={getUserChoice}
              type="radio"
              value="company"
              name="radio"
              label="Company"
            />
          </Form>
          <div className="d-flex justify-content-center my-4 w-100">
            {user === "individual" && token ? (
              <Link to="/lease/individual" className="text-decoration-none">
                <Button
                  variant={"outline-primary"}
                  className="bg-transparent px-5 py-2"
                >
                  Proceed
                </Button>
              </Link>
            ) : user === "company" && token ? (
              <Link to="/lease/company" className="text-decoration-none">
                <Button
                  variant={"outline-primary"}
                  className="bg-transparent px-5 py-2"
                >
                  Proceed
                </Button>
              </Link>
            ) : user === "individual" && !token ? (
              <Link
                to={{ pathname: "/login", state: { url: "/lease/individual" } }}
                className="text-decoration-none"
              >
                <Button variant={"outline-primary"} className="px-5 py-2">
                  Please login to proceed
                </Button>
              </Link>
            ) : user === "company" && !token ? (
              <Link
                to={{ pathname: "/login", state: { url: "/lease/company" } }}
                className="text-decoration-none"
              >
                <Button variant={"outline-primary"} className="px-5 py-2">
                  Please login to proceed
                </Button>
              </Link>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserChoiceModal;
