import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import Footer from '../Components/Reusable/Footer'
import Header from '../Components/Reusable/Header'
import HeaderCarousel from '../Components/Reusable/HeaderCarousel'
import Man from '../Assets/man-in-suit.jpg'
import { Link } from 'react-router-dom'

function ContactUs() {
    return (
        <>
            <div className="bg-light-grey w-100">
                <Container className='py-3 mb-5' fluid='md'>
                    <Header />
                    <HeaderCarousel/>
                </Container>
                <div className="bg-white">
                    <Container>
                        <Row className='text-left py-5'>
                            <Col xs={12} md={6} lg={6} className='pb-5'>
                                <div className=''>
                                    <h3 className=''>Get in touch</h3>
                                    <p className='mb-4'>Our team will reach out to you within 24 hours of receiving your message.</p>
                                    <div className="d-flex my-2">
                                        <span className="material-icons text-dark-blue">phone</span>
                                        <p>&nbsp;&nbsp;&nbsp;+234 (0) 809 958 8886</p>
                                    </div>
                                    <div className="d-flex my-2">
                                        <span className="material-icons text-dark-blue">mail</span>
                                        <p>&nbsp;&nbsp;&nbsp;info@leasehouz</p>
                                    </div>
                                    <div className="d-flex my-2">
                                        <span className="material-icons text-dark-blue">place</span>
                                        <p>&nbsp;&nbsp;&nbsp;2nd floor, Lapal House, 235 Igbosere Road, Lagos Island</p>
                                    </div>
                                </div>
                                <div className="pt-4">
                                    <h4>Want to work with us?</h4>
                                    <p>Send your CV to <Link to='mailto:careers@leasehouz.com'>careers@leasehouz.com</Link></p>
                                </div>
                                <div className="pt-4">
                                    <h4>Other enquiries</h4>
                                    <p className=''>For customer care: <Link to='mailto:customercare@leasehouz.com'>customercare@leasehouz.com</Link></p>
                                    <p>For vendor support: <Link to='mailto:vendorsupport@leasehouz.com'>vendorsupport@leasehouz.com</Link></p>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={6} className=''>
                                <div className="bg-dark-blue rounded-3 py-5 px-4 ">
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label className='text-white'>Name</Form.Label>
                                            <Form.Control autoFocus type="text" size='sm' className='bg-transparent border-bottom text-white' />
                                        </Form.Group>
                                        <Form.Group className="my-3 mt-4">
                                            <Form.Label className='text-white'>Email</Form.Label>
                                            <Form.Control type="text" size='sm' name='asset' className='bg-transparent border-bottom text-white' />
                                        </Form.Group>
                                        <Form.Group className="my-3 mt-4">
                                            <Form.Label className='text-white'>Message</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Type your message here..."
                                                style={{ height: '100px' }}
                                                className='bg-transparent border-bottom text-white'
                                            />
                                        </Form.Group>
                                        <button className='border-light-grey bg-transparent px-4 py-2 block mt-4 ml-auto'>Send message</button>
                                        {/* <FloatingLabel controlId="floatingInput" label="Your name" className="mb-3 text-white" >
                                            <Form.Control type="text" className='bg-transparent border-bottom text-white' />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="floatingInput" label="Your email address" className="mb-3 text-white" >
                                            <Form.Control type="email" className='bg-transparent border-bottom text-white' />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="floatingPassword" label="Password" className="mb-3 text-white">
                                            <Form.Control as='textarea' type="password" placeholder="Password" className='bg-transparent border-bottom text-white' />
                                        </FloatingLabel> */}
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default ContactUs
