import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import Loader from "../Reusable/Loader";
import Message from "../Reusable/Message";
import OnBoardNavbar from "../Reusable/OnBoardNavbar";
import { resetPassword } from "../../redux/actions/authActions";

const ResetPassword = ({ history }) => {
  // get token
  const params = useParams();
  const { token } = params;

  const dispatch = useDispatch();
  const resetPass = useSelector((state) => state.resetPass);
  const { loading, userInfo, error } = resetPass;

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(null);
  const [user, setUser] = useState({
    email: "",
    password: "",
    confirmed: "",
  });
  const { email, password, confirmed } = user;

  const findFormErrors = () => {
    const newErrors = {};
    const regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) newErrors.email = "Email field is invalid!";
    if (!email || email === "")
      newErrors.email = "Email field cannot be empty!";
    if (!password || password === "")
      newErrors.password = "Password field cannot be empty!";
    else if (password.length < 7)
      newErrors.password = "Password cannot be less than 7 characters!";
    if (!confirmed || confirmed === "")
      newErrors.confirmed = "Password field cannot be empty!";
    else if (confirmed.length < 7)
      newErrors.confirmed = "Password cannot be less than 7 characters!";

    return newErrors;
  };

  const getFormValues = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });

    // Check and see if errors exist and remove them from the error object:
    if (!!errors[e.target.name])
      setErrors({
        ...errors,
        [e.target.name]: null,
      });
  };

  const submitResetPassword = (e) => {
    e.preventDefault();
    if (password !== confirmed) {
      setMessage("Passwords do not match");
    }
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // DISPATCH RESET ACTION
      const data = { ...user, token };
      dispatch(resetPassword(data));
      setTimeout(() => {
        history.push("/login");
      }, 3000);
    }
  };

  return (
    <>
      <OnBoardNavbar />
      <div className="mt-5">
        <h1 className="text-center text-responsive">Reset Password</h1>
        <p className="text-center">Kindly enter your new password</p>
      </div>
      {message && <Message variant="danger">{message}</Message>}
      {error ? (
        <Message variant="danger">{error}</Message>
      ) : userInfo ? (
        <Message variant="success">Password changed successfully!</Message>
      ) : null}
      <Form onSubmit={submitResetPassword} className="mt-5 mw-400 mx-auto">
        <Form.Group className="my-4">
          <Form.Label htmlFor="email">Email</Form.Label>
          <Form.Control
            onChange={getFormValues}
            isInvalid={!!errors.email}
            size="lg"
            className="bg-light border-0"
            type="email"
            name="email"
            id="email"
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Label htmlFor="password">Password</Form.Label>
          <Form.Control
            onChange={getFormValues}
            isInvalid={!!errors.password}
            size="lg"
            className="bg-light border-0"
            type="password"
            name="password"
            id="password"
          />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Label htmlFor="confirmed">Confirm Password</Form.Label>
          <Form.Control
            onChange={getFormValues}
            isInvalid={!!errors.confirmed}
            size="lg"
            className="bg-light border-0"
            type="password"
            name="confirmed"
            id="confirmed"
          />
          <Form.Control.Feedback type="invalid">
            {errors.confirmed}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="my-4">
          <Form.Text>
            <b>Password should be:</b>
          </Form.Text>
          <Form.Text>At least 6 characters long</Form.Text>
          <Form.Text>A mixture of Upper and Lowercase letters</Form.Text>
        </div>
        <Button type="submit" block className="bg-blue border-0">
          {loading ? <Loader /> : "Reset"}
        </Button>
      </Form>
    </>
  );
};

export default ResetPassword;
