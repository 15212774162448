import * as types from '../types'


export const vendorProfileUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case types.VENDOR_PROFILE_UPDATE_REQUEST:
            return {
                loading: true
            }
        case types.VENDOR_PROFILE_UPDATE_SUCCESS:
            return {
                loading: false, data: action.payload
            }
        case types.VENDOR_PROFILE_UPDATE_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}

export const vendorFetchProductsReducer = (state = {}, action) => {
    switch (action.type) {
        case types.VENDOR_FETCH_PRODUCTS_REQUEST:
            return {
                loading: true
            }
        case types.VENDOR_FETCH_PRODUCTS_SUCCESS:
            return {
                loading: false, data: action.payload
            }
        case types.VENDOR_FETCH_PRODUCTS_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}

export const vendorCreateProductsReducer = (state = {}, action) => {
    switch (action.type) {
        case types.VENDOR_CREATE_PRODUCT_REQUEST:
            return {
                loading: true
            }
        case types.VENDOR_CREATE_PRODUCT_SUCCESS:
            return {
                loading: false, data: action.payload
            }
        case types.VENDOR_CREATE_PRODUCT_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}
export const vendorDeleteProductsReducer = (state = { deleteProduct: [] }, action) => {
    switch (action.type) {
        case types.VENDOR_DELETE_PRODUCT_REQUEST:
            return {
                loading: true
            }
        case types.VENDOR_DELETE_PRODUCT_SUCCESS:
            return {
                loading: false, deleteProduct: action.payload
            }
        case types.VENDOR_DELETE_PRODUCT_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}

export const vendorFetchOrdersReducer = (state = {}, action) => {
    switch (action.type) {
        case types.VENDOR_FETCH_ORDERS_REQUEST:
            return {
                loading: true
            }
        case types.VENDOR_FETCH_ORDERS_SUCCESS:
            return {
                loading: false, data: action.payload
            }
        case types.VENDOR_FETCH_ORDERS_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}

export const vendorMetricsReducer = (state = {}, action) => {
    switch (action.type) {
        case types.VENDOR_FETCH_METRICS_REQUEST:
            return {
                loading: true
            }
        case types.VENDOR_FETCH_METRICS_SUCCESS:
            return {
                loading: false, metrics: action.payload
            }
        case types.VENDOR_FETCH_METRICS_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}

export const saveProductAttributeData = (state = { saveAttributeResponse: [] }, action) => {
    switch (action.type) {
        case types.SAVE_PRODUCT_ATTRIBUTE_REQUEST:
            return {
                loading: true
            }
        case types.SAVE_PRODUCT_ATTRIBUTE_SUCCESS:
            return {
                loading: false, saveAttributeResponse: action.payload
            }
        case types.SAVE_PRODUCT_ATTRIBUTE_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}

export const editProductAttribute = (state = { updatedAttributes: [] }, action) => {
    switch (action.type) {
        case types.EDIT_PRODUCT_ATTRIBUTE_REQUEST:
            return {
                load: true
            }
        case types.EDIT_PRODUCT_ATTRIBUTE_SUCCESS:
            return {
                load: false, updatedAttributes: action.payload
            }
        case types.EDIT_PRODUCT_ATTRIBUTE_FAIL:
            return {
                load: false, err: action.payload
            }
        default:
            return state;
    }
}