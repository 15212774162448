import React, { useState, useEffect } from 'react'
import { Button, Form, Container, Row, Col, Toast} from 'react-bootstrap';
import Message from '../Reusable/Message'
import { useSelector, useDispatch } from 'react-redux';
import OnBoardNavbar from '../Reusable/OnBoardNavbar';
import Loader from '../Reusable/Loader'
import { forgotPassword } from '../../redux/actions/authActions'

const ForgotPassword = ({ history }) => {

    const [errors, setErrors] = useState({})
    
    const [popup, setPopup] = useState(false);
    
    const [user, setUser] = useState({ email: '' })
    
    const dispatch = useDispatch()
    
    const forgotPass = useSelector(state => state.forgotPass)
    
    const { loading, userInfo, error } = forgotPass
    
    const { email } = user
    
    const findFormErrors = () => {
        const newErrors = {}
        //eslint-disable-next-line
        const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!regEmail.test(email) || email === '' ) newErrors.email = 'Email field is invalid!'
        return newErrors
    }
    
    const getFormValues = e =>  { 
        setUser({ ...user, [e.target.name]: e.target.value})
            // Check and see if errors exist and remove them from the error object:
        if (!!errors[e.target.name]) setErrors({
            ...errors,
            [e.target.name]: null
        })
    }

    // show user success message that email has been sent
    const redirect =  '/email-sent'

    useEffect(() => {
        // if logged in, then redirect 
        if(userInfo) {
            history.push(redirect)
        }
        // eslint-disable-next-line
    }, [history, userInfo, redirect])

    const submitEmailHandler = (e) => {
        e.preventDefault()
        const newErrors = findFormErrors()
        // Conditional logic:
        if (Object.keys(newErrors).length > 0 ) {
        // We got errors!
        setErrors(newErrors)
        } else {
            dispatch(forgotPassword(email))
            setPopup(true)
        }
    }

    return (
        <>
            <OnBoardNavbar navlink={'Login'}/>
            <div className='mt-5'>
                <h1 className='text-center text-responsive'>Forgot Password</h1>
                <p className="text-center">Kindly enter your registered email address</p>
            </div>
            {error && <Message variant='danger'>{error}</Message>}

            {/* toast */}
            <Container fluid>
                <Row>
                    <Col>
                        <Toast  onClose={() => setPopup(false)} show={popup} delay={3000} autohide>
                        
                        <Toast.Body className='bg-dark text-light'>A Password reset link has been sent to your email</Toast.Body>
                        </Toast>
                    </Col>
                </Row>
                </Container>
                {/* End ot toast */}
            <Form onSubmit={submitEmailHandler} className='mt-5 mw-400 mx-auto'>
                <Form.Group>
                    <Form.Label htmlFor="email">Email Address</Form.Label>
                    <Form.Control value={email} onChange={getFormValues} isInvalid={!!errors.email} className='bg-light border-0' type="email" name="email" id="email" />
                    <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                </Form.Group>
                <Button type='submit' block className='bg-blue mt-3 border-0'>{loading ? <Loader /> : 'Reset'}</Button>
            </Form>
        </>
    )
}

export default ForgotPassword
