import React from 'react'

function ProductInfoCard({metrics}) {

    return (
        <>
            <div className="bg-white p-4 rounded-3 overview-products-card">
                <h6 className="text-grey">Products Sold</h6>
                <div className="d-flex w-75 justify-content-between">
                    <h4 className="text-bold" >{metrics && metrics.data.products_sold.toLocaleString('en') || 0}</h4>
                    <div className="d-flex">
                        {/* <span className="text-green mt-2">5%</span>
                        <span className="material-icons mt-2 text-green">arrow_drop_up</span> */}
                    </div>
                </div>
                <h6 className="text-grey mt-3">Product Leased</h6>
                <div className="d-flex w-75 justify-content-between">
                    <h4 className="text-bold">{metrics && metrics.data.loaned_products.toLocaleString('en') || 0}</h4>
                    <div className="d-flex">
                        {/* <p className="text-red mt-2">2.5%</p>
                        <span className="material-icons mt-2 text-red">arrow_drop_down</span> */}
                    </div>
                </div>
                <h6 className="text-grey mt-3">Total Revenue</h6>
                <h4 className="text-bold">{metrics && metrics.data.total_amount.toLocaleString('en') || 0}</h4>
            </div>
        </>
    )
}

export default ProductInfoCard
