import React from "react";
import { useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { saveIndividualLeaseData } from "../../../../redux/actions/leaseActions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup
  .object()
  .shape({
    city: yup.string().required(),
    address: yup.string().required(),
    phone_number: yup
      .string()
      .required()
      .matches(phoneRegExp, "Phone number is invalid")
      .min(11, "Should be 11 digits")
      .max(11, "Should be 11 digits"),
    landmark_nearest_bus_stop: yup.string().required(),
    next_of_kin_name: yup.string().required(),
    relationship: yup.string().required(),
    next_of_kin_house_address: yup.string().required(),
    next_of_kin_phone_number: yup
      .string()
      .required()
      .matches(phoneRegExp, "Phone number is invalid")
      .min(11, "Should be 11 digits")
      .max(11, "Should be 11 digits"),
    next_of_kin_email_address: yup.string().email().required(),
  })
  .required();

function ContactInformation({ onSelect, onPrevious }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    saveIndividualLeaseData(data);
    onSelect();
  };

  return (
    <>
      <div>
        <div className="bg-white py-2 px-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h5 className="my-4 font-bold">Contact Details</h5>
            <Row>
              <Col xs={12} md={6} lg={6} className="py-2">
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>
                    City <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    className="bg-light-grey"
                    {...register("city")}
                  />
                  {errors.city && (
                    <p className="text-red">{errors.city.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="houseaddress">
                  <Form.Label>
                    Address <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    className="bg-light-grey"
                    {...register("address")}
                  />
                  {errors.address && (
                    <p className="text-red">{errors.address.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>
                    Phone number <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone"
                    className="bg-light-grey"
                    {...register("phone_number")}
                    placeholder="09070..."
                  />
                  {errors.phone_number && (
                    <p className="text-red">{errors.phone_number.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>
                    {" "}
                    Landmark <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="landmark_nearest_bus_stop"
                    className="bg-light-grey"
                    {...register("landmark_nearest_bus_stop")}
                  />
                  {errors.landmark_nearest_bus_stop && (
                    <p className="text-red">
                      {errors.landmark_nearest_bus_stop.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="nextofkin">
                  <Form.Label>
                    Next of Kin (Name) <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="next_of_kin"
                    className="bg-light-grey"
                    {...register("next_of_kin_name")}
                  />
                  {errors.next_of_kin_name && (
                    <p className="text-red">
                      {errors.next_of_kin_name.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={6} className="py-2">
                <Form.Group className="mb-3" controlId="relationship">
                  <Form.Label>
                    Relationship <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="relationship"
                    className="bg-light-grey"
                    {...register("relationship")}
                  />
                  {errors.relationship && (
                    <p className="text-red">{errors.relationship.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="nokhouseaddress">
                  <Form.Label>
                    Next of Kin House Address{" "}
                    <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="nokhouseaddress"
                    className="bg-light-grey"
                    {...register("next_of_kin_house_address")}
                  />
                  {errors.next_of_kin_house_address && (
                    <p className="text-red">
                      {errors.next_of_kin_house_address.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="nokphone">
                  <Form.Label>
                    Next of Kin Phone number <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    name="nokphone"
                    className="bg-light-grey"
                    {...register("next_of_kin_phone_number")}
                  />
                  {errors.next_of_kin_phone_number && (
                    <p className="text-red">
                      {errors.next_of_kin_phone_number.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="nokemail">
                  <Form.Label>
                    Next of Kin Email Address{" "}
                    <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="nokemail"
                    className="bg-light-grey"
                    {...register("next_of_kin_email_address")}
                  />
                  {errors.next_of_kin_email_address && (
                    <p className="text-red">
                      {errors.next_of_kin_email_address.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <button onClick={onPrevious} className="border-0 mr-2 px-4 py-2">
                Previous
              </button>
              <button className="border-0 px-4 py-2">Next</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactInformation;
