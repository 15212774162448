import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
// import { Link } from 'react-router-dom'
import AddAttributes from "./AddAttributes";
import DeleteProduct from "./DeleteProduct";

function AllProducts({ products }) {
  const [showModal, setshowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productID, setproductID] = useState();
  const [attributeID, setAttributeID] = useState();
  const [attributeColor, setAttributeColor] = useState();
  const [attributeCondition, setAttributeCondition] = useState();
  const [attributeYear, setAttributeYear] = useState();
  const [productDeleteID, setProductDeleteID] = useState();

  const openAddAttributesModal = (id) => {
    setshowModal(true);
    setproductID(id);
  };

  const openDeleteModal = (id) => {
    setShowDeleteModal(true);
    setProductDeleteID(id);
  };

  return (
    <>
      <AddAttributes
        productId={productID}
        attributeId={attributeID}
        attributeColor={attributeColor}
        attributeCondition={attributeCondition}
        attributeYear={attributeYear}
        open={showModal}
        onClose={() => setshowModal(false)}
      />
      <DeleteProduct
        productId={productDeleteID}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
      <div className="mx-3">
        <div className="mb-4">
          <h5 className="underline-heading">All Products</h5>
        </div>
        <Row className="d-flex ">
          {products &&
            products.map((product) => {
              return (
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  key={product.id}
                  className="max-width"
                >
                  <div className="bg-white p-3" style={{ height: "230px" }}>
                    <img
                      src={product.url}
                      className="cursor-pointer w-100 object-fit-cover object-center"
                      alt=""
                    />
                    {product.status_id === 2 && (
                      <p className="bg-warning rounded-10 text-center px-3 py-1">
                        Inactive
                      </p>
                    )}
                  </div>
                  <div className="mt-3 d-flex justify-content-between">
                    <div className="w-75 ">
                      <p>{product.name} </p>
                      <p className="text-blue">
                        &#8358; {product.amount.toLocaleString("en")}
                      </p>
                    </div>
                    <div>
                      <div className="d-flex">
                        {/* <span className="material-icons p-1 cursor-pointer">visibility</span> */}
                        <span
                          onClick={() => {
                            openAddAttributesModal(product.id);
                            setAttributeID(product.attribute.id);
                            setAttributeColor(product.attribute.color);
                            setAttributeCondition(product.attribute.condition);
                            setAttributeYear(product.attribute.year_of_make);
                          }}
                          className="material-icons text-blue p-1 cursor-pointer"
                        >
                          edit
                        </span>
                        <span
                          onClick={() => openDeleteModal(product.id)}
                          className="material-icons text-red p-1 cursor-pointer"
                        >
                          delete
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
}

export default AllProducts;
