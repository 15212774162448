import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  initialState,
  saveCompanyLeaseData,
  // saveIndividualLeaseData,
  submitIndividualLeaseData,
  submitCompanyLeaseData,
} from "../../../../redux/actions/leaseActions";
import Loader from "../../../Reusable/Loader";
import Message from "../../../Reusable/Message";

function SupportingDocuments({ onPrevious, leaseType }) {
  const [leaseSubmitErrorMessage, setLeaseSubmitErrorMessage] = useState("");
  const [leaseSubmitSuccessMessage, setLeaseSubmitSuccessMessage] =
    useState("");
  const [toastVariant, setToastVariant] = useState("");
  const dispatch = useDispatch();

  const history = useHistory();

  let assetInfo = JSON.parse(localStorage.getItem("assetInfo"));

  const { register, handleSubmit } = useForm();

  //FETCH INDIVIDUAL LEASE DATA
  const individualLease = useSelector((state) => state.individualLease);
  const { data, loading, error } = individualLease;

  const startIndividualLeaseApplication = () => {
    //ADD ASSET INFORMATION IN STATE TO INDIVIDUAL LEASE DATA
    let updatedState = {
      ...initialState,
      //...finalIndividualLeaseData
    };
    dispatch(submitIndividualLeaseData(updatedState));
  };

  //FETCH COMPANY LEASE DATA
  const companyLease = useSelector((state) => state.companyLease);
  const { companyData, load } = companyLease;

  const startCompanyLeaseApplication = () => {
    //ADD ASSET INFORMATION IN STATE TO COMPANY LEASE DATA
    let updatedCompanyData = {
      ...initialState,
      //  ...finalCompanyLeaseData
    };
    dispatch(submitCompanyLeaseData(updatedCompanyData));
  };

  const onSubmit = async (data) => {
    if (leaseType === "individual") {
      // saveIndividualLeaseData(data)
      initialState.lease_type = leaseType;
      initialState.product_id = assetInfo.productID;
      initialState.application_letter = "";
      initialState.bank_statement = data.bank_statement[0];
      initialState.doc_type = data.doc_type;
      initialState.doc_url = data.doc_url[0];
      initialState.doc_type = data.doc_type;
      initialState.employment_confirmation = data.employment_confirmation[0];
      initialState.employment_id_card = data.employment_id_card[0];
      initialState.home_address_evidence = data.home_address_evidence[0];
      initialState.recent_passport_photograph =
        data.recent_passport_photograph[0];

      //SAVE ASSET INFORMATION TO STATE
      //setfinalIndividualLeaseData (data)
      await startIndividualLeaseApplication();
    } else {
      saveCompanyLeaseData(data);
      initialState.lease_type = leaseType;
      initialState.product_id = assetInfo.productID;
      initialState.application_letter = data.application_letter[0];
      initialState.copy_of_company_registration_papers =
        data.copy_of_company_registration_papers[0];
      initialState.months_bank_statement = data.months_bank_statement[0];
      // setfinalCompanyLeaseData (data)
      startCompanyLeaseApplication();
    }
  };

  useEffect(() => {
    if ((data && data.success) || (companyData && companyData.success)) {
      localStorage.setItem("leaseSuccess", JSON.stringify(data));
      setLeaseSubmitSuccessMessage(data.message);
      setTimeout(() => {
        history.push("/user/lease");
      }, 2000);
    }
    if (error) {
      setLeaseSubmitErrorMessage(error.message);
      setToastVariant("danger");
      setTimeout(() => {
        setLeaseSubmitErrorMessage("");
      }, 1000);
    }
  }, [companyData, data, error, history]);

  return (
    <>
      <div>
        {leaseSubmitErrorMessage && (
          <Message children={leaseSubmitErrorMessage} variant="danger" />
        )}
        {leaseSubmitSuccessMessage && (
          <Message children={leaseSubmitSuccessMessage} variant="success" />
        )}
        <div className="bg-white py-2 px-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h5 className="my-4 font-bold">Documents</h5>
            <p>
              The following requirements may be attached to this application to
              facilitate the process. Otherwise, they may be submitted to our
              office or arrangements made for pick up by our team.
            </p>
            <Row>
              <Col xs={12} md={6} lg={6} className="py-2">
                {leaseType === "company" ? (
                  <Form.Group className="mb-3" controlId="asset">
                    <Form.Label>
                      Application letter(Pdf,{" "}
                      <span className="text-red">Max 2MB</span>)
                    </Form.Label>
                    <Form.Control
                      {...register("application_letter")}
                      type="file"
                      accept="application/pdf"
                      name="application_letter"
                      className="bg-light-grey"
                    />
                  </Form.Group>
                ) : null}
                {leaseType === "company" ? (
                  <Form.Group className="mb-3" controlId="asset">
                    <Form.Label>
                      Copy of company registration papers( forms c02,c07
                      memorandum, <span className="text-red">Max 2MB</span>)
                    </Form.Label>
                    <Form.Control
                      {...register("copy_of_company_registration_papers")}
                      type="file"
                      name="copy_of_company_registration_papers"
                      className="bg-light-grey"
                    />
                  </Form.Group>
                ) : null}
                {leaseType === "individual" ? (
                  <Form.Group className="mb-3" controlId="asset">
                    <Form.Label>Type of Identification</Form.Label>
                    <Form.Control
                      {...register("doc_type")}
                      as="select"
                      name="doc_type"
                      className="bg-light-grey"
                    >
                      <option>Select a type of identification</option>
                      <option value="Passport">Passport</option>
                      <option value="Driver License">Driver License</option>
                      <option value="Voter's Card">Voter's Card</option>
                      <option value="National ID">National ID</option>
                    </Form.Control>
                  </Form.Group>
                ) : null}
                {leaseType === "individual" ? (
                  <Form.Group className="mb-3" controlId="asset">
                    <Form.Label>
                      Upload means of identification(image,{" "}
                      <span className="text-red">Max 2MB</span>)
                    </Form.Label>
                    <Form.Control
                      {...register("doc_url")}
                      type="file"
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                      name="doc_url"
                      className="bg-light-grey"
                    />
                  </Form.Group>
                ) : null}
                {leaseType === "individual" ? (
                  <Form.Group className="mb-3" controlId="asset">
                    <Form.Label>
                      Copy of employment/confirmation/promotion letter from your
                      current employer(Pdf,{" "}
                      <span className="text-red">Max 2MB</span>)
                    </Form.Label>
                    <Form.Control
                      {...register("employment_confirmation")}
                      type="file"
                      accept="application/pdf"
                      name="employment_confirmation"
                      className="bg-light-grey"
                    />
                  </Form.Group>
                ) : null}
                {leaseType === "individual" ? (
                  <Form.Group className="mb-3" controlId="asset">
                    <Form.Label>
                      Upload employee ID card(Pdf,{" "}
                      <span className="text-red">Max 2MB</span>)
                    </Form.Label>
                    <Form.Control
                      {...register("employment_id_card")}
                      type="file"
                      accept="application/pdf"
                      name="employment_id_card"
                      className="bg-light-grey"
                    />
                  </Form.Group>
                ) : null}
                {leaseType === "individual" ? (
                  <Form.Group className="mb-3" controlId="asset">
                    <Form.Label>
                      6 months bank statement(Pdf,{" "}
                      <span className="text-red">Max 2MB</span>)
                    </Form.Label>
                    <Form.Control
                      {...register("bank_statement")}
                      type="file"
                      accept="application/pdf"
                      name="bank_statement"
                      className="bg-light-grey"
                    />
                  </Form.Group>
                ) : null}
                {leaseType === "company" ? (
                  <Form.Group className="mb-3" controlId="asset">
                    <Form.Label>
                      12 months bank statement(Pdf,{" "}
                      <span className="text-red">Max 2MB</span>)
                    </Form.Label>
                    <Form.Control
                      {...register("months_bank_statement")}
                      type="file"
                      name="months_bank_statement"
                      accept=" application/pdf"
                      className="bg-light-grey"
                    />
                  </Form.Group>
                ) : null}
              </Col>
              <Col xs={12} md={6} lg={6} className="py-2">
                {leaseType === "individual" ? (
                  <Form.Group className="mb-3" controlId="asset">
                    <Form.Label>
                      Evidence of home address (e.g Utility Bill in pdf,{" "}
                      <span className="text-red">Max 2MB</span>)
                    </Form.Label>
                    <Form.Control
                      {...register("home_address_evidence")}
                      type="file"
                      accept="application/pdf"
                      name="home_address_evidence"
                      className="bg-light-grey"
                    />
                  </Form.Group>
                ) : null}
                {leaseType === "individual" ? (
                  <Form.Group className="mb-3" controlId="asset">
                    <Form.Label>
                      Recent passport photograph(image,{" "}
                      <span className="text-red">Max 2MB</span>)
                    </Form.Label>
                    <Form.Control
                      {...register("recent_passport_photograph")}
                      type="file"
                      accept=" image/jpg, image/jpeg"
                      name="recent_passport_photograph"
                      className="bg-light-grey"
                    />
                  </Form.Group>
                ) : null}
                {leaseType === "company" ? (
                  <Form.Group className="mb-3" controlId="asset">
                    <Form.Label>
                      Recent passport photograph of the promoter/MD(image,{" "}
                      <span className="text-red">Max 2MB</span>)
                    </Form.Label>
                    <Form.Control
                      {...register(
                        "recent_passport_photograph_of_the_promoter"
                      )}
                      type="file"
                      name="recent_passport_photograph_of_the_promoter"
                      className="bg-light-grey"
                    />
                  </Form.Group>
                ) : null}
                <Form.Group className="mb-3 d-flex" controlId="asset">
                  <Form.Check
                    type="checkbox"
                    name="asset"
                    label="I declare that to the best of my knowledge and belief, the information provided here are true and correct, and that no information has been withheld, which might otherwise affect your decision."
                  />
                  <span className="text-red">*</span>
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <button
                onClick={onPrevious}
                type="button"
                className="border-0 mr-2 px-4 py-2"
              >
                Previous
              </button>
              {leaseType === "individual" ? (
                <button type="submit" className="border-0 px-4 py-2">
                  {loading ? <Loader /> : "Submit"}
                </button>
              ) : (
                <button type="submit" className="border-0 px-4 py-2">
                  {load ? <Loader /> : "Submit"}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SupportingDocuments;
