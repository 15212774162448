import React, {useState} from 'react'
import {Row, Col } from 'react-bootstrap'
import SwiperCore, { Pagination, Scrollbar, Autoplay, Thumbs} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import suv from '../../../Assets/suv-white-background.jpg'

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/thumbs/thumbs.scss';

SwiperCore.use([Pagination, Scrollbar, Autoplay, Thumbs]);


function PreviewProduct({open, onClose, previewData}) {
    const [thumbsSwiper] = useState(null)

    //console.log(previewData)

    if (!open) return null
    return (
        <>
            <div className="modal-details-container">
                <Row className="bg-white py-4 width-90 m-auto modal-container">
                    <span onClick={onClose} className="material-icons cursor-pointer font-bold">close</span>
                    <Col xs={12} md={10} lg={6} className="preview-info">
                        <Swiper
                            spaceBetween={100}
                            slidesPerView={1}
                            centeredSlides
                            autoplay={{ delay: 3000 }}
                            speed={3000}
                            pagination={{ clickable: true }}
                            thumbs={{ swiper: thumbsSwiper}}
                            className='preview-slide'
                        >
                            {
                                [1].map((i) => {
                                    return <SwiperSlide key={i} className='mt-3'>
                                                <div className='mw-500 m-auto bg-white py-4'>
                                                    <img src={suv} alt="image" className=' w-100 block m-auto'/>
                                                </div>
                                            </SwiperSlide>
                                })
                            }
                        </Swiper>
                        <div className="">
                            <h3>{previewData.name}</h3>
                            <p>{previewData.description}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={10} lg={6} className="preview-info2">
                        <div>
                            <div className="d-flex">
                                <h5 className="text-bold mr-2">Current Price:</h5>
                                <p><span className="text-blue text-bold">₦{previewData.amount}</span></p>
                            </div>
                            <div className="d-flex">
                                <h5 className="text-bold mr-2">Quantity:</h5> 
                                <p>{previewData.quantity}</p>
                            </div>
                            <div className="d-flex my-3">
                                <h5 className="text-bold mr-2"> Method of payment:</h5>
                                <p>{previewData.loanable}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="text-light-grey">
                    <Col xs={12} md={6} lg={12}>
                        <div className="float-right my-4 mr-3">
                            <button className="bg-transparent text-blue border-blue rounded-3 py-2 px-4 m-2">Edit</button>
                            <button className="bg-blue text-white border-blue rounded-3 py-2 px-4 m-2">
                                Publish
                            </button>
                        </div>  
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default PreviewProduct;
