import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { userLoginReducer, userRegisterReducer, userVerifyReducer, resendCodeReducer, forgotPasswordReducer, resetPasswordReducer, createVendorReducer, getUserProfileReducer, updateBioReducer, updateProfileReducer, getStatesReducer, getCountriesReducer, changePasswordReducer } from './reducers/userReducers'
import { getCategoriesReducer, getProductsBySubCategoriesReducer, getProductsByTagReducer, getAttributeReducer, shippingInfoReducer, getSubCategoriesReducer, getBrandsReducer, getProductsByIdReducer, outrightPaymentReducer, processLeasehouzPaymentReducer, paymentChannelReducer, verifyPaymentReducer, fetchUserOrdersReducer, fetchUserOrdersByTrackingCodeReducer, getSearchProductReducer } from './reducers/productReducers'
// import { cartReducer } from './reducers/cartReducers'
import { cartReducer, updateCartItemReducer } from './reducers/cartReducers'
import { vendorCreateProductsReducer, vendorDeleteProductsReducer, vendorFetchProductsReducer, vendorProfileUpdateReducer, vendorFetchOrdersReducer, vendorMetricsReducer, saveProductAttributeData, editProductAttribute } from './reducers/vendorReducers'
import { submitIndividualLeaseDataReducer, submitCompanyLeaseDataReducer, getLeaseOrderReducer, payLeaseDepositReducer, getLeaseHistoryReducer } from './reducers/leaseReducers'


const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null
const vendorInfoFromStorage = localStorage.getItem('newVendor') ? JSON.parse(localStorage.getItem('newVendor')) : null
const cartItemsFromStorage = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []

const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
    newVendor: { vendor: vendorInfoFromStorage },
    cart: { cartItems: cartItemsFromStorage }
}

export const ConfigureStore = () => {
    const store = createStore(
        combineReducers({
            userLogin: userLoginReducer,
            userRegister: userRegisterReducer,
            userVerify: userVerifyReducer,
            resendVerifyCode: resendCodeReducer,
            forgotPass: forgotPasswordReducer,
            resetPass: resetPasswordReducer,
            newVendor: createVendorReducer,
            getCategories: getCategoriesReducer,
            getSubCategories: getSubCategoriesReducer,
            getProducts: getProductsBySubCategoriesReducer,
            getProductsByTag: getProductsByTagReducer,
            getProductsById: getProductsByIdReducer,
            fetchSearchResult: getSearchProductReducer,
            getBrands: getBrandsReducer,
            cart: cartReducer,
            updateCartItem: updateCartItemReducer,
            getAttribute: getAttributeReducer,
            shippingInfo: shippingInfoReducer,
            getProfile: getUserProfileReducer,
            updatepersonalDetails: updateBioReducer,
            updateadditionalDetailse: updateProfileReducer,
            getStates: getStatesReducer,
            userCountries: getCountriesReducer,
            directPayment: outrightPaymentReducer,
            getProcessedLeaseHouzPayment: processLeasehouzPaymentReducer,
            updatedVendorProfile: vendorProfileUpdateReducer,
            getVendorProducts: vendorFetchProductsReducer,
            createProduct: vendorCreateProductsReducer,
            getDeletedProduct: vendorDeleteProductsReducer,
            getVendorOrders: vendorFetchOrdersReducer,
            paymentChannel: paymentChannelReducer,
            vendorMetrics: vendorMetricsReducer,
            verifyPayment: verifyPaymentReducer,
            userOrders: fetchUserOrdersReducer,
            userOrdersByTrackingCode: fetchUserOrdersByTrackingCodeReducer,
            individualLease: submitIndividualLeaseDataReducer,
            companyLease: submitCompanyLeaseDataReducer,
            leaseOrders: getLeaseOrderReducer,
            leaseDeposit: payLeaseDepositReducer,
            leaseHistory: getLeaseHistoryReducer,
            saveAttribute: saveProductAttributeData,
            updateAttribute: editProductAttribute,
            fetchNewPassword: changePasswordReducer,
            //productSubCategories: getSubCategoriesReducer
        }),
        initialState,
        composeWithDevTools(applyMiddleware(thunk))
    )
    return store
}