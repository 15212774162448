import React from 'react'

function OperatingLease() {
    return (
        <>
            <div className="mt-4 height-300 overflow-y">
                <p>We provide various kinds of equipment on lease mostly to multinationals and large corporate organizations based on duly executed contracts. These include cars, light weight and heavy duty trucks, forklift machines, power generating sets, production equipment, ground handling equipment and various other leasable assets as required by our customers. Depending on the preference of the customer, we will provide other associated services required to operate the assets. It is a service provision agreement and upon full and final payment, the ownership of the assets continue to reside with our company. Such assets are either sold in the open market or deployed to a secondary lease contract. Often times, the customer is given the first right of refusal to purchase the assets.</p>
                <p>This service is offered to the corporates and other heavy industrial concerns that require outsourced equipment rental services to support their capacity planning. The companies enjoy and pay for the use of the assets with no prior intentions to own the assets. Understandably, this allows the customer to conserve capital for investment, better manage her cash flows, focus on her core business  and allows for more efficient financial reporting.</p>
                <p>Our experience in leasing and relationships with key partners in the industry allows us to delight our customers providing the required service.</p>
            </div>
        </>
    )
}

export default OperatingLease
