import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar} from 'react-bootstrap'
import Logo from './Logo'

function OnBoardNavbar(props) {
    return (
        <>
            <Navbar className='flex-nowrap'>
                <Navbar.Brand>
                    <Logo/>
                </Navbar.Brand>
                <Navbar.Text className=''>
                    <Link to={`/${props.navlink}`} className='text-blue text-decoration-none'>{props.navlink}</Link>
                </Navbar.Text>
            </Navbar>
        </>
    )
}

export default OnBoardNavbar
