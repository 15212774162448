import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { saveCompanyLeaseData } from "../../../../redux/actions/leaseActions";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const directorProfileSchema = yup.object().shape({
  directors_name: yup.string().required(),
  position: yup.string().required(),
  role: yup.string().required(),
  director_phone_number: yup
    .string()
    .required()
    .matches(phoneRegExp, "Phone number is invalid")
    .min(11, "Should be 11 digits")
    .max(11, "Should be 11 digits"),
  total_experience: yup.string().required(),
  director_email_address: yup.string().email().required(),
  number_of_years_in_role: yup.number().required(),
  other_places_of_work: yup.string().required(),
  bvn: yup
    .string()
    .required()
    .matches(phoneRegExp, "BVN is invalid")
    .min(11, "BVN should be 11 digits")
    .max(11, "Should be 11 digits"),
});

function DirectorProfile({ onSelect, onPrevious }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(directorProfileSchema),
  });
  const onSubmit = (data) => {
    saveCompanyLeaseData(data);
    onSelect();
  };

  return (
    <>
      <div>
        <div className="bg-white py-3 px-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h5 className="my-4 font-bold">Directors/Management Profile</h5>
            <Row>
              <Col xs={12} md={6} lg={6} className="py-2">
                <Form.Group className="mb-3" controlId="directors-name">
                  <Form.Label>
                    Name <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    autoFocus
                    type="text"
                    name="directors_name"
                    className="bg-light-grey"
                    {...register("directors_name")}
                  />
                  {errors.directors_name && (
                    <p className="text-red my-2">
                      {errors.directors_name.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="Position">
                  <Form.Label>
                    Position <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="position"
                    className="bg-light-grey"
                    {...register("position")}
                  >
                    <option>Select a position</option>
                    <option value="Founder">Founder</option>
                    <option value="Chairman">Chairman</option>
                    <option value="Director">Director</option>
                    <option value="Mgt Staff">Mgt Staff</option>
                  </Form.Control>
                  {errors.position && (
                    <p className="text-red my-2">{errors.position.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="role">
                  <Form.Label>
                    Role <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="role"
                    className="bg-light-grey"
                    placeholder="MD/ED/CFO etc"
                    {...register("role")}
                  />
                  {errors.role && (
                    <p className="text-red my-2">{errors.role.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="numberofyears">
                  <Form.Label>
                    Number of Years In This Role{" "}
                    <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="number_of_years_in_role"
                    className="bg-light-grey"
                    {...register("number_of_years_in_role")}
                  />
                  {errors.number_of_years_in_role && (
                    <p className="text-red my-2">
                      {errors.number_of_years_in_role.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="totalexperience">
                  <Form.Label>
                    Total Experience (yrs) <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="total_experience"
                    className="bg-light-grey"
                    {...register("total_experience")}
                  />
                  {errors.total_experience && (
                    <p className="text-red my-2">
                      {errors.total_experience.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={6} className="py-2">
                <Form.Group className="mb-3" controlId="otherbusiness">
                  <Form.Label>
                    Other businesses/places of work{" "}
                    <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="other_business"
                    className="bg-light-grey"
                    {...register("other_places_of_work")}
                  />
                  {errors.other_places_of_work && (
                    <p className="text-red my-2">
                      {errors.other_places_of_work.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="Qualifications">
                  <Form.Label>
                    Qualifications <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="qualifications"
                    className="bg-light-grey"
                    {...register("qualifications")}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="houseaddress">
                  <Form.Label>
                    Residential Address <span className="text-red">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="residential_address"
                    className="bg-light-grey"
                    {...register("residential_address")}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>
                    {" "}
                    Phone Number <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone"
                    className="bg-light-grey"
                    {...register("director_phone_number")}
                  />
                  {errors.director_phone_number && (
                    <p className="text-red my-2">
                      {errors.director_phone_number.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>
                    {" "}
                    Email Address <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    className="bg-light-grey"
                    {...register("director_email_address")}
                  />
                  {errors.director_email_address && (
                    <p className="text-red my-2">
                      {errors.director_email_address.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="bvn">
                  <Form.Label>
                    {" "}
                    BVN <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="bvn"
                    className="bg-light-grey"
                    {...register("bvn")}
                  />
                  {errors.bvn && (
                    <p className="text-red my-2">{errors.bvn.message}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <button onClick={onPrevious} className="border-0 mr-2 px-4 py-2">
                Previous
              </button>
              <button className="border-0 px-4 py-2">Next</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default DirectorProfile;
