import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import successful from "../../../Assets/checkmark.json";
import unsuccessful from "../../../Assets/cancel.json";
import Lottie from "react-lottie";
import Header from "../../Reusable/Header";
import { Card } from "react-bootstrap";
import { verifyPayment } from "../../../redux/actions/productActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function PaymentSuccess() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const gotoHome = () => {
    history.push("/");
  };

  const referenceNumber = location.search;
  useEffect(() => {
    dispatch(verifyPayment(referenceNumber));
  }, [dispatch, referenceNumber]);

  const verifiedPayment = useSelector((state) => state.verifyPayment);
  const { verified, error } = verifiedPayment;

  useEffect(() => {
    setTimeout(() => {
      if (verified) {
        if (verified.data && verified.data.is_deposit === true) {
          history.push("/user/lease");
        } else {
          history.push("/user/transactions");
        }
      }
    }, 2000);
  }, [history, verified]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: verified ? successful : error ? unsuccessful : null,
  };
  return (
    <>
      <Header />
      <Card>
        <div className="bg-white d-flex justify-content-center align-items-center p-4 flex-column m-auto width-70">
          <div className="my-5 icon-space">
            <Lottie options={defaultOptions} height={"200px"} width={"200px"} />
          </div>
          <h3 className="text-center paysuccess-text">
            {verified
              ? "Your transaction was successful"
              : error
              ? "Your transaction was not successful"
              : "Verifying Payment..."}
          </h3>
          <div className="my-5 icon-space">
            <button className="border-0 px-4 py-2" onClick={gotoHome}>
              Close
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}

export default PaymentSuccess;
