import React from 'react'
import Lottie from 'react-lottie';
import successful from '../../Assets/successful.json'
import OnBoardNavbar from '../Reusable/OnBoardNavbar';


const ResetEmailModal = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: successful,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };
   
    return (
        <>
        <OnBoardNavbar />
        <div className='mt-5'>
        <p className="text-center">Email has been sent!</p>
        </div>

       <div className='mt-3 mw-400 mx-auto'>
       <Lottie
            options={defaultOptions}
            height={'400px'}
            width={'400px'}
            />
       </div>

      <div className='mt-1'>
       <p className='text-center'>Kindly Check your email to reset password</p>
      </div>
    </>
    )
}

export default ResetEmailModal
