import React, { useState } from "react";
import { Alert, Container, Tab, Tabs } from "react-bootstrap";
import Footer from "../../../Reusable/Footer";
import Header from "../../../Reusable/Header";
import AssetInformation from "./AssetInformation";
import ContactInformation from "./ContactInformation";
import EmploymentInformation from "./EmploymentInformation";
import PersonalInformation from "./PersonalInformation";
import SupportingDocuments from "./SupportingDocuments";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function IndividualLeaseApplication() {
  const [key, setKey] = useState("Personal Information");
  const [alert, setAlert] = useState(true);
  const [leaseFormErrors, setLeaseFormErrors] = useState([]);
  const [leaseAlert, setLeaseAlert] = useState(false);

  const individualLease = useSelector((state) => state.individualLease);
  const { error } = individualLease;

  useEffect(() => {
    setLeaseAlert("");
    if (error) {
      console.log(error);
      setLeaseAlert(!leaseAlert);
      for (let err in error.errors) {
        setLeaseFormErrors((arr) => [...arr, err]);
      }
    } else {
      setLeaseFormErrors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <div className="bg-light-grey w-100">
        <Container className="py-3" fluid="md">
          <Header />
          <div className="py-4">
            <h4 className="font-bold">Personal Lease Application</h4>
            <p className="pt-3">
              Please ensure that you fill the form correctly (providing every
              information requested). The complete information is required to
              enable us make an informed decision.
            </p>
            <Alert show={alert} variant="warning" className="mt-3 w-100">
              <div className="d-flex justify-content-between">
                <p>
                  All fields with <span className="text-red">*</span> are
                  required.
                </p>
                <span
                  onClick={() => setAlert(false)}
                  className="material-icons cursor-pointer"
                >
                  close
                </span>
              </div>
            </Alert>
            <Alert show={leaseAlert} variant="danger" className="mt-3 w-100">
              <div className="d-flex justify-content-between">
                <p>The following fields were missing or invalid</p>
                <span
                  onClick={() => setLeaseAlert(!leaseAlert)}
                  className="material-icons cursor-pointer"
                >
                  close
                </span>
              </div>
              <ul className="text-left">
                {leaseFormErrors.map((error, id) => (
                  <li className="" key={id}>
                    {error}
                  </li>
                ))}
              </ul>
            </Alert>

            <Tabs
              onSelect={(k) => setKey(k)}
              activeKey={key}
              className="my-4"
              id="individualapplication"
            >
              <Tab eventKey="Personal Information" title="Personal Information">
                <PersonalInformation
                  onSelect={() => setKey("Contact Information")}
                />
              </Tab>
              <Tab eventKey="Contact Information" title="Contact Information">
                <ContactInformation
                  onPrevious={() => setKey("Personal Information")}
                  onSelect={() => setKey("Employment Information")}
                />
              </Tab>
              <Tab
                eventKey="Employment Information"
                title="Employment Information"
              >
                <EmploymentInformation
                  onPrevious={() => setKey("Contact Information")}
                  onSelect={() => setKey("Asset Information")}
                />
              </Tab>
              <Tab eventKey="Asset Information" title="Asset Information">
                <AssetInformation
                  onPrevious={() => setKey("Employment Information")}
                  onSelect={() => setKey("Supporting Documents")}
                />
              </Tab>
              <Tab eventKey="Supporting Documents" title="Supporting Documents">
                <SupportingDocuments
                  onPrevious={() => setKey("Asset Information")}
                  leaseType={"individual"}
                />
              </Tab>
            </Tabs>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default IndividualLeaseApplication;
