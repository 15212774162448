import * as types from '../types'

export const getCartItemsReducer = (state = { cartItems: [] }, action) => {
    switch (action.type) {
        case types.GET_CART_ITEMS_REQUEST:
            return {
                loading: true, cartItems: []
            }
        case types.GET_CART_ITEMS_SUCCESS:
            return {
                loading: false, cartItems: action.payload
            }
        case types.GET_CART_ITEMS_FAIL:
            return {
                loading: false, error: action.payload
            }

        default:
            return state;
    }
}

export const addCartItemsReducer = (state = { data: [] }, action) => {
    switch (action.type) {
        case types.ADD_CART_ITEMS_REQUEST:
            return {
                loading: true, data: []
            }
        case types.ADD_CART_ITEMS_SUCCESS:
            return {
                loading: false, data: action.payload
            }
        case types.ADD_CART_ITEMS_FAIL:
            return {
                loading: false, error: action.payload
            }

        default:
            return state;
    }
}

export const clearCartItemsReducer = (state = { clearedcart: [] }, action) => {
    switch (action.type) {
        case types.REMOVE_ALL_CART_ITEMS_REQUEST:
            return {
                cartloading: true, clearedcart: []
            }
        case types.REMOVE_ALL_CART_ITEMS_SUCCESS:
            return {
                cartloading: false, clearedcart: action.payload
            }
        case types.REMOVE_ALL_CART_ITEMS_FAIL:
            return {
                cartloading: false, error: action.payload
            }

        default:
            return state;
    }
}

export const deleteCartItemReducer = (state = { deletedcart: [] }, action) => {
    switch (action.type) {
        case types.REMOVE_CART_ITEM_REQUEST:
            return {
                loading: true, deletedcart: []
            }
        case types.REMOVE_CART_ITEM_SUCCESS:
            return {
                loading: false, deletedcart: action.payload
            }
        case types.REMOVE_CART_ITEM_FAIL:
            return {
                loading: false, error: action.payload
            }

        default:
            return state;
    }
}

export const updateCartItemReducer = (state = { updatedcart: [] }, action) => {
    switch (action.type) {
        case types.UPDATE_CART_ITEM_REQUEST:
            return {
                loading: true, updatedcart: []
            }
        case types.UPDATE_CART_ITEM_SUCCESS:
            return {
                loading: false, updatedcart: action.payload
            }
        case types.UPDATE_CART_ITEM_FAIL:
            return {
                loading: false, error: action.payload
            }

        default:
            return state;
    }
}

export const cartReducer = (state = { cartLoading: [], cartItems: [], totalAmount: [], trackingCode: [], clearedCart: [], deletedItem: [], addToCartData: [], updateCartData: [], error: [] }, action) => {
    switch (action.type) {
        case types.GET_CART_ITEMS_REQUEST:
        case types.ADD_CART_ITEMS_REQUEST:
        case types.UPDATE_CART_ITEM_REQUEST:
            return {
                cartLoading: true
            }
        case types.GET_CART_ITEMS_SUCCESS:
            return {
                cartItems: action.payload.data.carts,
                totalAmount: action.payload.data.total_cost,
                trackingCode: action.payload.data.tracking_code,
                cartLoading: false
            }
        case types.ADD_CART_ITEMS_SUCCESS:
            return {
                cartLoading: false,
                addToCartData: action.payload.data
            }
        case types.UPDATE_CART_ITEM_SUCCESS:
            return {
                cartLoading: false,
                updateCartData: action.payload.data
            }
        case types.REMOVE_CART_ITEM_SUCCESS:
            return {
                cartItems: state.cartItems.filter((item) => item.id !== action.payload),
                deletedItem: action.payload,
            }
        case types.REMOVE_ALL_CART_ITEMS_SUCCESS:
            //localStorage.removeItem('cartItems')
            return {
                cartItems: [],
                totalAmount: [],
                trackingCode: [],
                clearedCart: action.payload.data
            }

        case types.GET_CART_ITEMS_FAIL:
        case types.ADD_CART_ITEMS_FAIL:
        case types.REMOVE_CART_ITEM_FAIL:
        case types.REMOVE_ALL_CART_ITEMS_FAIL:
        case types.UPDATE_CART_ITEM_FAIL:
            return {
                error: action.payload,
                cartLoading: false
            }
        default:
            return state;
    }
}