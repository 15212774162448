import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteVendorProduct } from "../../../redux/actions/vendorActions";
import Loader from "../../Reusable/Loader";
import Message from "../../Reusable/Message";

function DeleteProduct({ open, onClose, productId }) {
  const dispatch = useDispatch();

  const getDeletedProduct = useSelector((state) => state.getDeletedProduct);
  const { deleteProduct, error, loading } = getDeletedProduct;

  const handleDeleteProduct = () => {
    dispatch(deleteVendorProduct(productId));
  };

  // console.log(deleteProduct)

  // const refreshPage = ()=>{
  //     window.location.reload();
  //  }

  // useEffect(()=>{
  //     if(deleteProduct){
  //         setTimeout(() => {
  //           refreshPage();
  //       }, 4000);
  //     }
  //   }, [deleteProduct])

  if (!open) return null;
  return (
    <>
      <Modal show={open} onHide={onClose} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Product</Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-0">
          {deleteProduct !== undefined && deleteProduct.length !== 0 ? (
            <Message variant={"success"}>
              {"Product deleted successfully!"}
            </Message>
          ) : null}
          {error ? <Message variant={"danger"}>{error}</Message> : null}
          <Row className="bg-white m-auto">
            <Col xs={12} className="py-2">
              <p className="text-danger py-5">
                Are you sure you want to delete this product?
              </p>
              <div className="float-right">
                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={handleDeleteProduct}
                  variant="danger"
                  className="ml-2"
                >
                  {loading ? <Loader /> : "Yes, Delete Product"}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteProduct;
