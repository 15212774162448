import React from 'react'
import { useHistory} from 'react-router-dom';
import {Navigation} from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';

function SideNav({ open, onClose}) {

  const history = useHistory();

  return (
    <>
      <div className={open ? null : "hide-on-mobile w-100"} >
        <Navigation
            // you can use your own router's api to get pathname
          activeItemId="/dashboard"
          onSelect={({itemId}) => {
            history.push(itemId);
          }}
          items={[
            {
              title: 'Overview',
              itemId: '/dashboard',
              elemBefore: () => <span className='material-icons hide-on-mobile'>dashboard</span>
            },
            {
              title: 'Products',
              itemId: '/dashboard/products',
              elemBefore: () => <span className='material-icons hide-on-mobile'>inventory_2</span>
            },
            {
              title: 'Order',
              itemId: '/dashboard/order',
              elemBefore: () => <span className='material-icons hide-on-mobile '>fact_check</span>
            },
            {
              title: 'Profile',
              itemId: '/dashboard/profile',
              elemBefore: () => <span className='material-icons hide-on-mobile'>manage_accounts</span>
            }
          ]}
        />
      </div>
    </>
  );
}

export default SideNav;