import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import ProductNav from "../../Components/Vendor/ProductNav";
import AddProduct from "../../Components/Vendor/Products/AddProduct";

function Products({ data }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getVendorProfile = useSelector((state) => state.getProfile);
  const { vendor } = getVendorProfile.profile;

  const openAddProductModal = (data) => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="bg-light-grey h-auto py-5">
        <AddProduct open={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <div className="">
          <div className="d-flex justify-content-between mx-3">
            <h5 className="text-bold">Products</h5>
            {vendor && vendor.status_id === 1 ? (
              <Button
                className="text-white text-decoration-none"
                onClick={openAddProductModal}
              >
                {" "}
                Add New Products
              </Button>
            ) : vendor && vendor.status_id === 2 ? (
              <p className="text-decoration-none">Account Status: Inactive</p>
            ) : null}
          </div>
          <ProductNav orderData={data} />
        </div>
      </div>
    </>
  );
}

export default Products;
