import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../Assets/leasehouz-logo.jpg'

function Logo() {
    return (
        <>
            <Link to='/'>
                <img className='w-50' src={logo} alt="Leasehouz Logo" />
            </Link>
        </>
    )
}

export default Logo
