import React from "react";
import { Col, Container } from "react-bootstrap";
import { Route, Switch, useHistory, useLocation } from "react-router";
import Footer from "../Components/Reusable/Footer";
import Header from "../Components/Reusable/Header";
// import Newsletter from "../Components/Reusable/Newsletter";
import Transactions from "./Transactions";
import UserProfile from "./UserProfile";
import ChangePassword from "./ChangePassword";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import Leases from "./Leases";

function UserRoutes({ match }) {
  const history = useHistory();
  const location = useLocation();
  return (
    <>
      <div className="bg-light-grey pt-4 px-2 p-bottom45">
        <Container>
          <Header />
          <h3 className="my-4">Profile</h3>
          <div className="d-flex justify-content-between direction-col">
            <div className="profile-nav bg-white p-2 height-fit mr-3 mobile-margin">
              <Navigation
                // you can use your own router's api to get pathname
                activeItemId={location.pathname}
                onSelect={({ itemId }) => {
                  history.push(itemId);
                }}
                items={[
                  {
                    title: "Profile Details",
                    itemId: "/user",
                    key: "Personal Information",
                    elemBefore: () => (
                      <span className="material-icons">manage_accounts</span>
                    ),
                  },
                  {
                    title: "Instant Orders",
                    itemId: "/user/transactions",
                    key: "Orders",
                    elemBefore: () => (
                      <span className="material-icons ">fact_check</span>
                    ),
                  },
                  {
                    title: "Lease Orders",
                    itemId: "/user/lease",
                    key: "Lease",
                    elemBefore: () => (
                      <span className="material-icons ">fact_check</span>
                    ),
                  },
                  {
                    title: "Change Password",
                    itemId: "/user/change-password",
                    key: "Password",
                    elemBefore: () => (
                      <span className="material-icons ">password</span>
                    ),
                  },
                ]}
              />
            </div>
            <div className="bg-white profile-form py-4">
              <Col>
                <Switch>
                  <Route exact path="/user" component={UserProfile} />
                  <Route
                    path={match.url + "/transactions"}
                    component={Transactions}
                  />
                  <Route path={match.url + "/lease"} component={Leases} />
                  <Route
                    path={match.url + "/change-password"}
                    component={ChangePassword}
                  />
                </Switch>
              </Col>
            </div>
          </div>
        </Container>
      </div>
      {/* <Newsletter /> */}
      <Footer />
    </>
  );
}

export default UserRoutes;
