import React from 'react'
import Empty from '../../../Assets/empty.png'

 function NoData(props) {
    return (
        <>
            <h5 className="mb-3 text-bold">No {props.name}</h5>
            <div className="bg-white mb-5 p-5 rounded-3">
                <div className="text-center">
                    <img src={Empty} alt="empty state" className="my-3 width-50"/>
                    <p> {props.name} will appear here</p>
                </div>
            </div>
        </>
    )
}

export default NoData;
