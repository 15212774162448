import axios from 'axios'
import * as types from '../types'
import { Cookies } from 'react-cookie'
import { baseurl } from '../../Utils/baseurl'

const cookies = new Cookies()

export const getProductCategories = () => async (dispatch) => {
    try {
        dispatch({ type: types.PRODUCT_CATEGORIES_REQUEST })

        const { data } = await axios.get(`${baseurl}/products/categories/all`)

        dispatch({
            type: types.PRODUCT_CATEGORIES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.PRODUCT_CATEGORIES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const getProductSubCategories = () => async (dispatch) => {
    try {
        dispatch({ type: types.PRODUCT_SUBCATEGORIES_REQUEST })

        const { data } = await axios.get(`${baseurl}/products/sub-categories/1/all`)

        dispatch({
            type: types.PRODUCT_SUBCATEGORIES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.PRODUCT_SUBCATEGORIES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}


export const allSubcategories = []
export const getProductsBySubCategory = (id, page) => async (dispatch, getState) => {
    try {
        dispatch({ type: types.PRODUCTS_BY_SUBCATEGORIES_REQUEST })

        const { data } = await axios.get(`${baseurl}/marketplace/products/sub-category/${id}?page=${page}`)
        allSubcategories.push(data.data.products.data)

        dispatch({
            type: types.PRODUCTS_BY_SUBCATEGORIES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.PRODUCTS_BY_SUBCATEGORIES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const clearAllSubcategories = () => () => {
    allSubcategories.length = []
}

export const searchProduct = (term, page) => async (dispatch) => {
    try {
        dispatch({ type: types.SEARCH_PRODUCT_REQUEST })

        const { data } = await axios.get(`${baseurl}/marketplace/products/search?query=${term}&page=${page}`)

        dispatch({
            type: types.SEARCH_PRODUCT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.SEARCH_PRODUCT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}


export const getProductsByTags = (id) => async (dispatch) => {
    try {
        dispatch({ type: types.PRODUCTS_BY_TAGS_REQUEST })

        const { data } = await axios.get(`${baseurl}/marketplace/tag/${id}`)

        dispatch({
            type: types.PRODUCTS_BY_TAGS_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: types.PRODUCTS_BY_TAGS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const getProductAttribute = (product_id) => async (dispatch) => {
    try {
        dispatch({ type: types.PRODUCT_ATTRIBUTE_REQUEST })

        const { data } = await axios.get(`${baseurl}/marketplace/attribute/products/${product_id}`)

        dispatch({
            type: types.PRODUCT_ATTRIBUTE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.PRODUCT_ATTRIBUTE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}
export const getShippingInfo = () => async (dispatch) => {
    const token = cookies.get('token');
    try {
        dispatch({ type: types.SHIPPING_INFO_REQUEST })
        const config = { headers: { Authorization: `Bearer ${token}` } }

        const { data } = await axios.get(`${baseurl}/shippings/`, config)

        dispatch({
            type: types.SHIPPING_INFO_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: types.SHIPPING_INFO_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const getBrands = () => async (dispatch) => {
    try {
        dispatch({ type: types.FETCH_BRANDS_REQUEST })

        const { data } = await axios.get(`${baseurl}/brands/all`)

        dispatch({
            type: types.FETCH_BRANDS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.FETCH_BRANDS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const getProductsById = (id) => async (dispatch) => {
    try {
        dispatch({ type: types.PRODUCTS_BY_ID_REQUEST })

        const { data } = await axios.get(`${baseurl}/marketplace/product/${id}`)
        dispatch({
            type: types.PRODUCTS_BY_ID_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: types.PRODUCTS_BY_ID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const outrightPayment = (paymentData) => async (dispatch) => {

    const token = cookies.get('token');

    try {
        dispatch({ type: types.OUTRIGHT_PAYMENT_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };

        const { data } = await axios.post(`${baseurl}/transaction/outright`, paymentData, config)
        dispatch({
            type: types.OUTRIGHT_PAYMENT_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: types.OUTRIGHT_PAYMENT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}
export const leasehouzPayment = (paymentData) => async (dispatch) => {

    const token = cookies.get('token');

    try {
        dispatch({ type: types.LEASEHOUZ_PAYMENT_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };

        const { data } = await axios.post(`${baseurl}/transaction/outright`, paymentData, config)
        dispatch({
            type: types.LEASEHOUZ_PAYMENT_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: types.LEASEHOUZ_PAYMENT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}
export const processLeasehouzPayment = (formData) => async (dispatch) => {

    const token = cookies.get('token');

    try {
        dispatch({ type: types.PROCESS_LEASEHOUZ_PAYMENT_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                // Accept:
                Authorization: `Bearer ${token}`
            }
        };

        const { data } = await axios.post(`${baseurl}/transaction/bank/payment`, formData, config)
        dispatch({
            type: types.PROCESS_LEASEHOUZ_PAYMENT_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: types.PROCESS_LEASEHOUZ_PAYMENT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const getPaymentChannel = () => async (dispatch) => {
    try {
        dispatch({ type: types.PAYMENT_CHANNEL_REQUEST })
        const { data } = await axios.get(`${baseurl}/channels`)

        dispatch({
            type: types.PAYMENT_CHANNEL_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.PAYMENT_CHANNEL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const verifyPayment = (paymentReference) => async (dispatch) => {
    try {
        dispatch({ type: types.VERIFY_PAYMENT_REQUEST })
        const { data } = await axios.get(`${baseurl}/transaction/paystack/verify${paymentReference}`)

        dispatch({
            type: types.VERIFY_PAYMENT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.VERIFY_PAYMENT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const fetchUserOrders = () => async (dispatch) => {
    const token = cookies.get('token');

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };

    try {
        dispatch({ type: types.USER_FETCH_ORDERS_REQUEST })
        const { data } = await axios.get(`${baseurl}/orders`, config)

        dispatch({
            type: types.USER_FETCH_ORDERS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.USER_FETCH_ORDERS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const fetchUserOrdersByTrackingCode = (code) => async (dispatch) => {
    const token = cookies.get('token');

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };

    try {
        dispatch({ type: types.ORDERS_BY_TRACKING_CODE_REQUEST })
        const { data } = await axios.get(`${baseurl}/orders/products/${code}`, config)

        dispatch({
            type: types.ORDERS_BY_TRACKING_CODE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.ORDERS_BY_TRACKING_CODE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}