import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Components/Reusable/Footer";
import Header from "../Components/Reusable/Header";
import HeaderCarousel from "../Components/Reusable/HeaderCarousel";
import Sino from "../Assets/SINOTRUCK.png";
import Simba from "../Assets/SIMBA-GROUP.png";
import Wonderwheels from "../Assets/wonderwheels.png";
import Tetrapak from "../Assets/TETRAPAK.png";
import Tatacars from "../Assets/TATA-CARS.png";
import Smt from "../Assets/SMT.png";
import Richbon from "../Assets/RICHBON.png";
import PM from "../Assets/pm-group.jpg";
import Panasonic from "../Assets/PANASONIC.png";
import Sevan from "../Assets/Sevan.png";
import SCOA from "../Assets/SCOA.png";
import Lanre from "../Assets/lanre-shittu.png";
import Koncept from "../Assets/koncept.jpg";
import Kojo from "../Assets/kojo-motors.jpg";
import Metropolitan from "../Assets/Metropolitan-Motors.png";
import Mantrack from "../Assets/MANTRACK.png";
import LG from "../Assets/LG.png";
import Jubaili from "../Assets/JUBAILI-BRO.png";
import CFAO from "../Assets/CFAO.png";
import Castalat from "../Assets/CASTALAT.png";
import IPI from "../Assets/IPI.png";
import Heidelberg from "../Assets/HEIDELBERG.png";
import Bukkehave from "../Assets/Bukkehave.png";
import Access from "../Assets/access-bank.png";
import Leadway from "../Assets/leadway.jpg";

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Thumbs,
  EffectCoverflow,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/components/thumbs/thumbs.scss";
// import 'swiper/css/EffectCoverflow.css';

// install Swiper modules
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Thumbs,
  EffectCoverflow,
]);

function OurPartners() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const images = [
    Sino,
    Simba,
    Wonderwheels,
    Tetrapak,
    Tatacars,
    Smt,
    Richbon,
    PM,
    Panasonic,
    Sevan,
    SCOA,
    Lanre,
    Koncept,
    Kojo,
    Metropolitan,
    Mantrack,
    LG,
    Jubaili,
    CFAO,
    Castalat,
    IPI,
    Heidelberg,
    Bukkehave,
  ];
  return (
    <>
      <div className="bg-light-grey w-100">
        <Container className="py-3 mb-5" fluid="md">
          <Header />
          <HeaderCarousel />
        </Container>
        <div className="bg-white">
          <Container fluid="md">
            <div className="mt-5 py-5">
              <h3 className="underline-heading my-5">Vendors & suppliers</h3>
              <Row>
                <Col xs={12}>
                  <Swiper
                    spaceBetween={100}
                    slidesPerView={1}
                    centeredSlides
                    autoplay
                    grabCursor={true}
                    speed={1000}
                    effect={"coverflow"}
                    freeMode={true}
                    pagination={{ clickable: true }}
                    thumbs={{ swiper: thumbsSwiper }}
                    className="product-slide pb-5 justify-content-center"
                    breakpoints={{
                      768: {
                        width: 768,
                        slidesPerView: 2,
                        spaceBetween: 50,
                      },
                      1024: {
                        width: 1024,
                        slidesPerView: 4,
                        spaceBetween: 50,
                      },
                    }}
                  >
                    {images.map((image, i) => {
                      return (
                        <SwiperSlide
                          key={i}
                          className="py-5 d-flex justify-content-center align-items-center"
                        >
                          <div className="">
                            <img
                              src={image}
                              alt=""
                              className="py-5 w-100 h-100 object-fit-cover"
                            />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Col>
                <Col xs={12} className="">
                  <Swiper
                    id="thumbs"
                    onSwiper={setThumbsSwiper}
                    spaceBetween={30}
                    slidesPerView={4}
                    className="thumbs-wrapper"
                    breakpoints={{
                      768: {
                        width: 768,
                        slidesPerView: 6,
                      },
                    }}
                  >
                    {images.map((image, i) => {
                      return (
                        <SwiperSlide
                          key={i}
                          className=" d-flex justify-content-center align-items-center"
                        >
                          <div className="py-4">
                            <img src={image} alt="" className="w-100" />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Col>
              </Row>
            </div>
            <div className="pb-5">
              <h3 className="underline-heading mb-4">
                Financial Institutions{" "}
              </h3>
              <Row className="align-items-center">
                <Col xs={5} md={3}>
                  <div className="">
                    <img
                      src={Leadway}
                      alt=""
                      className="w-100 h-100 object-fit-cover"
                    />
                  </div>
                </Col>
                <Col xs={5} md={3}>
                  <div className="">
                    <img
                      src={Access}
                      alt=""
                      className="w-100 h-100 object-fit-cover"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default OurPartners;
