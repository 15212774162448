import React from 'react'
//import { Link } from 'react-router-dom'
import { Carousel } from 'react-bootstrap'
import rectangle from '../../Assets/brown-rectangle.png'
import slider2 from '../../Assets/tractor-1.jpg'
import slider3 from '../../Assets/appliances.jpg'
import suv from '../../Assets/suv.png'

const HeaderCarousel = () => {

    return (
        <Carousel>
            <Carousel.Item  className='bg-blue'>
                <img className="first-slide-image" src={rectangle} alt="First slide" />
                <Carousel.Caption>
                    <h3>Take delivery and enjoy the use of your choice items TODAY, while you pay us in CONVENIENT Installments over an agreed period of time</h3>
                    <p className='text-white'>You can PAY SMALL SMALL</p>
                </Carousel.Caption>
                <img src={suv} className='carousel-suv-image' alt="" />
            </Carousel.Item>
            <Carousel.Item>
                <div className="overlay"></div>
                <img className=" first-slide-image d-block w-100 object-fit-cover" fluid='true' src={slider2} alt="Second slide" />
                <Carousel.Caption>
                    <h3>We support businesses and corporate organisations with required equipment</h3>
                    <p className='text-white'>Our offerings are designed to address both financial and non-financial gaps experienced by our customers.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <div className="overlay"></div>
                <img className="d-block w-100 object-fit-cover" fluid='true' src={slider3} alt="Third slide" />
                <Carousel.Caption>
                    <h3>Home appliances and general items</h3>
                    <p className='text-white'>Warranty available on items (where applicable).</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
    
}

export default HeaderCarousel
