import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getStates } from "../../../../redux/actions/authActions";
import { getCountries } from "../../../../redux/actions/authActions";
import { Col, Form, Row } from "react-bootstrap";
import { saveIndividualLeaseData } from "../../../../redux/actions/leaseActions";

function PersonalInformation({ onSelect }) {
  const [meansOfId, setMeansOfId] = useState();
  const [location, setLocation] = useState();
  const [idError, setIdError] = useState("");
  const idSelectRef = useRef(null);

  const getIdType = (e) => {
    setMeansOfId(e.target.value);
  };

  const getLocationValue = (e) => {
    setLocation(e.target.value);
    if (e.target.name === "country_id") {
      dispatch(getStates(e.target.value));
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStates(160));
  }, [dispatch]);

  const { register, handleSubmit, getValues } = useForm();

  const onSubmit = (data, e) => {
    const { identification_type } = getValues();
    if (identification_type === "Select an id") {
      setIdError("Please choose an id");
      return false;
    }
    saveIndividualLeaseData(data);
    onSelect();
  };
  const onError = (error, e) => {
    // do something if error occurs
  };
  //fetch states data
  const allStates = useSelector((state) => state.getStates);
  const { states } = allStates;

  //fetch countries data
  const userCountries = useSelector((state) => state.userCountries);
  const { countries } = userCountries;

  return (
    <>
      <div>
        <form
          className="bg-white py-2 px-4"
          onSubmit={(e) => handleSubmit(onSubmit, onError)(e)}
        >
          <h5 className="my-4 font-bold">Personal Details</h5>
          <Row>
            <Col xs={12} md={6} lg={6} className="py-2">
              <Form.Group className="mb-3" controlId="other-names">
                <Form.Label>
                  Other name(s) <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="other_names"
                  className="bg-light-grey"
                  {...register("other_names")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="dob">
                <Form.Label>
                  Date of Birth <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="date_of_birth"
                  className="bg-light-grey"
                  {...register("date_of_birth")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="maritalstatus">
                <Form.Label>
                  Marital Status <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="marital_status"
                  className="bg-light-grey"
                  {...register("marital_status")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="religion">
                <Form.Label>
                  Religion <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="religion"
                  className="bg-light-grey"
                  {...register("religion")}
                >
                  <option>Select a religion</option>
                  <option value="christainity">Christainity</option>
                  <option value="Islam">Islam</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={6} className="py-2">
              <Form.Group className="mb-3" controlId="id">
                <Form.Label>
                  Identification Type: <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  ref={idSelectRef}
                  as="select"
                  name="id"
                  {...register("identification_type")}
                  className="bg-light-grey"
                  onChange={getIdType}
                  onMouseEnter={() => {
                    setIdError("");
                  }}
                >
                  <option>Select an id</option>
                  <option value="National Id">National ID</option>
                  <option value="Passport">Passport</option>
                </Form.Control>
                {idError && <p className="text-red">{idError}</p>}
              </Form.Group>
              <div>
                {meansOfId === "National Id" ? (
                  <Form.Group className="mb-3" controlId="national id">
                    <Form.Label>
                      National Id Number <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="national_id"
                      className="bg-light-grey"
                      {...register("national_id")}
                    />
                  </Form.Group>
                ) : meansOfId === "Passport" ? (
                  <Form.Group className="mb-3" controlId="passport">
                    <Form.Label>
                      Passport Number <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="passport"
                      className="bg-light-grey"
                      {...register("passport")}
                    />
                  </Form.Group>
                ) : null}
              </div>
              <Form.Group className="mb-3" controlId="nationality">
                <Form.Label>
                  Nationality <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="nationality"
                  value={location}
                  onChange={getLocationValue}
                  className="bg-light-grey"
                  {...register("country_id")}
                >
                  <option>Select nationality</option>
                  {countries.map((country, id) => {
                    return (
                      <option value={country.id} key={id}>
                        {country.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="stateoforigin">
                <Form.Label>
                  State of Origin <span className="text-red">*</span>{" "}
                </Form.Label>
                <Form.Control
                  as="select"
                  name="state_of_origin"
                  value={location}
                  className="bg-light-grey"
                  onChange={getLocationValue}
                  {...register("state_of_origin")}
                >
                  <option>Select state</option>
                  {states.map((state, id) => {
                    return (
                      <option value={state.id} key={id}>
                        {state.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="gender">
                <Form.Label>
                  Gender <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  className="bg-light-grey"
                  {...register("gender")}
                >
                  <option>Select a gender</option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <button
            type="submit"
            className="d-flex ml-auto border-0 mt-2 px-4 py-2"
          >
            Next
          </button>
          {/* onClick={} */}
        </form>
      </div>
    </>
  );
}

export default PersonalInformation;
