import React from "react";
import { Container, Nav } from "react-bootstrap";
import twitter from "../../Assets/twitter.png";
import linkedin from "../../Assets/linkedin-icon.png";
import facebook from "../../Assets/facebook-icon.png";
import Instagram from "../../Assets/instagram.svg";

function Footer() {
  return (
    <>
      <div className="bg-dark-blue py-5">
        <Container className="" fluid="md">
          <Nav className="justify-content-center" activeKey="/">
            <Nav.Item>
              <Nav.Link href="/" className="text-white">
                Marketplace
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/about" eventKey="link-1" className="text-white">
                About us
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="/services"
                eventKey="link-2"
                className="text-white"
              >
                Our Services
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="/partners"
                eventKey="link-2"
                className="text-white"
              >
                Our Partners
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="/contact"
                eventKey="link-2"
                className="text-white"
              >
                Contact Us
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/terms" eventKey="link-2" className="text-white">
                Terms & Conditions
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav className="justify-content-center my-4" activeKey="/home">
            <Nav.Item>
              <Nav.Link
                href="https://www.facebook.com/profile.php?id=100063546282500&mibextid=LQQJ4d"
                target="_blank"
                eventKey="link-2"
                className="text-white"
              >
                <img src={facebook} alt="facebook" className="w-75" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://x.com/leasehouz?s=21&t=YjelrtDxZWWYEbgVQ-jw3A"
                target="_blank"
                eventKey="link-2"
                className="text-white"
              >
                <img src={twitter} alt="twitter" className="w-75" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://www.linkedin.com/in/leasehouz-8b9b55163"
                target="_blank"
                eventKey="link-2"
                className="text-white"
              >
                <img src={linkedin} alt="linkedin" className="w-75" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://instagram.com/leasehouz?igshid=MzRlODBiNWFlZA=="
                target="_blank"
                eventKey="link-2"
                className="text-white"
              >
                <img src={Instagram} alt="instagram" className="w-75" />
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <p className="text-center text-white">
            &copy; Copyright {new Date().getFullYear()} | Leasehouz, powered by
            Assetkap Investment Limited
          </p>
        </Container>
      </div>
    </>
  );
}

export default Footer;
