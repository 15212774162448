import React, { useRef, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../Assets/blank-avatar.png";
import Loader from "../../Components/Reusable/Loader";
import Message from "../../Components/Reusable/Message";
import { updateVendorProfile } from "../../redux/actions/vendorActions";

function Profile() {
  const dispatch = useDispatch();
  const [avatarImage, setavatarImage] = useState(Avatar);

  const getProfile = useSelector((state) => state.getProfile);
  const {
    profile,
    profile: { vendor },
  } = getProfile;

  //UPLOAD VENDOR IMAGE
  const avatarInputRef = useRef(null);
  const uploadAvatar = () => {
    avatarInputRef.current.click();
  };

  const avatarImageChange = (e) => {
    let img = document.querySelector("#avatar");
    img.onload = () => {
      URL.revokeObjectURL(img.src);
    };
    img.src = URL.createObjectURL(e.target.files[0]);
    setavatarImage(URL.createObjectURL(e.target.files[0]));
  };

  const formRef = useRef(null);
  const updateProfile = () => {
    const form = formRef.current;
    let formdata = new FormData(form);
    dispatch(updateVendorProfile(formdata, vendor.id));
  };

  //GET RESPONSE FROM PROFILE UPDATE REQUEST
  const updatedVendorProfile = useSelector(
    (state) => state.updatedVendorProfile
  );
  const { loading, error, data } = updatedVendorProfile;

  return (
    <>
      <div className="bg-light-grey py-5">
        {error ? (
          <Message variant="danger">Failed to update profile!</Message>
        ) : data ? (
          <Message variant="success">Profile updated successfully!</Message>
        ) : null}
        <div>
          <h5 className="pb-2 mx-3">Profile</h5>
          <Row className="bg-white mx-3 rounded-3">
            <Col className="py-3">
              <Form ref={formRef}>
                <div className="d-flex justify-content-between width-100 height-100 position-relative">
                  <img
                    src={vendor ? vendor.banner : avatarImage}
                    alt="vendor-avatar"
                    className="width-100 height-100 object-fit-cover opacity-1 rounded-circle "
                    id="avatar"
                  />
                  <div className="vendor-profile-icon-wrapper rounded-circle">
                    <input
                      style={{ display: "none" }}
                      type="file"
                      ref={avatarInputRef}
                      onChange={avatarImageChange}
                      name="banner"
                      id=""
                    />
                    <span className="material-icons" onClick={uploadAvatar}>
                      camera_alt
                    </span>
                  </div>
                </div>
                <h5 className="mt-4 pt-4 pb-2 border-top">Personal Details</h5>
                <Row>
                  <Col className="pl-0" xs={12} lg={6}>
                    <Form.Group as={Col} className="mb-3" controlId="full-name">
                      <Form.Label>Fullname</Form.Label>
                      <Form.Control
                        defaultValue={vendor && vendor.name}
                        type="text"
                        name="name"
                        className="bg-light-grey"
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="email-address"
                    >
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={profile && profile.email}
                        type="text"
                        className="bg-light-grey"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="pl-0">
                    <Form.Group as={Col} className="mb-3" controlId="phone">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={profile && profile.phone}
                        type="text"
                        className="bg-light-grey"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <h5 className="mt-4 pt-4 pb-2 border-top">Business Details</h5>
                <Row>
                  <Col sm={12} lg={6} className="pl-0 ">
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="business-name"
                    >
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        defaultValue={vendor && vendor.store_name}
                        name="store_name"
                        type="text"
                        className="bg-light-grey"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} lg={6} className="pl-0">
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="business-address"
                    >
                      <Form.Label>Business Address</Form.Label>
                      <Form.Control
                        defaultValue={vendor && vendor.address}
                        name="address"
                        type="text"
                        className="bg-light-grey"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <div className="m-3">
            <button
              onClick={updateProfile}
              className="bg-blue float-right text-white border-blue rounded-3 py-1 px-4"
            >
              {!loading ? "Save" : <Loader />}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
