// Auth types
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const VERIFY_REQUEST = 'VERIFY_REQUEST'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const VERIFY_FAIL = 'VERIFY_FAIL'

export const RESEND_CODE_REQUEST = 'RESEND_CODE_REQUEST'
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS'
export const RESEND_CODE_FAIL = 'RESEND_CODE_FAIL'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

export const CREATE_VENDOR_REQUEST = 'CREATE_VENDOR_REQUEST'
export const CREATE_VENDOR_SUCCESS = 'CREATE_VENDOR_SUCCESS'
export const CREATE_VENDOR_FAIL = 'CREATE_VENDOR_FAIL'

export const PRODUCT_CATEGORIES_REQUEST = 'PRODUCT_CATEGORIES_REQUEST'
export const PRODUCT_CATEGORIES_SUCCESS = 'PRODUCT_CATEGORIES_SUCCESS'
export const PRODUCT_CATEGORIES_FAIL = 'PRODUCT_CATEGORIES_FAIL'

export const PRODUCT_SUBCATEGORIES_REQUEST = 'PRODUCT_SUBCATEGORIES_REQUEST'
export const PRODUCT_SUBCATEGORIES_SUCCESS = 'PRODUCT_SUBCATEGORIES_SUCCESS'
export const PRODUCT_SUBCATEGORIES_FAIL = 'PRODUCT_SUBCATEGORIES_FAIL'

export const PRODUCTS_BY_SUBCATEGORIES_REQUEST = 'PRODUCTS_BY_SUBCATEGORIES_REQUEST'
export const PRODUCTS_BY_SUBCATEGORIES_SUCCESS = 'PRODUCTS_BY_SUBCATEGORIES_SUCCESS'
export const PRODUCTS_BY_SUBCATEGORIES_FAIL = 'PRODUCTS_BY_SUBCATEGORIES_FAIL'

export const PRODUCTS_BY_TAGS_REQUEST = 'PRODUCTS_BY_TAGS_REQUEST'
export const PRODUCTS_BY_TAGS_SUCCESS = 'PRODUCTS_BY_TAGS_SUCCESS'
export const PRODUCTS_BY_TAGS_FAIL = 'PRODUCTS_BY_TAGS_FAIL'

export const PRODUCTS_BY_ID_REQUEST = 'PRODUCTS_BY_ID_REQUEST'
export const PRODUCTS_BY_ID_SUCCESS = 'PRODUCTS_BY_ID_SUCCESS'
export const PRODUCTS_BY_ID_FAIL = 'PRODUCTS_BY_ID_FAIL'

export const FETCH_BRANDS_REQUEST = 'FETCH_BRANDS_REQUEST'
export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS'
export const FETCH_BRANDS_FAIL = 'FETCH_BRANDS_FAIL'

export const FETCH_SUBCATEGORIES_REQUEST = 'FETCH_SUBCATEGORIES_REQUEST'
export const FETCH_SUBCATEGORIES_SUCCESS = 'FETCH_SUBCATEGORIES_SUCCESS'
export const FETCH_SUBCATEGORIES_FAIL = 'FETCH_SUBCATEGORIES_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_TO_CART_FAIL = 'ADD_TO_CART_FAIL'

export const PRODUCT_ATTRIBUTE_REQUEST = 'PRODUCT_ATTRIBUTE_REQUEST'
export const PRODUCT_ATTRIBUTE_SUCCESS = 'PRODUCT_ATTRIBUTE_SUCCESS'
export const PRODUCT_ATTRIBUTE_FAIL = 'PRODUCT_ATTRIBUTE_FAIL'

export const SEARCH_PRODUCT_REQUEST = 'SEARCH_PRODUCT_REQUEST'
export const SEARCH_PRODUCT_SUCCESS = 'SEARCH_PRODUCT_SUCCESS'
export const SEARCH_PRODUCT_FAIL = 'SEARCH_PRODUCT_FAIL'

export const SHIPPING_INFO_REQUEST = 'SHIPPING_INFO_REQUEST'
export const SHIPPING_INFO_SUCCESS = 'SHIPPING_INFO_SUCCESS'
export const SHIPPING_INFO_FAIL = 'SHIPPING_INFO_FAIL'

export const GET_CART_ITEMS_REQUEST = 'GET_CART_ITEMS_REQUEST'
export const GET_CART_ITEMS_SUCCESS = 'GET_CART_ITEMS_SUCCESS'
export const GET_CART_ITEMS_FAIL = 'GET_CART_ITEMS_FAIL'

export const ADD_CART_ITEMS_REQUEST = 'ADD_CART_ITEMS_REQUEST'
export const ADD_CART_ITEMS_SUCCESS = 'ADD_CART_ITEMS_SUCCESS'
export const ADD_CART_ITEMS_FAIL = 'ADD_CART_ITEMS_FAIL'

export const REMOVE_ALL_CART_ITEMS_REQUEST = 'REMOVE_ALL_CART_ITEMS_REQUEST'
export const REMOVE_ALL_CART_ITEMS_SUCCESS = 'REMOVE_ALL_CART_ITEMS_SUCCESS'
export const REMOVE_ALL_CART_ITEMS_FAIL = 'REMOVE_ALL_CART_ITEMS_FAIL'

export const REMOVE_CART_ITEM_REQUEST = 'REMOVE_CART_ITEM_REQUEST'
export const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS'
export const REMOVE_CART_ITEM_FAIL = 'REMOVE_CART_ITEM_FAIL'

export const UPDATE_CART_ITEM_REQUEST = 'UPDATE_CART_ITEM_REQUEST'
export const UPDATE_CART_ITEM_SUCCESS = 'UPDATE_CART_ITEM_SUCCESS'
export const UPDATE_CART_ITEM_FAIL = 'UPDATE_CART_ITEM_FAIL'


export const INCREASE_ITEM = 'INCREASE_ITEM'
export const DECREASE_ITEM = 'DECREASE_ITEM'
export const INCREASE_ITEM_FAIL = 'INCREASE_ITEM_FAIL'
export const DECREASE_ITEM_FAIL = 'DECREASE_ITEM_FAIL'
export const REMOVE_ALL_CART_ITEMS = 'REMOVE_ALL_CART_ITEMS'

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL'

export const GET_STATES_REQUEST = 'GET_STATES_REQUEST'
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS'
export const GET_STATES_FAIL = 'GET_STATES_FAIL'

export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST'
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'
export const GET_COUNTRIES_FAIL = 'GET_COUNTRIES_FAIL'

export const UPDATE_BIO_REQUEST = 'UPDATE_BIO_REQUEST'
export const UPDATE_BIO_SUCCESS = 'UPDATE_BIO_SUCCESS'
export const UPDATE_BIO_FAIL = 'UPDATE_BIO_FAIL'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'
export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST'
export const GET_USER_PROFILE_FAILED = 'GET_USER_PROFILE_FAILED'

export const VENDOR_PROFILE_UPDATE_REQUEST = 'VENDOR_PROFILE_UPDATE_REQUEST'
export const VENDOR_PROFILE_UPDATE_SUCCESS = 'VENDOR_PROFILE_UPDATE_SUCCESS'
export const VENDOR_PROFILE_UPDATE_FAIL = 'VENDOR_PROFILE_UPDATE_FAIL'

export const VENDOR_FETCH_PRODUCTS_REQUEST = 'VENDOR_FETCH_PRODUCTS_REQUEST'
export const VENDOR_FETCH_PRODUCTS_SUCCESS = 'VENDOR_FETCH_PRODUCTS_SUCCESS'
export const VENDOR_FETCH_PRODUCTS_FAIL = 'VENDOR_FETCH_PRODUCTS_FAIL'

export const VENDOR_CREATE_PRODUCT_REQUEST = 'VENDOR_CREATE_PRODUCT_REQUEST'
export const VENDOR_CREATE_PRODUCT_SUCCESS = 'VENDOR_CREATE_PRODUCT_SUCCESS'
export const VENDOR_CREATE_PRODUCT_FAIL = 'VENDOR_CREATE_PRODUCT_FAIL'

export const VENDOR_DELETE_PRODUCT_REQUEST = 'VENDOR_DELETE_PRODUCT_REQUEST'
export const VENDOR_DELETE_PRODUCT_SUCCESS = 'VENDOR_DELETE_PRODUCT_SUCCESS'
export const VENDOR_DELETE_PRODUCT_FAIL = 'VENDOR_DELETE_PRODUCT_FAIL'

export const VENDOR_FETCH_ORDERS_REQUEST = 'VENDOR_FETCH_ORDERS_REQUEST'
export const VENDOR_FETCH_ORDERS_SUCCESS = 'VENDOR_FETCH_ORDERS_SUCCESS'
export const VENDOR_FETCH_ORDERS_FAIL = 'VENDOR_FETCH_ORDERS_FAIL'

export const VENDOR_FETCH_METRICS_REQUEST = 'VENDOR_FETCH_METRICS_REQUEST'
export const VENDOR_FETCH_METRICS_SUCCESS = 'VENDOR_FETCH_METRICS_SUCCESS'
export const VENDOR_FETCH_METRICS_FAIL = 'VENDOR_FETCH_METRICS_FAIL'

// export const VENDOR_SHIPPING_ORDERS_REQUEST = 'VENDOR_SHIPPING_ORDERS_REQUEST'
// export const VENDOR_SHIPPING_ORDERS_SUCCESS = 'VENDOR_SHIPPING_ORDERS_SUCCESS'
// export const VENDOR_SHIPPING_ORDERS_FAIL = 'VENDOR_SHIPPING_ORDERS_FAIL'

export const OUTRIGHT_PAYMENT_REQUEST = 'OUTRIGHT_PAYMENT_REQUEST'
export const OUTRIGHT_PAYMENT_SUCCESS = 'OUTRIGHT_PAYMENT_SUCCESS'
export const OUTRIGHT_PAYMENT_FAIL = 'OUTRIGHT_PAYMENT_FAIL'

export const LEASEHOUZ_PAYMENT_REQUEST = 'LEASEHOUZ_PAYMENT_REQUEST'
export const LEASEHOUZ_PAYMENT_SUCCESS = 'LEASEHOUZ_PAYMENT_SUCCESS'
export const LEASEHOUZ_PAYMENT_FAIL = 'LEASEHOUZ_PAYMENT_FAIL'

export const PROCESS_LEASEHOUZ_PAYMENT_REQUEST = 'PROCESS_LEASEHOUZ_PAYMENT_REQUEST'
export const PROCESS_LEASEHOUZ_PAYMENT_SUCCESS = 'PROCESS_LEASEHOUZ_PAYMENT_SUCCESS'
export const PROCESS_LEASEHOUZ_PAYMENT_FAIL = 'PROCESS_LEASEHOUZ_PAYMENT_FAIL'

export const USER_FETCH_ORDERS_REQUEST = 'USER_FETCH_ORDERS_REQUEST'
export const USER_FETCH_ORDERS_SUCCESS = 'USER_FETCH_ORDERS_SUCCESS'
export const USER_FETCH_ORDERS_FAIL = 'USER_FETCH_ORDERS_FAIL'

export const ORDERS_BY_TRACKING_CODE_REQUEST = 'ORDERS_BY_TRACKING_CODE_REQUEST'
export const ORDERS_BY_TRACKING_CODE_SUCCESS = 'ORDERS_BY_TRACKING_CODE_SUCCESS'
export const ORDERS_BY_TRACKING_CODE_FAIL = 'ORDERS_BY_TRACKING_CODE_FAIL'

export const PAYMENT_CHANNEL_REQUEST = 'OUTRIGHT_PAYMENT_REQUEST'
export const PAYMENT_CHANNEL_SUCCESS = 'OUTRIGHT_PAYMENT_SUCCESS'
export const PAYMENT_CHANNEL_FAIL = 'OUTRIGHT_PAYMENT_FAIL'

export const VERIFY_PAYMENT_REQUEST = 'VERIFY_PAYMENT_REQUEST'
export const VERIFY_PAYMENT_SUCCESS = 'VERIFY_PAYMENT_SUCCESS'
export const VERIFY_PAYMENT_FAIL = 'VERIFY_PAYMENT_FAIL'

export const SAVE_INDIVIDUAL_LEASE_DATA = 'SAVE_INDIVIDUAL_LEASE_DATA'

export const SUBMIT_INDIVIDUAL_LEASE_DATA_REQUEST = 'SUBMIT_INDIVIDUAL_LEASE_DATA_REQUEST'
export const SUBMIT_INDIVIDUAL_LEASE_DATA_SUCCESS = 'SUBMIT_INDIVIDUAL_LEASE_DATA_SUCCESS'
export const SUBMIT_INDIVIDUAL_LEASE_DATA_FAIL = 'SUBMIT_INDIVIDUAL_LEASE_DATA_FAIL'

export const SUBMIT_COMPANY_LEASE_DATA_REQUEST = 'SUBMIT_COMPANY_LEASE_DATA_REQUEST'
export const SUBMIT_COMPANY_LEASE_DATA_SUCCESS = 'SUBMIT_COMPANY_LEASE_DATA_SUCCESS'
export const SUBMIT_COMPANY_LEASE_DATA_FAIL = 'SUBMIT_COMPANY_LEASE_DATA_FAIL'

export const FETCH_LEASE_ORDERS_REQUEST = 'FETCH_LEASE_ORDERS_REQUEST'
export const FETCH_LEASE_ORDERS_SUCCESS = 'FETCH_LEASE_ORDERS_SUCCESS'
export const FETCH_LEASE_ORDERS_FAIL = 'FETCH_LEASE_ORDERS_FAIL'

export const PAY_LEASE_DEPOSIT_REQUEST = 'PAY_LEASE_DEPOSIT_REQUEST'
export const PAY_LEASE_DEPOSIT_SUCCESS = 'PAY_LEASE_DEPOSIT_SUCCESS'
export const PAY_LEASE_DEPOSIT_FAIL = 'PAY_LEASE_DEPOSIT_FAIL'

export const GET_LEASE_HISTORY_REQUEST = 'GET_LEASE_HISTORY_REQUEST'
export const GET_LEASE_HISTORY_SUCCESS = 'GET_LEASE_HISTORY_SUCCESS'
export const GET_LEASE_HISTORY_FAIL = 'GET_LEASE_HISTORY_FAIL'

export const SAVE_PRODUCT_ATTRIBUTE_REQUEST = 'SAVE_PRODUCT_ATTRIBUTE_REQUEST'
export const SAVE_PRODUCT_ATTRIBUTE_SUCCESS = 'SAVE_PRODUCT_ATTRIBUTE_SUCCESS'
export const SAVE_PRODUCT_ATTRIBUTE_FAIL = 'SAVE_PRODUCT_ATTRIBUTE_FAIL'

export const EDIT_PRODUCT_ATTRIBUTE_REQUEST = 'SAVE_PRODUCT_ATTRIBUTE_REQUEST'
export const EDIT_PRODUCT_ATTRIBUTE_SUCCESS = 'SAVE_PRODUCT_ATTRIBUTE_SUCCESS'
export const EDIT_PRODUCT_ATTRIBUTE_FAIL = 'SAVE_PRODUCT_ATTRIBUTE_FAIL'

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'
