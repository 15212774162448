import React from "react";
import { Container } from "react-bootstrap";
import Header from "../Components/Reusable/Header";
import Footer from "../Components/Reusable/Footer";
import ProductShowcase from "../Components/Reusable/ProductShowcase";
import ProductInfo from "../Components/Products/ProductInfo";

function ProductDetails() {
  return (
    <>
      <div className="bg-light-grey w-100">
        <Container className="py-3" fluid="md">
          <Header />
          <ProductInfo />
        </Container>
        <ProductShowcase heading="You might also like" tagID={1} />
        <Footer />
      </div>
    </>
  );
}

export default ProductDetails;
