import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Row, Col, Modal, Button, Nav } from "react-bootstrap";
import {
  getCartItems,
  deleteAllCartItems,
  deleteCartItem,
  updateCartItem,
} from "../../../redux/actions/cartActions";
import { getPaymentChannel } from "../../../redux/actions/productActions";
import Loader from "../../Reusable/Loader";
// import Message from "../../Reusable/Message";
import { Link } from "react-router-dom";

const Cart = ({ onSelect }) => {
  const [isShow, invokeModal] = useState(false);
  const [getId, setGetid] = useState("");
  const [getQty, setGetQty] = useState(0);
  const [getTotalQty, setGetTotalQty] = useState();
  const [loading, setLoading] = useState(false);
  const [emptycartloading, setemptycartLoading] = useState(false);
  const [emptyCart, setEmptyCart] = useState(false);
  const dispatch = useDispatch();

  // const openModal = () => {
  //   return invokeModal(true);
  // };
  const closeModal = () => {
    return invokeModal(false);
  };

  //FETCH ALL ITEMS IN CART
  const cart = useSelector((state) => state.cart);
  const {
    cartItems,
    cartLoading,
    totalAmount,
    trackingCode,
    clearedCart,
    deletedItem,
    updateCartData,
  } = cart;

  //FETCH All ITEMS ACTION
  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch, clearedCart, deletedItem, updateCartData]);

  //CLEAR ALL ITEMS FROM CART ACTION
  const tracking_code = trackingCode && trackingCode;
  const clearCart = (tracking_code) => {
    setemptycartLoading(true);
    dispatch(deleteAllCartItems(tracking_code));
  };

  //DELETE ITEM FROM CART
  const removeItemFromCart = (id) => {
    setLoading(true);
    dispatch(deleteCartItem(id));
  };

  // CLOSE CART PRE-EMPT MODAL
  useEffect(() => {
    if (deletedItem) {
      setLoading(false);
      setTimeout(() => {
        closeModal();
      }, 2000);
    }
  }, [deletedItem]);

  useEffect(() => {
    if (clearedCart) {
      setemptycartLoading(false);
      setTimeout(() => {
        closeModal();
      }, 2000);
    }
  }, [clearedCart]);

  const updateCart = (id, quantity, update) => {
    if (update === "decrease" && quantity !== 1) {
      dispatch(updateCartItem(id, quantity - 1));
    } else if (update === "increase") {
      dispatch(updateCartItem(id, quantity + 1));
    }
  };

  useEffect(() => {
    dispatch(getPaymentChannel());
  }, [dispatch]);

  const [key, setKey] = useState("/payment/instant");

  return (
    <>
      <div>
        <Modal show={isShow}>
          <Modal.Header closeButton onClick={closeModal}>
            <Modal.Title>
              {emptyCart ? "Clear Cart Item(s)" : "Delete Cart Item"}
            </Modal.Title>
          </Modal.Header>
          {deletedItem ? (
            <p className="text-success p-5 text-center success-text text-capitalize ">
              Item deleted successfully
            </p>
          ) : (
            <>
              <Modal.Body className="p-5">
                <p className="text-red text-center font-weight-bold">
                  {emptyCart
                    ? "Are you sure you want to clear this cart?"
                    : "Are you sure you want to delete this item?"}
                </p>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-around my-3">
                <Button
                  type="submit"
                  onClick={closeModal}
                  variant="danger"
                  className="cancel-clear"
                >
                  Cancel
                </Button>
                {emptyCart ? (
                  <Button
                    type="submit"
                    onClick={() => clearCart(tracking_code)}
                    variant="success"
                    className="clear-cart"
                  >
                    {emptycartloading ? <Loader /> : " Yes, clear cart"}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    onClick={() => removeItemFromCart(getId)}
                    variant="success"
                    className="clear-cart"
                  >
                    {loading ? <Loader /> : " Yes, delete"}
                  </Button>
                )}
              </Modal.Footer>
            </>
          )}
        </Modal>
        <div className="d-flex w-100 justify-content-between flex-wrap">
          <Nav className="mb-3" onSelect={(k) => setKey(k)} activeKey={key}>
            <Nav.Item>
              <Nav.Link className="text-bold" eventKey="/payment/instant">
                Outright Purchase
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className=" mx-auto text-bold">
            Tracking Code : {tracking_code}
          </div>
          <button
            // onClick={() => clearCart(tracking_code)}
            onClick={() => {
              invokeModal(true);
              setEmptyCart(true);
            }}
            className="ml-auto border-0 empty-btn block text-white px-4 py-2 mb-2"
          >
            {clearedCart && !clearedCart.length ? <Loader /> : "Clear cart"}
          </button>
        </div>
        <div className="d-flex w-100 justify-content-between flex-wrap">
          <div className="d-flex">
            {/* <h6 className='border-0 bg-blue block text-white px-3 py-2 mr-2 mb-2'> SubTotal: ({ cartItems.reduce((total, product) => total + product.quantity, 0)}) Items</h6>
                        <button onClick={() => deleteCartItems(cartItems[0].tracking_code)}  className='border-0 bg-red block text-white px-4 py-2 mb-2'>Clear cart</button> */}
          </div>
        </div>
        {cartLoading ? (
          <Loader />
        ) : cartItems && cartItems?.length === 0 ? (
          <div className="text-center mt-5 font-weight-bold">
            {" "}
            There are no item(s) in your cart to display
          </div>
        ) : cartItems && typeof cartItems !== "number" ? (
          cartItems.map((item) => {
            return (
              <Row
                key={item.product_id}
                className="bg-white border-bottom m-1 my-2 p-3 align-items-center"
              >
                <Col xs={12} md={3} className="p-3">
                  <div className="mw-400">
                    <img
                      src={item.banner}
                      alt="product"
                      className="w-100 block m-auto"
                    />
                  </div>
                </Col>
                <Col xs={12} md={3}>
                  <span>{item.product.name}</span>
                  <p>
                    <strong>Status:</strong>{" "}
                    {item.attribute && item.attribute.condition}
                  </p>
                  <p>
                    <strong>Quantity in stock:</strong> {item.product.quantity}
                  </p>
                </Col>
                <Col
                  xs={12}
                  md={3}
                  lg={2}
                  className="flex-on-mobile my-2 quantity"
                >
                  <h6 className="">Quantity: {item.quantity} </h6>
                  <div className="text-dark-grey p-0 ml-2">
                    <Badge
                      onClick={() => {
                        updateCart(item.id, item.quantity, "decrease");
                        setGetQty(item.quantity - 1);
                        setGetTotalQty(item.product.quantity);
                      }}
                      bg="light"
                      className="bg-grey cursor-pointer font-size-sm mx-2"
                    >
                      &#8722;
                    </Badge>
                    {item.quantity}
                    <Badge
                      onClick={() => {
                        updateCart(item.id, item.quantity, "increase");
                        setGetQty(item.quantity + 1);
                        setGetTotalQty(item.product.quantity);
                      }}
                      bg="light"
                      className="bg-grey cursor-pointer font-size-sm mx-2"
                    >
                      &#43;
                    </Badge>
                  </div>
                </Col>
                <Col xs={12} md={2} className="my-2 text-blue">
                  <h5>
                    {(item.product.amount * item.quantity).toLocaleString("en")}
                  </h5>
                </Col>
                <Col
                  style={{ cursor: "pointer" }}
                  xs={12}
                  md={2}
                  className="my-2"
                >
                  <span
                    onClick={() => {
                      invokeModal(true);
                      setGetid(item.id);
                      setEmptyCart(false);
                    }}
                    className="material-icons text-red"
                  >
                    delete
                  </span>
                </Col>
                <div className="d-flex justify-content-end w-100">
                  <Link
                    to={`/product/${item.product_id}`}
                    className="text-decoration-none"
                  >
                    view item
                  </Link>
                </div>
                {/* <div className="d-flex justify-content-end w-100">
                    <h6 className="mx-2">
                      tracking code: {item.tracking_code}
                    </h6>
                  </div> */}
              </Row>
            );
          })
        ) : null}
        <div className="d-flex flex-column align-items-end w-100 my-4">
          <span className="text-bold">Total:</span>
          <h4 className="text-blue my-3">
            &#8358;{totalAmount && totalAmount.toLocaleString("en")}
          </h4>
          <button
            onClick={onSelect}
            disabled={getQty > getTotalQty}
            className={`${
              getQty > getTotalQty
                ? "disabled-btn border-0 px-4 py-2"
                : "border-0 px-4 py-2"
            }`}
            // className="border-0 px-4 py-2"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Cart;
