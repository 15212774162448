import React from 'react'

function Investments() {
    return (
        <>
            <div className="mt-4">
                <p>We work with our customers supporting them in making investments and wise business decisions with the aim to maximize returns.</p>
                <p>We encourage our customers to finance their assets with the aim to conserve capital that can be put to other investments. We also work with the customers supporting them in making the investments. This we do by providing investment advice and proposing various products for actual investment. Our knowledge and relationships in this area is of immense value to our customers.</p>
                <p>We welcome investments in our portfolio and we offer high yield returns  on all secured investments.</p>
            </div>
        </>
    )
}

export default Investments
