import React, { useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import OnBoardNavbar from "../Reusable/OnBoardNavbar";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Reusable/Loader";
import Message from "../Reusable/Message";
import { register } from "../../redux/actions/authActions";

const RegisterUser = ({ history }) => {
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
  });
  const [togglePasswordVisibility, settogglePasswordVisibility] =
    useState("password");

  const handlePasswordToggle = () => {
    togglePasswordVisibility === "password"
      ? settogglePasswordVisibility("text")
      : settogglePasswordVisibility("password");
  };

  const { first_name, last_name, email, phone, password } = user;

  const location = useLocation();

  const findFormErrors = () => {
    const newErrors = {};
    //eslint-disable-next-line
    const regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regPhone = /^([0]{1})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7,8})$/g;

    // name errors
    if (!first_name || first_name === "")
      newErrors.first_name = "first name cannot be empty!";
    else if (first_name.length > 15) newErrors.first_name = "name is too long!";
    if (!last_name || last_name === "")
      newErrors.last_name = "Last Name cannot be empty!";
    else if (last_name.length > 15) newErrors.last_name = "name is too long!";

    // email errors
    if (!regEmail.test(email) || email === "")
      newErrors.email = "Email field is invalid!";

    // phone error
    if (phone === "") newErrors.phone = "Phone number field cannot be empty";
    if (!regPhone.test(phone))
      newErrors.phone = "Phone number field cannot be less than 11!";
    // password errors
    if (!password || password === "")
      newErrors.password = "Password field cannot be empty!";
    else if (password.length < 7)
      newErrors.password = "Password cannot be less than 7 characters!";

    return newErrors;
  };

  const getFormValues = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });

    // Check and see if errors exist and remove them from the error object:
    if (!!errors[e.target.name])
      setErrors({
        ...errors,
        [e.target.name]: null,
      });
  };

  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);

  const { loading, error, userInfo } = userRegister;

  const submitRegister = (e) => {
    e.preventDefault();

    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      // DISPATCH REGISTER ACTION
      dispatch(register(first_name, last_name, email, phone, password));
    }
  };

  if (userInfo && userInfo.data) {
    return (
      <Redirect
        to={{
          pathname: "/verify",
          state: {
            email: userInfo.data.email,
            url: location.state && location.state.url,
          },
        }}
      />
    );
  }

  return (
    <>
      <OnBoardNavbar navlink={"Login"} />
      <div className="mt-5">
        <h1 className="text-center text-responsive">
          Create a customer account
        </h1>
        <p className="text-center">Kindly enter your personal details</p>
      </div>

      {/* {message && <Message variant='danger'>{message}</Message>} */}
      {error && <Message variant="danger">{error}</Message>}

      <Form onSubmit={submitRegister} className="mt-5 mw-500 mx-auto">
        <Form.Group className="my-4">
          <Form.Label htmlFor="firstname">First Name</Form.Label>
          <Form.Control
            onChange={getFormValues}
            isInvalid={!!errors.first_name}
            size="lg"
            className="bg-light border-0"
            type="text"
            placeholder="Enter firstname"
            name="first_name"
            id="first_name"
          />
          <Form.Control.Feedback type="invalid">
            {errors.first_name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Label htmlFor="lastname">Last Name</Form.Label>
          <Form.Control
            onChange={getFormValues}
            isInvalid={!!errors.last_name}
            size="lg"
            className="bg-light border-0"
            type="text"
            placeholder="Enter lastname"
            name="last_name"
            id="last_name"
          />
          <Form.Control.Feedback type="invalid">
            {errors.last_name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Label htmlFor="phone">Phone Number</Form.Label>
          <Form.Control
            onChange={getFormValues}
            isInvalid={!!errors.phone}
            size="lg"
            className="bg-light border-0"
            type="tel"
            placeholder="09076..."
            name="phone"
            id="phone"
          />
          <Form.Control.Feedback type="invalid">
            {errors.phone}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Label htmlFor="email">Email Address</Form.Label>
          <Form.Control
            onChange={getFormValues}
            isInvalid={!!errors.email}
            className="bg-light border-0"
            type="email"
            placeholder="Enter email"
            name="email"
            id="email"
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Label htmlFor="password">Password</Form.Label>
          <div className="position-relative">
            <Form.Control
              onChange={getFormValues}
              isInvalid={!!errors.password}
              size="lg"
              className="bg-light border-0 "
              type={togglePasswordVisibility}
              name="password"
              placeholder="Enter password"
              id="password"
            />
            <i
              onClick={handlePasswordToggle}
              className="material-icons position-absolute cursor-pointer"
              style={{ right: 10, top: "10px" }}
            >
              {togglePasswordVisibility === "password"
                ? "visibility_off"
                : "visibility"}
            </i>
          </div>
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="my-4">
          <Form.Text>
            <b>Password should be:</b>
          </Form.Text>
          <Form.Text>At least 6 characters long</Form.Text>
          <Form.Text>A mixture of Upper and Lowercase letters</Form.Text>
        </div>
        <Button type="submit" block className="bg-blue border-0">
          {loading ? <Loader /> : "Create Account"}
        </Button>
        <p className="text-center mt-3">
          Already have an account?
          <Link to="/login" className="text-blue text-decoration-none">
            {" "}
            Login{" "}
          </Link>
        </p>
      </Form>
    </>
  );
};

export default RegisterUser;
