import React from 'react'
import { Switch, Route } from 'react-router-dom'
import RegisterUser from './RegisterUser'
import RegisterVendor from './RegisterVendor'

function Register({ match }) {
    return (
        <div>
            <Switch>
                <Route exact path="/register">
                    <RegisterUser/>
                </Route>
                <Route path={match.url + "/vendor"}>
                    <RegisterVendor/>
                </Route>
            </Switch>
        </div>
    )
}

export default Register
