import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { Redirect } from 'react-router-dom'
import { getProductsByTags } from "../../redux/actions/productActions";
import Loader from "./Loader";
import Product from "./Product";

const ProductShowcase = ({ heading, tagID }) => {
  const dispatch = useDispatch();

  const getProducts = useSelector((state) => state.getProductsByTag);
  const { productsbytags, loading } = getProducts;

  useEffect(() => {
    if (!productsbytags?.length) dispatch(getProductsByTags(tagID));
  }, [dispatch, tagID]);

  return (
    <>
      <div className="bg-light-grey">
        <Container className="py-3 pt-4" fluid="md">
          <div className="d-flex justify-content-between ">
            <h3 className="underline-heading">{heading}</h3>
          </div>
          {loading ? (
            <Loader />
          ) : productsbytags ? (
            <Row className="mb-2 justify-content-md-center">
              {productsbytags &&
                productsbytags.data &&
                productsbytags.data.map((product, i) => {
                  return (
                    <Product
                      key={product.id}
                      name={product.name}
                      price={product.amount}
                      like={"favorite_border"}
                      id={product.id}
                      productInfo={productsbytags.data}
                      productImage={product.images[0]}
                    />
                  );
                })}
            </Row>
          ) : (
            "No Trending Products"
          )}
        </Container>
      </div>
    </>
  );
};

export default ProductShowcase;
