import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import OnBoardNavbar from "../Reusable/OnBoardNavbar";
import Loader from "../Reusable/Loader";
import Message from "../Reusable/Message";
import { createVendor } from "../../redux/actions/authActions";

const RegisterVendor = ({ history }) => {
  const dispatch = useDispatch();

  const newVendor = useSelector((state) => state.newVendor);
  const { loading, vendor, error } = newVendor;

  const [errors, setErrors] = useState({});

  const [cVendor, setCVendor] = useState({
    name: "",
    store_name: "",
    address: "",
    banner: "",
  });

  const { name, store_name, address, banner } = cVendor;

  const findFormErrors = () => {
    const newErrors = {};
    if (!name || name === "") newErrors.name = "Name cannot be empty!";

    if (!store_name || store_name === "")
      newErrors.store_name = "Store Name cannot be empty!";

    if (!address || address === "")
      newErrors.address = "Address field cannot be empty!";

    if (!banner || banner === "")
      newErrors.banner = "Please Upload company image!";

    return newErrors;
  };

  const getFormValues = (e) => {
    if (e.target.name === "banner") {
      setCVendor({ ...cVendor, [e.target.name]: e.target.files[0] });
    } else {
      setCVendor({ ...cVendor, [e.target.name]: e.target.value });
    }

    // Check and see if errors exist and remove them from the error object:
    if (!!errors[e.target.name])
      setErrors({
        ...errors,
        [e.target.name]: null,
      });
  };

  const submitVendorForm = (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      dispatch(createVendor({ name, store_name, address, banner }));
    }
  };

  if (vendor && vendor.data) {
    return (
      <Redirect
        to={{
          pathname: "/dashboard",
        }}
      />
    );
  }

  return (
    <>
      <OnBoardNavbar navlink={"Login"} />
      <div className="mt-5">
        <h1 className="text-center text-responsive">Create a vendor account</h1>
        <p className="text-center">You are creating an account as a vendor</p>
      </div>
      {error && <Message variant="danger">{error}</Message>}

      <Form onSubmit={submitVendorForm} className="mt-5 mw-500 mx-auto">
        <Form.Group className="my-4">
          <Form.Label htmlFor="firstname">Full Name</Form.Label>
          <Form.Control
            onChange={getFormValues}
            isInvalid={!!errors.name}
            size="lg"
            className="bg-light border-0"
            type="text"
            value={name}
            name="name"
            id="name"
          />
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Label htmlFor="vendor-store">Store Name</Form.Label>
          <Form.Control
            onChange={getFormValues}
            isInvalid={!!errors.store_name}
            size="lg"
            className="bg-light border-0"
            value={store_name}
            type="text"
            name="store_name"
            id="store_name"
          />
          <Form.Control.Feedback type="invalid">
            {errors.store_name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Label htmlFor="vendor-address">Business Address</Form.Label>
          <Form.Control
            onChange={getFormValues}
            isInvalid={!!errors.address}
            size="lg"
            className="bg-light border-0"
            value={address}
            type="text"
            name="address"
            id="address"
          />
          <Form.Control.Feedback type="invalid">
            {errors.address}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="my-4">
          <Form.Label htmlFor="vendor-logo">Business Logo</Form.Label>
          <Form.Control
            onChange={getFormValues}
            custom
            isInvalid={!!errors.banner}
            type="file"
            name="banner"
            defaultValue={banner}
            id="banner"
            accept="image/*"
          />
          <Form.Control.Feedback type="invalid">
            {errors.banner}
          </Form.Control.Feedback>
          <Form.Text className="text-muted">
            You can upload a placeholder image until your logo is available
          </Form.Text>
        </Form.Group>

        <Button type="submit" block className="bg-blue border-0 mt-4">
          {loading ? <Loader /> : "Create Account"}
        </Button>
        <p className="text-center mt-3">
          Already have a vendor account?
          <Link to="/dashboard" className="text-blue text-decoration-none">
            {" "}
            Go to dashboard{" "}
          </Link>
        </p>
      </Form>
    </>
  );
};

export default RegisterVendor;
