import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loader = ({ extraStyle }) => {

    const styles = {
        padding: `${extraStyle?.padding}`,
    }
    return (
        <div style={styles}>
            <Spinner animation='border' className={`m-auto block `} role='status' style={{
                width: '20px',
                height: '20px'
            }}>
                <span className='sr-only'>Loading...</span>
            </Spinner>
        </div>
    )
}

export default Loader
