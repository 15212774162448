import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Components/Reusable/Footer";
import Header from "../Components/Reusable/Header";
import HeaderCarousel from "../Components/Reusable/HeaderCarousel";
import { Link } from "react-router-dom";

function TermsAndConditions() {
  return (
    <>
      <div className="bg-light-grey w-100">
        <Container className="py-3 mb-5" fluid="md">
          <Header />
          <HeaderCarousel />
        </Container>
        <div className="bg-white">
          <Container>
            <Row className=" py-5">
              <h3 className="mx-auto text-bold mb-4">
                General Terms and Conditions of Use of the Leasehouz Marketplace
                for Buyers and Sellers
              </h3>
              <div className="mb-4">
                <h4 className="text-bold">1. Introduction</h4>
                <p>
                  <span className="text-bold mr-2">1.1.</span> “Leasehouz”
                  doubles as the trade name and online marketplace for Assetkap
                  Investment Limited.
                </p>
                <p>
                  <span className="text-bold mr-2">1.2.</span> These general
                  terms and conditions shall apply to buyers and sellers on the
                  marketplace and shall govern your use of the marketplace and
                  related services.
                </p>
                <p>
                  <span className="text-bold mr-2">1.3.</span> By using our
                  marketplace whether by outright purchase or purchases
                  concluded via financing, you accept these general terms and
                  conditions in full. If you disagree with these general terms
                  and conditions or any part of these general terms and
                  conditions you must not use our marketplace.
                </p>
                <p>
                  <span className="text-bold mr-2">1.4.</span> If you use our
                  marketplace in the course of a business or other
                  organizational project then by so doing you:
                </p>
                <p>
                  <span className="text-bold mr-2">1.4.1.</span> Confirm that
                  you have obtained the necessary authority to agree to these
                  general terms and conditions;
                </p>
                <p>
                  <span className="text-bold mr-2">1.4.2.</span> bind both
                  yourself and the person company or other legal entity that
                  operates that business or organizational project to these
                  general terms and conditions; and
                </p>
                <p>
                  <span className="text-bold mr-2">1.4.3.</span> agree that you
                  in these general terms and conditions shall reference both the
                  individual user and the relevant person company or legal
                  entity unless the context requires otherwise.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">2. Registration and account</h4>
                <p>
                  <span className="text-bold mr-2">2.1.</span> You may not
                  register with our marketplace if you are under 18 years of age
                  (by using our marketplace or agreeing to these general terms
                  and conditions you warrant and represent to us that you are at
                  least 18 years of age).
                </p>
                <p>
                  <span className="text-bold mr-2">2.2.</span> If you register
                  for an account with our marketplace you will be asked to
                  provide an email address/user ID and password and you agree
                  to:
                </p>
                <p>
                  <span className="text-bold mr-2">2.2.1.</span> Keep your
                  password confidential.
                </p>
                <p>
                  <span className="text-bold mr-2">2.2.2.</span> Notify us in
                  writing immediately (using our contact details provided in
                  section 26) if you become aware of any disclosure of your
                  password; and
                </p>
                <p>
                  <span className="text-bold mr-2">2.2.3.</span> Be responsible
                  for any activity on our marketplace arising out of any failure
                  to keep your password confidential and you may be held liable
                  for any losses arising out of such a failure.
                </p>
                <p>
                  <span className="text-bold mr-2">2.2.4.</span> Your account
                  shall be used exclusively by you and you shall not transfer
                  your account to any third party. If you authorize any third
                  party to manage your account on your behalf this shall be at
                  your own risk.
                </p>
                <p>
                  <span className="text-bold mr-2">2.2.5.</span> We may suspend
                  or cancel your account and/or edit your account details at any
                  time in our sole discretion and without notice or explanation
                  providing that if we cancel any products or services you have
                  paid for but not received and you have not breached these
                  general terms and conditions we will refund you in respect of
                  the same.
                </p>
                <p>
                  You may cancel your account on our marketplace by contacting
                  us.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">3. Terms and conditions of sale</h4>
                <p>
                  <span className="text-bold mr-2">3.1.</span> You acknowledge
                  and agree that:
                </p>
                <p>
                  <span className="text-bold mr-2">3.1.1.</span> The marketplace
                  provides an online location for sellers to sell and buyers to
                  purchase products;
                </p>
                <p>
                  <span className="text-bold mr-2">3.1.2.</span> We are
                  displaying products of selected vendors for sale via outright
                  purchase or financing provided by us. Leasehouz will only
                  facilitate the process between the buyer and the seller. In
                  addition to these terms and conditions, all sales concluded
                  through the financing option shall conform to the accompanying
                  lease agreements.
                </p>
                <p>
                  <span className="text-bold mr-2">3.1.3.</span> The contract
                  for the sale and purchase of a product or products shall be
                  between the buyer and seller and accordingly you commit to
                  buying the relevant product or products upon the buyer’s
                  confirmation of purchase via the marketplace.
                </p>
                <p>
                  <span className="text-bold mr-2">3.2.</span> Subject to these
                  general terms and conditions the seller’s terms of business
                  shall govern the contract for sale and purchase between the
                  buyer and the seller. Notwithstanding this, the following
                  provisions will be incorporated into the contract of sale and
                  purchase between the buyer and the seller:
                </p>
                <p>
                  <span className="text-bold mr-2">3.2.1.</span> The price for a
                  product will be as stated in the relevant product listing
                  however, the seller is at liberty to offer a discount to the
                  buyer;
                </p>
                <p>
                  <span className="text-bold mr-2">3.2.2.</span> The price for
                  the product must include all taxes and comply with applicable
                  laws in force from time to time;
                </p>
                <p>
                  <span className="text-bold mr-2">3.2.3.</span> delivery
                  charges packaging charges handling charges administrative
                  charges insurance costs other ancillary costs and charges
                  where applicable will only be payable by the buyer if this is
                  expressly stated in the product listing, and delivery of
                  digital products may be made electronically;
                </p>
                <p>
                  <span className="text-bold mr-2">3.2.4.</span> products must
                  be of satisfactory quality fit and safe for any purpose
                  specified in and conform in all material respects to the
                  product listing and any other description of the products
                  supplied or made available by the seller to the buyer; and
                </p>
                <p>
                  <span className="text-bold mr-2">3.2.5.</span> In respect of
                  physical products sold the seller warrants that the seller has
                  good title to and is the sole legal and beneficial owner of
                  the products and/or has the right to supply the products
                  pursuant to this agreement and that the products are not
                  subject to any third party rights or restrictions including in
                  respect of third party intellectual property rights and/or any
                  criminal insolvency or tax investigation or proceedings; and
                  in respect of digital products the seller warrants that the
                  seller has the right to supply the digital products to the
                  buyer.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">4. Returns and refunds</h4>
                <p>
                  <span className="text-bold mr-2">4.1.</span> Buyers agree that
                  the selection and acceptance of products or assets on the
                  platform is their sole responsibility. Buyers agree that by
                  purchasing on the marketplace, they have selected, inspected
                  (where applicable), and accepted the products or assets.
                  Leashouz makes no promise regarding the condition or
                  suitability of the products.
                </p>
                <p>
                  <span className="text-bold mr-2">4.2.</span>
                  All warranty and maintenance-related matters on the products
                  and assets shall be between the Sellers and the Buyers. Where
                  there is a dispute between the Buyer and the Supplier,
                  Leasehouz may at best, attempt to resolve the dispute provided
                  the same is reported to Leasehouz. However, Leasehouz shall
                  not be held responsible or liable for such disputes
                </p>
                <p>
                  <span className="text-bold mr-2">4.3.</span>
                  Refunds and returns of products by buyers and acceptance of
                  returned products by sellers shall be between the sellers and
                  the buyers. Provided Leashouz has been notified, we shall at
                  best attempt to resolve the matter.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">5. Promotions</h4>
                <p>
                  <span className="text-bold mr-2">5.1.</span>
                  Promotions and competitions run by Leasehouz and/or other
                  promoters shall be managed in accordance with the Promotions
                  Terms and Conditions.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">
                  6. Rules about your content as a Seller
                </h4>
                <p>
                  <span className="text-bold mr-2">6.1.</span>
                  In these general terms and conditions your content means:
                </p>
                <p>
                  <span className="text-bold mr-2">6.1.1</span>
                  All works and materials (including without limitation text
                  graphics images, audio material, video material, audio-visual
                  material, scripts, software and files) that you submit to us
                  or our marketplace for storage or publication processing by or
                  onward transmission; and
                </p>
                <p>
                  <span className="text-bold mr-2">6.1.2</span>
                  All communications on the marketplace including product
                  reviews, feedback, and comments.
                </p>
                <p>
                  <span className="text-bold mr-2">6.2.</span>
                  Your content and the use of your content by us by these
                  general terms and conditions must be accurate, complete, and
                  truthful.
                </p>
                <p>
                  <span className="text-bold mr-2">6.3.</span>
                  Your content must be appropriate, civil, and tasteful and
                  accord with generally accepted standards of etiquette and
                  behavior on the internet and must not:
                </p>
                <p>
                  <span className="text-bold mr-2">6.3.1</span>
                  Your content must not be offensive, obscene, indecent,
                  pornographic, lewd, suggestive, or sexually explicit;
                </p>
                <p>
                  <span className="text-bold mr-2">6.3.2</span>
                  Your content must not depict violence in an explicit graphic
                  or gratuitous manner; or
                </p>
                <p>
                  <span className="text-bold mr-2">6.3.3</span>
                  Your content must not be blasphemous in breach of racial or
                  religious hatred or discrimination legislation;
                </p>
                <p>
                  <span className="text-bold mr-2">6.3.4</span>
                  Your content must not be deceptive, fraudulent, threatening,
                  abusive, harassing, anti-social, menacing, hateful,
                  discriminatory or inflammatory;
                </p>
                <p>
                  <span className="text-bold mr-2">6.3.5</span>
                  Your content must not cause annoyance, inconvenience, or
                  needless anxiety to any person; or
                </p>
                <p>
                  <span className="text-bold mr-2">6.3.6</span>
                  Your content must not constitute spam.
                </p>
                <p>
                  <span className="text-bold mr-2">6.4.</span>
                  Your content must not be illegal or unlawful, infringe any
                  person's legal rights, or be capable of giving rise to legal
                  action against any person (in each case in any jurisdiction
                  and under any applicable law). Your content must not infringe
                  or breach:
                </p>
                <p>
                  <span className="text-bold mr-2">6.4.1</span>
                  any copyright moral right, database right, trademark right,
                  design right, in passing off, or other intellectual property
                  rights.
                </p>
                <p>
                  <span className="text-bold mr-2">6.4.2</span>
                  any right of confidence, right of privacy, or right under data
                  protection legislation;
                </p>
                <p>
                  <span className="text-bold mr-2">6.4.3</span>
                  any contractual obligation owed to any person; or any court
                  orders.
                </p>
                <p>
                  <span className="text-bold mr-2">6.5.</span>
                  You must not use our marketplace to link to any website or web
                  page consisting of or containing material that would, if
                  posted on our marketplace, breach the provisions of these
                  general terms and conditions.
                </p>
                <p>
                  <span className="text-bold mr-2">6.6.</span>
                  You must not submit to our marketplace any material that is or
                  has ever been the subject of any threatened or actual legal
                  proceedings or other similar complaint.
                </p>
                <p>
                  <span className="text-bold mr-2">6.7.</span>
                  The review function on the marketplace may be used to
                  facilitate buyer reviews on products. You shall not use the
                  review function or any other form of communication to provide
                  inaccurate inauthentic or fake reviews.
                </p>
                <p>
                  <span className="text-bold mr-2">6.8.</span>
                  You must not interfere with a transaction by
                </p>
                <p>
                  <span className="text-bold mr-2">6.8.1</span>
                  contacting another user to buy or sell an item listed on the
                  marketplace outside of the marketplace; or
                </p>
                <p>
                  <span className="text-bold mr-2">6.8.2</span>
                  communicating with a user involved in an active or completed
                  transaction to warn them away from a particular buyer seller
                  or item; or
                </p>
                <p>
                  <span className="text-bold mr-2">6.8.3</span>
                  contacting another user with the intent to collect any
                  payments
                </p>
                <p>
                  <span className="text-bold mr-2">6.9.</span>
                  You acknowledge that all users of the marketplace are solely
                  responsible for interactions with other users and you shall
                  exercise caution and good judgment in your communication with
                  users. You shall not send them personal information including
                  credit card details.
                </p>
                <p>
                  <span className="text-bold mr-2">6.10.</span>. We may
                  periodically review your content and we reserve the right to
                  remove any content at our discretion for any reason
                  whatsoever.
                </p>
                <p>
                  <span className="text-bold mr-2">6.11.</span>
                  If you learn of any unlawful material or activity on our
                  marketplace or any material or activity that breaches these
                  general terms and conditions you may inform us by contacting
                  us.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">7. Our rights to use your content</h4>
                <p>
                  <span className="text-bold mr-2">7.1.</span>
                  You grant to us a worldwide irrevocable non-exclusive
                  royalty-free license to use reproduce store adapt publish
                  translate and distribute your content on our marketplace and
                  across our marketing channels and any existing or future
                  media.
                </p>
                <p>
                  <span className="text-bold mr-2">7.2.</span>
                  You grant to us the right to sub-license the rights licensed
                  under section 7.1
                </p>
                <p>
                  <span className="text-bold mr-2">7.3.</span>
                  You grant us the right to bring an action for infringement of
                  the rights licensed under section 7.1
                </p>
                <p>
                  <span className="text-bold mr-2">7.4.</span>
                  You hereby waive all your moral rights in your content to the
                  maximum extent permitted by applicable law, and you warrant
                  and represent that all other moral rights in your content have
                  been waived to the maximum extent permitted by applicable law.
                </p>
                <p>
                  <span className="text-bold mr-2">7.5.</span>
                  Without prejudice to our other rights under these general
                  terms and conditions if you breach our rules on content in any
                  way or if we reasonably suspect that you have breached our
                  rules on content we may delete unpublish or edit any or all of
                  your content.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">
                  8. Use of the website and mobile applications
                </h4>
                <p>
                  <span className="text-bold mr-2">8.1.</span>
                  In this section 8 words “marketplace” and website” shall be
                  used interchangeably to refer to the Leasehouz website and
                  mobile applications.
                </p>
                <p>
                  <span className="text-bold mr-2">8.2.</span>
                  You may:
                </p>
                <p>
                  <span className="text-bold mr-2">8.2.1</span>
                  view pages from our website in a web browser;
                </p>
                <p>
                  <span className="text-bold mr-2">8.2.2</span>
                  download pages from our website for caching in a web browser;
                </p>
                <p>
                  <span className="text-bold mr-2">8.2.3</span>
                  You grant to us a worldwide irrevocable non-exclusive
                  royalty-free license to use reproduce store adapt publish
                  translate and distribute your content on our marketplace and
                  across our marketing channels and any existing or future
                  media.
                </p>
                <p>
                  <span className="text-bold mr-2">8.2.3</span>
                  print pages from our website for your own personal and
                  non-commercial use providing that such printing is not
                  systematic or excessive;
                </p>
                <p>
                  <span className="text-bold mr-2">8.2.4</span>
                  stream audio and video files from our website using the media
                  player on our website; and
                </p>
                <p>
                  <span className="text-bold mr-2">8.2.5</span>
                  use our marketplace services using a web browser subject to
                  the other provisions of these general terms and conditions.
                </p>
                <p>
                  <span className="text-bold mr-2">8.3.</span>
                  Except as expressly permitted by section 9.2 or the other
                  provisions of these general terms and conditions you must not
                  download any material from our website or save any such
                  material to your computer.
                </p>
                <p>
                  <span className="text-bold mr-2">8.4.</span>
                  You may only use our website for your own personal and
                  business purposes in respect of selling or purchasing products
                  on the marketplace.
                </p>
                <p>
                  <span className="text-bold mr-2">8.5.</span>
                  Except as expressly permitted by these general terms and
                  conditions you must not edit or otherwise modify any material
                  on our website.
                </p>
                <p>
                  <span className="text-bold mr-2">8.6.</span>
                  Unless you own or control the relevant rights in the material
                  you must not:
                </p>
                <p>
                  <span className="text-bold mr-2">8.6.1</span>
                  Republish material from our website (including republication
                  on another website);
                </p>
                <p>
                  <span className="text-bold mr-2">8.6.2</span>
                  sell rent or sub-license material from our website;
                </p>
                <p>
                  <span className="text-bold mr-2">8.6.3</span>
                  show any material from our website in public;
                </p>
                <p>
                  <span className="text-bold mr-2">8.6.4</span>
                  exploit material from our website for a commercial purpose; or
                </p>
                <p>
                  <span className="text-bold mr-2">8.6.5</span>
                  redistribute material from our website.
                </p>
                <p>
                  <span className="text-bold mr-2">8.7.</span>
                  Notwithstanding section 8.6 you may forward links to products
                  on our website and redistribute our newsletter and promotional
                  materials in print and electronic form to any person.
                </p>
                <p>
                  <span className="text-bold mr-2">8.8.</span>
                  We reserve the right to suspend or restrict access to our
                  website to areas of our website and/or to functionality upon
                  our website. We may for example suspend access to the website
                  during server maintenance or when we update the website. You
                  must not circumvent or bypass or attempt to circumvent or
                  bypass any access restriction measures on the website.
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.</span>
                  You must not:
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.1</span>
                  use our website in any way or take any action that causes or
                  may cause damage to the website or impairment of the
                  performance availability accessibility integrity or security
                  of the website;
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.2</span>
                  use our website in any way that is unethical, unlawful,
                  illegal, fraudulent, or harmful or in connection with any
                  unlawful illegal fraudulent, or harmful purpose or activity;
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.3</span>
                  hack or otherwise tamper with our website;
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.4</span>
                  probe, scan, or test the vulnerability of our website without
                  our permission;
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.5</span>
                  circumvent any authentication or security systems or processes
                  on or relating to our website;
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.6</span>
                  use our website to copy store host transmit send use publish
                  or distribute any material that consists of (or is linked to)
                  any spyware, computer virus, Trojan horse worm keystroke
                  logger rootkit, or other malicious computer software;
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.7</span>
                  impose an unreasonably large load on our website resources
                  (including bandwidth storage capacity and processing
                  capacity);
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.8</span>
                  decrypt or decipher any communications sent by or to our
                  website without our permission;
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.9</span>
                  conduct any systematic or automated data collection activities
                  (including without limitation scraping data mining data
                  extraction and data harvesting) on or in relation to our
                  website without our express written consent;
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.10</span>
                  access or otherwise interact with our website using any robot
                  spider or other automated means except for search engine
                  indexing;
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.11</span>
                  use our website except using our public interfaces;
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.12</span>
                  violate the directives set out in the robots.txt file for our
                  website;
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.7</span>
                  use data collected from our website for any direct marketing
                  activity (including without limitation email marketing SMS
                  marketing telemarketing and direct mailing); or
                </p>
                <p>
                  <span className="text-bold mr-2">8.9.7</span>
                  do anything that interferes with the normal use of our
                  website.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold"> 9. Copyright and Trademarks</h4>
                <p>
                  <span className="text-bold mr-2">9.1.</span>
                  Subject to the express provisions of these general terms and
                  conditions:
                </p>
                <p>
                  <span className="text-bold mr-2">9.1.1</span>
                  We together with our licensors own and control all the
                  copyright and other intellectual property rights in our
                  website and the material on our website; and
                </p>
                <p>
                  <span className="text-bold mr-2">9.1.2</span>
                  All the copyright and other intellectual property rights on
                  our website and the material on our website are reserved.
                </p>
                <p>
                  <span className="text-bold mr-2">9.2.</span>
                  Leasehouz’s logos and our other registered and unregistered
                  trademarks are trademarks belonging to us; we do not permit
                  the use of these trademarks and such use may constitute an
                  infringement of our rights.
                </p>
                <p>
                  <span className="text-bold mr-2">9.3.</span>
                  The third-party registered and unregistered trademarks or
                  service marks on our website are the property of their
                  respective owners and we do not endorse and are not affiliated
                  with any of the holders of any such rights and as such we
                  cannot grant any license to exercise such rights.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold"> 10. Data Privacy</h4>
                <p>
                  <span className="text-bold mr-2">10.1.</span>
                  Buyers agree to the processing of their data by the terms of
                  Leasehouz’s Privacy and Cookie Notice
                </p>
                <p>
                  <span className="text-bold mr-2">10.2.</span>
                  Leasehouz shall process all personal data obtained through the
                  marketplace and related services by the terms of our Privacy
                  and Cookie Notice and Privacy Policy.
                </p>
                <p>
                  <span className="text-bold mr-2">10.3.</span>
                  Sellers shall be directly responsible to buyers for any misuse
                  of their data and Leasehouz shall bear no liability to buyers
                  in respect of any misuse by sellers of their data.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">
                  11. Due diligence and audit rights
                </h4>
                <p>
                  <span className="text-bold mr-2">11.1.</span>
                  We operate an anti-fraud and anti-money laundering compliance
                  program and reserve the right to perform due diligence checks
                  on all users of the marketplace.
                </p>
                <p>
                  <span className="text-bold mr-2">11.2.</span>
                  You agree to provide us with all such information
                  documentation and access to your business premises as we may
                  require:
                </p>
                <p>
                  <span className="text-bold mr-2">11.2.1</span>
                  To verify your adherence to and performance of your
                  obligations under these terms and conditions;
                </p>
                <p>
                  <span className="text-bold mr-2">11.2.2</span>
                  For disclosures under a valid order by a court or other
                  governmental body; or
                </p>
                <p>
                  <span className="text-bold mr-2">11.2.3</span>
                  as otherwise required by law or applicable regulation.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">
                  {" "}
                  12. Leasehouz’s role as a marketplace
                </h4>
                <p>
                  <span className="text-bold mr-2">12.1.</span>
                  You acknowledge that:
                </p>
                <p>
                  <span className="text-bold mr-2">12.1.1</span>
                  Leasehouz facilitates a marketplace for buyers and third-party
                  sellers;
                </p>
                <p>
                  <span className="text-bold mr-2">12.1.2</span>
                  The relevant seller of the product shall at all times remain
                  exclusively liable for the products they sell on the
                  marketplace; and
                </p>
                <p>
                  <span className="text-bold mr-2">12.1.3</span>
                  In the event that there is an issue arising from the purchase
                  of a product on the marketplace, the buyer should seek
                  recourse from the relevant seller of the product by following
                  the process set out.
                </p>
                <p>
                  <span className="text-bold mr-2">12.2.</span>
                  We commit to ensuring that the third-party sellers submit
                  information relating to their products on the marketplace that
                  is completely accurate and up to date and pursuant thereto:
                </p>
                <p>
                  <span className="text-bold mr-2">12.2.1</span>
                  The relevant seller warrants and represents the completeness
                  and accuracy of the information published on our marketplace
                  relating to their products;
                </p>
                <p>
                  <span className="text-bold mr-2">12.2.2</span>
                  The relevant seller warrants and represents that the material
                  on the marketplace is up to date; and
                </p>
                <p>
                  <span className="text-bold mr-2">12.2.3</span>
                  If a buyer has a complaint relating to the accuracy or
                  completeness of the product information received from a seller
                  the buyer can seek recourse from the relevant seller by
                  following the process set out in the Leasehouz's DISPUTE
                  RESOLUTION POLICY.
                </p>
                <p>
                  <span className="text-bold mr-2">12.3.</span>
                  We do not warrant or represent that the marketplace will
                  operate without fault; or that the marketplace or any service
                  on the marketplace will remain available during the occurrence
                  of events beyond Leasehouz’s control (force majeure events)
                  which include but are not limited to; flood drought earthquake
                  or other natural disasters; hacking viruses malware or other
                  malicious software attacks on the marketplace; terrorist
                  attacks civil war civil commotion or riots; war threat of or
                  preparation for war; epidemics or pandemics; or
                  extra-constitutional events or circumstances which materially
                  and adversely affect the political or macro-economic stability
                  of the territory as a whole.
                </p>
                <p>
                  <span className="text-bold mr-2">12.4.</span>
                  We reserve the right to discontinue or alter any or all of our
                  marketplace services and to stop publishing our marketplace at
                  any time in our sole discretion without notice or explanation,
                  and you will not be entitled to any compensation or other
                  payment upon the discontinuance or alteration of any
                  marketplace services or if we stop publishing the marketplace.
                  This is without prejudice to your rights in respect of any
                  unfulfilled orders or other existing liabilities of Leasehouz.
                </p>
                <p>
                  <span className="text-bold mr-2">12.5.</span>
                  If we discontinue or alter any or all of our marketplace in
                  circumstances not relating to force majeure we will provide
                  prior notice to the buyers and sellers of not less than
                  fifteen (15) days with clear guidance on the way forward for
                  the pending transactions or other existing liabilities of
                  Leasehouz.
                </p>
                <p>
                  <span className="text-bold mr-2">12.6.</span>
                  We do not guarantee any commercial results concerning the use
                  of the marketplace.
                </p>
                <h6 className="text-bold">
                  To the maximum extent permitted by applicable law and subject
                  to section 13.1 below we exclude all representations and
                  warranties relating to the subject matter of these general
                  terms and conditions of our marketplace and the use of our
                  marketplace.
                </h6>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">
                  13.Limitations and exclusions of liability
                </h4>
                <p>
                  <span className="text-bold mr-2">13.1.</span>
                  Nothing in these general terms and conditions will:
                </p>
                <p>
                  <span className="text-bold mr-2">13.1.1</span>
                  limit any liabilities in any way that is not permitted under
                  applicable law; or
                </p>
                <p>
                  <span className="text-bold mr-2">13.1.2</span>
                  exclude any liabilities or statutory rights that may not be
                  excluded under applicable law.
                </p>
                <p>
                  <span className="text-bold mr-2">13.2.</span>
                  The limitations and exclusions of liability set out in this
                  section 13 and elsewhere in these general terms and
                  conditions:
                </p>
                <p>
                  <span className="text-bold mr-2">13.2.1</span>
                  are subject to section 15.1; and
                </p>
                <p>
                  <span className="text-bold mr-2">13.2.2</span>
                  govern all liabilities arising under these general terms and
                  conditions or relating to the subject matter of these general
                  terms and conditions including liabilities arising in contract
                  in tort (including negligence) and for breach of statutory
                  duty except to the extent expressly provided otherwise in
                  these general terms and conditions.
                </p>
                <p>
                  <span className="text-bold mr-2">13.3.</span>
                  In respect of the services offered to you free of charge we
                  will not be liable to you for any loss or damage of any nature
                  whatsoever.
                </p>
                <p>
                  <span className="text-bold mr-2">13.4.</span>
                  Our aggregate liability to you in respect of any contract to
                  provide services to you under these general terms and
                  conditions shall not exceed the total amount paid and payable
                  to us under the contract. Each separate transaction on the
                  marketplace shall constitute a separate contract for this
                  section 13.
                </p>
                <p>
                  <span className="text-bold mr-2">13.5.</span>
                  Notwithstanding section 13.4 above we will not be liable to
                  you for any loss or damage of any nature including in respect
                  of:
                </p>
                <p>
                  <span className="text-bold mr-2">13.5.1</span>
                  any losses occasioned by any interruption or dysfunction to
                  the website;
                </p>
                <p>
                  <span className="text-bold mr-2">13.5.2</span>
                  any losses arising out of any event or events beyond our
                  reasonable control;
                </p>
                <p>
                  <span className="text-bold mr-2">13.5.3</span>
                  any business losses including (without limitation) loss of or
                  damage to profits income revenue use production anticipated
                  savings business contracts commercial opportunities or
                  goodwill;
                </p>
                <p>
                  <span className="text-bold mr-2">13.5.4</span>
                  any loss or corruption of any data database or software; or
                </p>
                <p>
                  <span className="text-bold mr-2">13.5.5</span>
                  any special indirect or consequential loss or damage.
                </p>
                <p>
                  <span className="text-bold mr-2">13.6.</span>
                  We accept that we have an interest in limiting the personal
                  liability of our officers and employees and having regard to
                  that interest you acknowledge that we are a limited liability
                  entity; you agree that you will not bring any claim personally
                  against our officers or employees in respect of any losses you
                  suffer in connection with the marketplace or these general
                  terms and conditions (this will not limit or exclude the
                  liability of the limited liability entity itself for the acts
                  and omissions of our officers and employees).
                </p>
                <p>
                  <span className="text-bold mr-2">13.7.</span>
                  Our marketplace includes hyperlinks to other websites owned
                  and operated by third parties; such hyperlinks are not
                  recommendations. We have no control over third-party websites
                  and their contents and we accept no responsibility for them or
                  for any loss or damage that may arise from your use of them.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">14. Indemnification</h4>
                <p>
                  <span className="text-bold mr-2">14.1.</span>
                  You hereby indemnify us and undertake to keep us indemnified
                  against:
                </p>
                <p>
                  <span className="text-bold mr-2">14.1.1</span>
                  any losses damages costs liabilities and expenses (including
                  without limitation legal expenses and any amounts paid by us
                  to any third party in settlement of a claim or dispute)
                  incurred or suffered by us and arising directly or indirectly
                  out of your use of our marketplace or any breach by you of any
                  provision of these general terms and conditions or the
                  Leasehouz codes policies or guidelines; and
                </p>
                <p>
                  <span className="text-bold mr-2">14.1.2</span>
                  any VAT liability or other tax liability that we may incur
                  about any sale supply or purchase made through our marketplace
                  where that liability arises out of your failure to withhold
                  declare or register to pay any VAT or other tax properly due
                  in any jurisdiction.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">
                  15. Breaches of these general terms and conditions
                </h4>
                <p>
                  <span className="text-bold mr-2">15.1.</span>
                  If we permit the registration of an account on our marketplace
                  it will remain open indefinitely subject to these general
                  terms and conditions.
                </p>
                <p>
                  <span className="text-bold mr-2">15.2.</span>
                  If you breach these general terms and conditions or if we
                  reasonably suspect that you have breached these general terms
                  and conditions or any Leasehouz codes policies or guidelines
                  in any way we may:
                </p>
                <p>
                  <span className="text-bold mr-2">15.2.1</span>
                  temporarily suspend your access to our marketplace;
                </p>
                <p>
                  <span className="text-bold mr-2">15.2.2</span>
                  permanently prohibit you from accessing our marketplace;
                </p>
                <p>
                  <span className="text-bold mr-2">15.2.3</span>
                  block computers using your IP address from accessing our
                  marketplace;
                </p>
                <p>
                  <span className="text-bold mr-2">15.2.4</span>
                  contact any or all of your internet service providers and
                  request that they block your access to our marketplace;
                </p>
                <p>
                  <span className="text-bold mr-2">15.2.5</span>
                  suspend or delete your account on our marketplace; and/or
                </p>
                <p>
                  <span className="text-bold mr-2">15.2.6</span>
                  commence legal action against you whether for breach of
                  contract or otherwise.
                </p>
                <p>
                  <span className="text-bold mr-2">15.3.</span>
                  Where we suspend, prohibit, or block your access to our
                  marketplace or a part of our marketplace you must not take any
                  action to circumvent such suspension prohibition or blocking
                  (including without limitation creating and/or using a
                  different account).
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">16. Entire agreement</h4>
                <p>
                  <span className="text-bold mr-2">16.1.</span>
                  These general terms and conditions and the Leasehouz codes
                  policies and guidelines (and in respect of sellers the seller
                  terms and conditions) shall constitute the entire agreement
                  between you and us about your use of our marketplace and shall
                  supersede all previous agreements between you and us about
                  your use of our marketplace.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">17. Hierarchy</h4>
                <p>
                  <span className="text-bold mr-2">17.1.</span>
                  Should these general terms and conditions, the seller terms
                  and conditions, and the Leasehouz codes policies and
                  guidelines conflict, with these terms and conditions, the
                  seller terms and conditions and the Leasehouz codes policies
                  and guidelines shall prevail in the order here stated.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">18. Variation</h4>
                <p>
                  <span className="text-bold mr-2">18.1.</span>
                  We may revise these general terms and conditions, the seller
                  terms and conditions, and the Leasehouz codes policies and
                  guidelines from time to time.
                </p>
                <p>
                  <span className="text-bold mr-2">18.2.</span>
                  The revised general terms and conditions shall apply from the
                  date of publication on the marketplace.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">19. No waiver</h4>
                <p>
                  <span className="text-bold mr-2">19.1.</span>
                  No waiver of any breach of any provision of these general
                  terms and conditions shall be construed as a further or
                  continuing waiver of any other breach of that provision or any
                  breach of any other provision of these general terms and
                  conditions.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">20. Severability</h4>
                <p>
                  <span className="text-bold mr-2">20.1.</span>
                  If a provision of these general terms and conditions is
                  determined by any court or other competent authority to be
                  unlawful and/or unenforceable the other provisions will
                  continue in effect
                </p>
                <p>
                  <span className="text-bold mr-2">20.2.</span>
                  If any unlawful and/or unenforceable provision of these
                  general terms and conditions would be lawful or enforceable,
                  if part of it were deleted, that part will be deemed to be
                  deleted and the rest of the provision will continue in effect.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">21. Assignment</h4>
                <p>
                  <span className="text-bold mr-2">21.1.</span>
                  You hereby agree that we may assign a transfer sub-contract or
                  otherwise deal with our rights and/or obligations under these
                  general terms and conditions.
                </p>
                <p>
                  <span className="text-bold mr-2">21.2.</span>
                  You may not without our prior written consent assign transfer
                  sub-contract or otherwise deal with any of your rights and/or
                  obligations under these general terms and conditions.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">22. Third party rights</h4>
                <p>
                  <span className="text-bold mr-2">22.1.</span>. A contract
                  under these general terms and conditions is for our benefit
                  and your benefit and is not intended to benefit or be
                  enforceable by any third party.
                </p>
                <p>
                  <span className="text-bold mr-2">22.2.</span>
                  The exercise of the parties' rights under a contract under
                  these general terms and conditions is not subject to the
                  consent of any third party.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">23. Law and jurisdiction</h4>
                <p>
                  <span className="text-bold mr-2">23.1.</span>. These general
                  terms and conditions shall be governed by and construed by the
                  laws of the territory.
                </p>
                <p>
                  <span className="text-bold mr-2">23.2.</span>
                  Any disputes relating to these general terms and conditions
                  shall be subject to the exclusive jurisdiction of the courts
                  of the territory.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="text-bold">
                  24. Our company details and notices
                </h4>
                <p>
                  <span className="text-bold mr-2">24.1.</span>
                  You can contact us by using the contact details on our
                  website.
                </p>
                <p>
                  <span className="text-bold mr-2">24.2.</span>
                  You may contact our sellers for after-sales queries including
                  any disputes by requesting their contact details from
                  Leasehouz by the DISPUTE RESOLUTION POLICY under which
                  Leasehouz shall be obliged to ensure that the seller is
                  identifiable.
                </p>
                <p>
                  <span className="text-bold mr-2">24.3.</span>
                  You consent to receive notices electronically from us. We may
                  provide all communications and information related to your use
                  of the marketplace in electronic format either by posting to
                  our website or application or by email to the email address on
                  your account. All such communications will be deemed to be
                  notices in writing and received by and properly given to you.
                </p>
              </div>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default TermsAndConditions;
