import dayjs from 'dayjs';
import React, { useEffect } from 'react'
import {Table,Row,Col} from 'react-bootstrap'
import { Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getProductSubCategories } from '../../../redux/actions/productActions';
import NoData from '../Overview/NoData';

function LeasedProduct({soldProducts}) {
    
    const dispatch = useDispatch()
    //console.log(soldProducts)
    // useEffect(() => {
    //     dispatch(getProductSubCategories())
    // }, [dispatch])

    // const productSubCategories = useSelector(state => state.getSubCategories)
    // console.log(productSubCategories)

    const leasedproducts = soldProducts && soldProducts.filter(product =>{
        return product.loanable === true
    })

    //console.log(leasedproducts)

    return (
        <> 
            {
                leasedproducts && leasedproducts.length ?
                    <Row className="bg-white m-auto">
                        <Col xs={12} md={12} lg={12} className=''>
                            <Table responsive='xl' className="table-spacing">
                                <thead>
                                    <tr className="">
                                        <th className="text-muted">Date</th>
                                        <th className="text-muted">Lease Product</th>
                                        <th className="text-muted">Amount</th>
                                        <th className="text-muted">Quantity</th>
                                        <th className="text-muted">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        leasedproducts && leasedproducts.map((item, i) =>
                                            <tr key={i}>
                                                <td>{dayjs(item.created_at).format('D/MM/YYYY')}</td>              
                                                <td>{item.name}</td>
                                                <td>{item.amount.toLocaleString('en')}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.status_id === 1 ? 'Active' : 'Inactive'}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                : <NoData name='Leased Products'/>
            }
        </>
    )
}

export default LeasedProduct;
