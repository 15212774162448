import React, { useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown, Form } from "react-bootstrap";
import { MDBBadge, MDBIcon } from "mdb-react-ui-kit";
import { LinkContainer } from "react-router-bootstrap";
import Logo from "../../Assets/leasehouz.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, logout } from "../../redux/actions/authActions";
import { searchProduct } from "../../redux/actions/productActions";
import { getCartItems } from "../../redux/actions/cartActions";
import { Cookies } from "react-cookie";
import { useHistory } from "react-router";
// import { useNavigate } from "react-router";

const Header = ({ page, searchTerm }) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const history = useHistory();
  // const [page] = useState(2);
  // console.log(page);
  // console.log(searchTerm);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const getSearchResult = useSelector((state) => state.fetchSearchResult);
  const { searchedResult } = getSearchResult;

  const [inputData, setInputData] = useState("");
  const handleInput = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputData(lowerCase);
  };

  const handleSearch = () => {
    dispatch(searchProduct(inputData, 1));
    setTimeout(() => {
      history.push(`/products?search=${inputData}`, {
        stateData: searchedResult,
      });
    }, 3000);
  };

  useEffect(() => {
    if (page !== undefined && page !== null) {
      dispatch(searchProduct(searchTerm, page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getCartItems());
  }, [dispatch]);

  const logoutHandler = () => {
    cookies.remove("token");
    dispatch(logout());

    setTimeout(() => {
      history.push("/");
    }, 2000);
  };

  const token = cookies.get("token");

  const itemCount = cartItems && cartItems.length;

  useEffect(() => {
    if (!token) {
      dispatch(logout());
    }
  }, [token]);

  return (
    <>
      <Navbar className="bg-grey" expand="md">
        <div className="nav-brand-wrapper">
          <Navbar.Brand href="/">
            <img
              src={Logo}
              className="d-inline-block align-top logo"
              alt="Leasehouz Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="grid-container navbar-grid-container"
        >
          <Form className="search-area d-flex flex-wrap">
            <Form.Control
              autoFocus
              type="text"
              onChange={handleInput}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  e.preventDefault();
                  dispatch(searchProduct(inputData, 1));
                  setTimeout(() => {
                    history.push(`/products?search=${inputData}`, {
                      stateData: searchedResult,
                    });
                  }, 3000);
                }
              }}
              placeholder="Search products..."
              className="my-2 w-auto flex-fill border-0 bg-transparent"
            />
            <span
              className="material-icons m-2 cursor-pointer"
              onClick={handleSearch}
            >
              search
            </span>
            {userInfo && userInfo.data.vendor ? (
              <Link
                to="/dashboard"
                className="text-decoration-none border-0 p-2 my-2 px-4 bg-blue text-white rounded-3"
              >
                Go to Dashboard{" "}
              </Link>
            ) : (
              <Link
                to="/register/vendor"
                className="text-decoration-none border-0 p-2 my-2 px-4 bg-blue text-white rounded-3"
              >
                Become a vendor
              </Link>
            )}
          </Form>
          <Nav className="nav-area mr-auto">
            <Nav.Link href="/">Marketplace</Nav.Link>
            <Nav.Link href="/about">About Us</Nav.Link>
            <Nav.Link href="/services">Our Services</Nav.Link>
            <Nav.Link href="/partners">Our Partners</Nav.Link>
            <Nav.Link href="/contact">Contact Us</Nav.Link>
            {!userInfo ? (
              <>
                <Nav.Link href="/register">Register</Nav.Link>
                <Nav.Link href="/login">Login</Nav.Link>
              </>
            ) : (
              <Nav.Link href="/user">Profile</Nav.Link>
            )}
          </Nav>

          <div className="d-flex icon-area">
            <Link
              to="/payment/instant/"
              className="text-decoration-nonemx-3 m-2"
              href="#!"
            >
              <MDBIcon fas icon="cart-plus" size="lg" />
              <MDBBadge color="black" notification pill>
                {itemCount}
              </MDBBadge>
            </Link>
            {userInfo ? (
              <NavDropdown
                title={userInfo.data.user.first_name}
                id="first_name"
                className=""
              >
                <LinkContainer to="/user">
                  <NavDropdown.Item>Profile</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={logoutHandler}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Link
                to="/login"
                className="material-icons m-2 text-decoration-none text-dark-grey"
              >
                person
              </Link>
            )}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
