import axios from 'axios'
import { baseurl } from '../../Utils/baseurl';
import { Cookies } from 'react-cookie'
import * as types from '../types'

const cookies = new Cookies()

export const login = (email, password) => async (dispatch) => {

    try {
        dispatch({
            type: types.USER_LOGIN_REQUEST,
        })

        // GET the headers
        const requestOptions = {
            headers: { 'Content-Type': 'application/json' }
        };

        const { data } = await axios.post(`${baseurl}/login`, { email, password }, requestOptions)

        dispatch({
            type: types.USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        console.log(error.response.data.errors.user[0])
        dispatch({
            type: types.USER_LOGIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.errors.user[0] : error.message,
        })
    }
}

export const logout = () => async (dispatch) => {

    cookies.remove('token')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('vendor')

    dispatch({ type: types.USER_LOGOUT })
}

export const clearErrors = () => (dispatch) => setTimeout(() => dispatch({ type: types.CLEAR_ERRORS }), 3000)

export const register = (first_name, last_name, email, phone, password) => async (dispatch) => {
    try {
        dispatch({
            type: types.USER_REGISTER_REQUEST,
        })

        // GET the headers
        const requestOptions = {
            headers: { 'Content-Type': 'application/json' }
        };

        const res = await axios.post(`${baseurl}/register`, { first_name, last_name, email, phone, password }, requestOptions)
        const data = await res.data

        dispatch({
            type: types.USER_REGISTER_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        console.log(error.response)
        dispatch({
            type: types.USER_REGISTER_FAIL,
            payload: error.response && error.response.data.errors.email ? error.response.data.errors?.email[0] : error.response.data.errors.phone[0],
        })
    }
}

export const verifyUser = (email, token) => async (dispatch) => {
    // let successMessage = localStorage.getItem("successMessage") ? "success" : ""
    try {
        dispatch({
            type: types.VERIFY_REQUEST,
        })

        // GET the headers
        const requestOptions = {
            headers: { 'Content-Type': 'application/json' }
        };

        const res = await axios.post(`${baseurl}/activate/account`, { email, token }, requestOptions)
        const data = await res.data

        dispatch({
            type: types.VERIFY_SUCCESS,
            payload: data
        })

        dispatch({
            type: types.USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: types.VERIFY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const resendCode = (email) => async (dispatch) => {
    try {
        dispatch({
            type: types.RESEND_CODE_REQUEST,
        })

        const { data } = await axios.get(`${baseurl}/resend/token/${email}`)

        dispatch({
            type: types.RESEND_CODE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.RESEND_CODE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}


export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({
            type: types.FORGOT_PASSWORD_REQUEST,
        })
        const requestOptions = {
            headers: { 'Content-Type': 'application/json' }
        };
        const { data } = await axios.post(`${baseurl}/password`, { email }, requestOptions)
        dispatch({
            type: types.FORGOT_PASSWORD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.FORGOT_PASSWORD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const resetPassword = (resetData) => async (dispatch) => {
    try {
        dispatch({
            type: types.RESET_PASSWORD_REQUEST,
        })

        const { data } = await axios.patch(`${baseurl}/password`, resetData)

        dispatch({
            type: types.RESET_PASSWORD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.RESET_PASSWORD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const createVendor = (e) => async (dispatch) => {

    const token = cookies.get('token');

    // const { userLogin: { userInfo }} = getState()

    const formData = new FormData()
    formData.append('name', e.name)
    formData.append('store_name', e.store_name)
    formData.append('address', e.address)
    formData.append('banner', e.banner)

    try {
        dispatch({ type: types.CREATE_VENDOR_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        };
        const { data } = await axios.post(`${baseurl}/vendor`, formData, config)
        dispatch({
            type: types.CREATE_VENDOR_SUCCESS,
            payload: data
        })
        localStorage.setItem('vendor', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: types.CREATE_VENDOR_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const getUserProfile = () => async (dispatch) => {
    const token = cookies.get('token');
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*'
            }
        }

        const { data } = await axios.get(`${baseurl}/profile`, config)
        dispatch({
            type: types.GET_USER_PROFILE_REQUEST,
            payload: data
        })

    } catch (error) {
        dispatch({
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
            type: types.GET_USER_PROFILE_FAILED
        })
    }
}
export const getStates = (id) => async (dispatch) => {
    try {
        dispatch({ type: types.GET_STATES_REQUEST })

        const { data } = await axios.get(`${baseurl}/states/${id}`)
        dispatch({
            type: types.GET_STATES_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: types.GET_STATES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}
export const getCountries = () => async (dispatch) => {
    try {
        dispatch({ type: types.GET_COUNTRIES_REQUEST })

        const { data } = await axios.get(`${baseurl}/countries/active`)

        dispatch({
            type: types.GET_COUNTRIES_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: types.GET_COUNTRIES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const updateBio = (e) => async (dispatch) => {
    const token = cookies.get('token');
    const formData = new FormData()
    formData.append('first_name', e.first_name)
    formData.append('last_name', e.last_name)
    formData.append('phone', e.phone)
    formData.append('email', e.email)
    formData.append('avatar', e.avatar)
    try {
        dispatch({
            type: types.UPDATE_BIO_REQUEST,
        })

        // GET the headers
        const requestOptions = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        };

        const response = await axios.post(`${baseurl}/bio`, formData, requestOptions)

        dispatch({
            type: types.UPDATE_BIO_SUCCESS,
            payload: response
        })

    } catch (error) {
        dispatch({
            type: types.UPDATE_BIO_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const updateProfile = (state_id, country_id, address, next_of_kin_name, next_of_kin_phone) => async (dispatch) => {

    const token = cookies.get('token');
    try {
        dispatch({ type: types.UPDATE_PROFILE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };
        const { data } = await axios.post(`${baseurl}/profile`, { state_id, country_id, address, next_of_kin_name, next_of_kin_phone }, config)

        dispatch({
            type: types.UPDATE_PROFILE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: types.UPDATE_PROFILE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}
export const changePassword = (old_password, new_password, confirmPassword) => async (dispatch) => {

    const token = cookies.get('token');
    try {
        dispatch({ type: types.CHANGE_PASSWORD_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };
        const { data } = await axios.post(`${baseurl}/profile/change-password`, { old_password, new_password, confirmPassword }, config)

        dispatch({
            type: types.CHANGE_PASSWORD_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: types.CHANGE_PASSWORD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}