import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { saveCompanyLeaseData } from "../../../../redux/actions/leaseActions";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const companyProfileSchema = yup.object().shape({
  company_name: yup.string().required(),
  date_of_registration: yup.string().required(),
  phone_number: yup
    .string()
    .required()
    .matches(phoneRegExp, "Phone number is invalid")
    .min(11, "Should be 11 digits")
    .max(11, "Should be 11 digits"),
  city: yup.string().required(),
  email_address: yup.string().email().required(),
  landmark_nearest_bus_stop: yup.string().required(),
  nature_of_business: yup.string().required(),
});

function CompanyProfile({ onSelect }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(companyProfileSchema),
  });

  const onSubmit = (data) => {
    saveCompanyLeaseData(data);
    onSelect();
  };

  return (
    <>
      <div className="bg-white py-2 px-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h5 className="my-4 font-bold">Company Profile</h5>
          <Row>
            <Col xs={12} md={6} lg={6} className="py-2">
              <Form.Group className="mb-3" controlId="company-name">
                <Form.Label>
                  Company Name <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  name="company_name"
                  className="bg-light-grey"
                  {...register("company_name")}
                />
                {errors.company_name && (
                  <p className="text-red my-2">{errors.company_name.message}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="dateofregistration">
                <Form.Label>
                  Date of Registration <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="date_of_registration"
                  className="bg-light-grey"
                  {...register("date_of_registration")}
                />
                {errors.date_of_registration && (
                  <p className="text-red my-2">
                    {errors.date_of_registration.message}
                  </p>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="ageofcompany">
                <Form.Label>
                  Age of Company <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="age_of_company"
                  className="bg-light-grey"
                  {...register("age_of_company")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="rc-no">
                <Form.Label>
                  RC No <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="rc_no"
                  className="bg-light-grey"
                  {...register("rc_no")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="officeaddress">
                <Form.Label>
                  Office Address <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="office_address"
                  className="bg-light-grey"
                  {...register("office_address")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="nearest-landmark">
                <Form.Label>
                  Nearest Landmark <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="landmark_nearest_bus_stop"
                  className="bg-light-grey"
                  {...register("landmark_nearest_bus_stop")}
                />
                {errors.landmark_nearest_bus_stop && (
                  <p className="text-red my-2">
                    {errors.landmark_nearest_bus_stop.message}
                  </p>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="city">
                <Form.Label>
                  City <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  className="bg-light-grey"
                  {...register("city")}
                />
                {errors.city && (
                  <p className="text-red my-2">{errors.city.message}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>
                  Phone number <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="tel"
                  name="phone_number"
                  className="bg-light-grey"
                  {...register("phone_number")}
                />
                {errors.phone_number && (
                  <p className="text-red my-2">{errors.phone_number.message}</p>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={6} className="py-2">
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>
                  Email Address <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email_address"
                  className="bg-light-grey"
                  {...register("email_address")}
                />
                {errors.email_address && (
                  <p className="text-red my-2">
                    {errors.email_address.message}
                  </p>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="natureofbusiness">
                <Form.Label>
                  Nature of Business <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="nature_of_business"
                  className="bg-light-grey"
                  {...register("nature_of_business")}
                />
                {errors.nature_of_business && (
                  <p className="text-red my-2">
                    {errors.nature_of_business.message}
                  </p>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="industry">
                <Form.Label>
                  Industry <span className="text-red">*</span>{" "}
                </Form.Label>
                <Form.Control
                  as="select"
                  name="industry"
                  className="bg-light-grey"
                  {...register("industry")}
                >
                  <option>Select an industry</option>
                  <option value="Finance">Finance</option>
                  <option value="Technology">Technology</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Agriculture">Agriculture</option>
                  <option value="Logistics">Logistics</option>
                  <option value="Restaurant">Restaurant</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="products-offerings">
                <Form.Label>
                  Products/offerings <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="product"
                  className="bg-light-grey"
                  {...register("products_offerings")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="comment">
                <Form.Label>
                  Brief Description of The Business{" "}
                  <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                  className="bg-light-grey"
                  {...register("business_description")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="aim-of-company">
                <Form.Label>
                  Aim of The Company <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="aim_of_company"
                  className="bg-light-grey"
                  {...register("aim_of_company")}
                />
              </Form.Group>
            </Col>
          </Row>
          <button
            type="submit"
            className="d-flex ml-auto border-0 mt-2 px-4 py-2"
          >
            Next
          </button>
        </form>
      </div>
    </>
  );
}

export default CompanyProfile;
