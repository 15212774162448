import React from 'react'
import { Form, Button, Row, Col, Container } from 'react-bootstrap'

function Newsletter() {
    return (
        <> 
            <div className="bg-blue py-5">
                <Container className='' fluid='md'> 
                    <Form>
                        <Form.Group as={Row} className='m-auto justify-content-center mw-700'>
                            <Form.Label className='text-white text-center my-2' column sm='2'>Newsletter</Form.Label>
                            <Col xs='auto' md={6} className='position-relative my-2'>
                                <Form.Control className='rounded-half' type='email' size='lg' placeholder='Your email' />
                                <Button type="submit" className=" rounded-circle newsletter-button position-absolute">
                                    <span className="material-icons">send</span>
                                </Button>
                            </Col>
                        </Form.Group>
                    </Form>
                    {/* <Form inline className='justify-content-center'> 
                        <Form.Label className='text-white' htmlFor="email"> Newsletter </Form.Label>
                        <InputGroup className="position-relative"> 
                            <Form.Control
                                className="rounded-half"
                                id="email"
                                type='email'
                                placeholder="Your email" 
                                size='lg'
                            />
                            <Button type="submit" className="mb-2 rounded-circle newsletter-button position-absolute">
                                <span className="material-icons">send</span>
                            </Button>
                        </InputGroup>
                    </Form> */}
                </Container>
            </div>
        </>
    )
}

export default Newsletter
