import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import Loader from "../Reusable/Loader";
import Message from "../Reusable/Message";
import OnBoardNavbar from "../Reusable/OnBoardNavbar";
import { login } from "../../redux/actions/authActions";

const Login = ({ history }) => {
  const location = useLocation();
  const [cookies, setCookie] = useCookies(["token"]);
  const url = location.state && location.state.url;

  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { email, password } = user;

  const findFormErrors = () => {
    const newErrors = {};
    //eslint-disable-next-line
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // email errors
    if (!regEmail.test(email) || email === "")
      newErrors.email = "Email field is invalid!";

    if (!password || password === "")
      newErrors.password = "Password field cannot be empty!";
    else if (password.length < 7)
      newErrors.password = "Password cannot be less than 7 characters!";

    return newErrors;
  };

  const getFormValues = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });

    // Check and see if errors exist and remove them from the error object:
    if (!!errors[e.target.name])
      setErrors({
        ...errors,
        [e.target.name]: null,
      });
  };

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);

  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    // SAVE TOKEN TO COOKIE WHEN USER LOGS IN
    if (userInfo) {
      const accessToken = userInfo.data.accessToken;
      setCookie("token", accessToken, { maxAge: 518400 });
      if (location.state && location.state.url) {
        history.push(location.state.url);
      } else {
        history.push("/");
      }
    }
    // eslint-disable-next-line
  }, [history, userInfo]);

  const submitLogin = (e) => {
    e.preventDefault();

    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      // DISPATCH LOGIN ACTION
      dispatch(login(email, password));
    }
  };

  return (
    <>
      <OnBoardNavbar navlink={"Register"} />
      <div className="mt-5">
        <h1 className="text-center text-responsive">Welcome Back</h1>
        <p className="text-center">
          Kindly enter your email and password to sign in
        </p>
      </div>
      {error && <Message variant="danger">{error}</Message>}

      <Form onSubmit={submitLogin} className="mt-5 mw-400 mx-auto">
        <Form.Group className="my-4">
          <Form.Label htmlFor="email">Email Address</Form.Label>
          <Form.Control
            isInvalid={!!errors.email}
            autoFocus
            size="lg"
            className="bg-light border-0"
            type="email"
            placeholder="Enter email"
            name="email"
            id="email"
            onChange={getFormValues}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Label htmlFor="password">Password</Form.Label>
          <Form.Control
            isInvalid={!!errors.password}
            size="lg"
            className="bg-light border-0"
            type="password"
            name="password"
            placeholder="Enter password"
            id="password"
            onChange={getFormValues}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="my-4">
          <Form.Text>
            <b>Password should be:</b>
          </Form.Text>
          <Form.Text>At least 6 characters long</Form.Text>
          <Form.Text>A mixture of Upper and Lowercase letters</Form.Text>
        </div>
        <Button type="submit" block className="bg-blue border-0">
          {loading ? <Loader /> : "Sign In"}
        </Button>
        <p className="text-center mt-3">
          Don't have an account?
          <Link
            to={{ pathname: "/register", state: { url: url } }}
            className="text-blue text-decoration-none"
          >
            {" "}
            Register{" "}
          </Link>
        </p>
        <p className="text-center mt-3">
          Forgot password?
          <Link
            to="/forgot-password"
            className="text-blue text-decoration-none"
          >
            {" "}
            Reset{" "}
          </Link>
        </p>
      </Form>
    </>
  );
};

export default Login;
