import React from "react";
import Header from "../Components/Reusable/Header";
import HeaderCarousel from "../Components/Reusable/HeaderCarousel";
import { Container } from "react-bootstrap";
import ProductShowcase from "../Components/Reusable/ProductShowcase";
import trendingproducts from "../Utils/trendingproducts";
import ProductCategories from "../Components/Home/ProductCategories";
import Testimonial from "../Components/Reusable/Testimonial";
import Footer from "../Components/Reusable/Footer";
// import Newsletter from '../Components/Reusable/Newsletter'
// import Loader from '../components/reusable/Loader'

function Marketplace() {
  return (
    <>
      <div className="bg-light-blue w-100">
        <Container className="py-3 mb-5" fluid="md">
          <Header />
          <HeaderCarousel />
        </Container>
        <ProductShowcase
          heading="Top Trending"
          products={trendingproducts}
          tagID={1}
        />
        <ProductCategories />
        {/* <ProductShowcase
                    heading='Recently Viewed'
                    products = {trendingproducts}
                /> */}
        <Testimonial />
        {/* <Newsletter/> */}
        <Footer />
      </div>
    </>
  );
}

export default Marketplace;
