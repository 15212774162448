import React from 'react'
import Header from '../Components/Reusable/Header'
import { Container } from 'react-bootstrap'
import Footer from '../Components/Reusable/Footer'
import PaymentNav from '../Components/Payments/Instant/PaymentNav'

function InstantPayment() {
    return (
        <>
            <div className="bg-light-grey w-100">
                <Container className='py-3' fluid='md'>
                    <Header />
                    <PaymentNav/>
                </Container>
                {/* <Newsletter/>  */}
                <Footer/> 
            </div>
        </>
    )
}

export default InstantPayment
