import React from 'react'

function FleetManagement() {
    return (
        <>
            <div className="mt-4 height-300 overflow-y">
                <p>Here, the service includes a range of functions such as vehicle financing, maintenance, tracking, driver management, speed management, fuel management, fleet insurance programs, license and title services and health and safety management. It allows companies to minimize investments in vehicles, and their overall transportation cost.</p>
                <p>At <strong>AssetKap</strong>, we deliver custom-built fleet leasing and management programs tailored for the various fleet sizes and budgets. Depending on customer preference, we are happy to provide some or all of the services that go with fleet management. This, we do with our team of knowledgeable and experienced leasing and fleet management professionals that have been providing the service for years in the Nigerian market, not forgetting our competent partners who support us all the way.</p>
                <p><strong>Vehicle financing: </strong>In addition to our capital, we enjoy the support of other partners to fund the purchase of required assets.</p>
                <p><strong>Maintenance:</strong>  We offer full maintenance. The cost of maintaining the vehicle is provided for in the agreed payment</p>
                <p><strong>Tracking:</strong> We have existing and long standing relationships with selected and tested tracking companies</p>
                <p><strong>Driver management:</strong>  We have the expertise and partnerships needed to effectively manage and train drivers.</p>
                <p><strong>Fuel management:</strong>  With the use of a payment card, our fuel management solution is convenient. It monitors  fleet expense and reduces cost.</p>
                <p><strong>Fleet insurance:</strong> We are in relationship with top insurance partners in Nigeria. Our in-house systems also support the management and processing of insurance policies, renewals and claims.</p>
                <p><strong>Health and safety:</strong> Driver training and history is key to us. Vehicle history and quality is another. We also provide other protection plans in this regard.</p>
            </div>
        </>
    )
}

export default FleetManagement
