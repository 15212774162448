import React,{ useEffect, useRef, useState } from 'react';
import {Row, Col,Form} from 'react-bootstrap';
// import {Link} from 'react-router-dom'
//import MultiselectOpt from '../MultiselectOpt';
import RichEditor from '../RichEditor';
import FileUpload from '../FileUpload';
// import ProductTag from '../ProductTag';
//import PreviewProduct from './PreviewProduct'
import { useDispatch, useSelector } from 'react-redux'
import { getProductCategories, getBrands } from '../../../redux/actions/productActions';
import PreviewProduct from './PreviewProduct';
import Message from '../../Reusable/Message';
import { createVendorProduct } from '../../../redux/actions/vendorActions';
import Loader from '../../Reusable/Loader';

function AddProduct({open, onClose}) {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [description, setdescription] = useState()
    const [fileUpload, setfileUpload] = useState()
    const [alert, setalert] = useState(false)

    const getProductDescription = (description) =>{
        setdescription(description)
    }

    const getFileUpload = (file) =>{
        setfileUpload(file)
    }


    const dispatch = useDispatch()

    const getVendorProfile = useSelector(state => state.getProfile)
    const {vendor} = getVendorProfile.profile
    
    //console.log(vendor)

    //GET PRODUCT CATEGORIES TO DISPLAY SUBCATEGORIES
    const getCategories = useSelector(state => state.getCategories)
    const { categories } = getCategories

    const createProduct = useSelector(state => state.createProduct)
    const { data, loading, error } = createProduct


    useEffect(() => {
        dispatch(getBrands())
        dispatch(getProductCategories())
    }, [dispatch])

    //GET ALL PRODUCT SUBCATEGORIES AND DISPLAY IN FORM
    const subcategories = []
    categories && categories.map((subcategory, index) => {
        //return console.log(subcategory.sub_categories)
        return subcategory.sub_categories.map(data => {
            return subcategories.push(data)
        })
    })

    //GET ALL PRODUCT BRANDS AND DISPLAY IN FORM
    const brands = useSelector(state => state.getBrands)

    // console.log(subcategories)
    // console.log(brands)

    //SAVE PRODUCTS TO DRAFT
    // const drafts = []
    // const items = {}
    // const retrievedDrafts = JSON.parse(localStorage.getItem('draftsLocalStorage'))

    const formRef = useRef(null)
    // const saveToDraft = () =>{
    //     const form = formRef.current
    //     let formdata = new FormData(form)

    //     //ADD THE DESCRIPTION AND IMAGE
    //     formdata.append('description', description)
    //     formdata.append('banner', fileUpload)
    //     formdata.append('vendor_id', vendor.id)
    //     for(let [name, value] of formdata) {
    //         items[name] = value
    //         console.log(`${name} = ${value}`);
    //     }
        
    //     drafts.push(items)

    //     //IF DRAFTS IN LOCAL STORAGE DO NOT EXIST CREATE ONE, IF IT DOES ADD MORE DATA TO IT
    //     if(!retrievedDrafts){
    //         localStorage.setItem('draftsLocalStorage', JSON.stringify(drafts))
    //     }
    //     else{
    //         let newItems = []
    //         newItems = JSON.parse(localStorage.getItem('draftsLocalStorage')) || [];
    //         newItems.push(items)
    //         localStorage.setItem('draftsLocalStorage', JSON.stringify(newItems))
    //     }
    //     setalert(true)
    // }

    const publishProduct = () =>{
        const form = formRef.current
        let formdata = new FormData(form)

        //ADD THE DESCRIPTION AND IMAGE
        formdata.append('description', description)
        formdata.append('banner', fileUpload)
        formdata.append('vendor_id', vendor.id)
        for(let [name, value] of formdata) {
            //console.log(`${name} = ${value}`);
        }
        dispatch(createVendorProduct(formdata))
        setalert(true)
    }
    //console.log(data)

    useEffect(() => {
        if(data){
            setTimeout(() => {
                // onClose()
                window.location.reload()
            }, 1500);
        }
    }, [data])


    //SET ALERT TO FALSE AFTER 3 SECONDS
    if(alert){
        setTimeout(() => {
            setalert(false)
        }, 3000);
    }



    if (!open) return null
    return (
        <>
            <div className='modal-details-container'>
                {/* <PreviewProduct previewData={previewData} open={isModalOpen} onClose={ () => setIsModalOpen(false)}/> */}
                <div className='modal-container'>
                    <div className="d-flex justify-content-between width-95 m-auto pt-5">
                        <h5 className="mb-3"><span className="text-muted">Product</span>/Add Product</h5>
                        <span onClick={onClose} className="material-icons cursor-pointer font-bold">close</span>
                    </div>
                    <Form ref={formRef} className=''>
                        {
                            data || error ? <Message variant={data ? 'success' : 'danger'}>{error || data && data.message}</Message> : null
                        }
                        <Row className='bg-white add-product-wrapper width-95 m-auto'>
                            <Col xs={12} md={6} lg={7} className='py-2 shipping-addres mw-50'>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Product Name <span className="text-red">*</span></Form.Label>
                                    <Form.Control type="text" name='name' className='bg-light-grey' required/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="category">
                                    <Form.Label>Product Category <span className="text-red">*</span></Form.Label>
                                    <Form.Control as="select" name='product_sub_category_id' className='bg-light-grey'>
                                        <option>Select a category</option>
                                        {
                                            subcategories.map(value => {
                                                return <option key={value.id} value={parseInt(value.id)}>{value.name}</option>
                                            })
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Description <span className="text-red">*</span></Form.Label>
                                    <RichEditor onChange={ getProductDescription } className='bg-light-grey'/>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={5} className="py-2">
                                <Form.Group className="mb-3" controlId="category">
                                    <Form.Label>Product Brand <span className="text-red">*</span></Form.Label>
                                    <Form.Control as="select" name='brand_id' className='bg-light-grey'>
                                        <option>Select the product brand</option>
                                        {
                                            brands && brands.brands.data.map((value, i) => {
                                                return <option key={value.id} value={parseInt(value.id)}>{value.name}</option>
                                            })
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="product-image">
                                    <Form.Label>Product Image <span className="text-red">*</span></Form.Label>
                                    <FileUpload onChange={ getFileUpload } />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="current-price">
                                    <Form.Label>Product Price <span className="text-red">*</span></Form.Label>
                                    <Form.Control  name='amount' type="number" className='bg-light-grey' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="quantity">
                                    <Form.Label>Product Quantity <span className="text-red">*</span></Form.Label>
                                    <Form.Control name='quantity' type="number" className='bg-light-grey' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="installment">
                                    <Form.Label>Mode of payment <span className="text-red">*</span></Form.Label>
                                    <Form.Control name='loanable' as="select" className='bg-light-grey'>
                                        <option>Select the payment method</option>
                                        <option value={1}>Installment</option>
                                        <option value={0}>Instant</option>
                                    </Form.Control>
                                </Form.Group>
                                {/* <Form.Group className="mb-3" controlId="product-condition">
                                    <Form.Label>Product Condition</Form.Label>
                                    <Form.Control as="select" className='bg-light-grey'>
                                        <option>Select the product condition</option>
                                        <option>Pre Used(UK)</option>
                                        <option>Pre Used(Nigeria)</option>
                                        <option>Brand New </option>
                                        <option>Refurbished</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="available-colors">
                                    <Form.Label>Available Colors</Form.Label>
                                    <MultiselectOpt  /> 
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="product-tag">
                                    <Form.Label>Product Tags</Form.Label>
                                    <MultiselectOpt />
                                </Form.Group> */}
                            </Col>
                        </Row>
                    </Form>
                    <Row className="text-light-grey">
                        <Col xs={12} md={6} lg={12}>
                            <div className="float-right my-4 mr-3 ipad-margin">
                                <button onClick={publishProduct} className="bg-blue text-white border-blue rounded-3 py-2 px-4 m-2">{ loading ? <Loader/> : 'Publish'}</button>
                                {/* <button className="bg-transparent text-blue border-blue rounded-3 py-2 px-4 m-2">Save to draft</button>
                                <button onClick={sendDataToPreview} className="bg-blue text-white border-blue rounded-3 py-2 px-4 m-2">
                                    Preview
                                </button> */}
                            </div>  
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default AddProduct;