import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import Overview from "./Overview";
import Products from "./Products";
import Order from "./Order";
import Profile from "./Profile";
import DashboardHeader from "../../Components/Vendor/DashboardHeader";
import SideNav from "../../Components/Vendor/SideNav";
import Footer from "../../Components/Reusable/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../redux/actions/authActions";
import { Redirect } from "react-router-dom";
import { fetchVendorOrders } from "../../redux/actions/vendorActions";

function Routes({ match }) {
  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const handleSidebar = () => {
    isSidebarOpen === true ? setisSidebarOpen(false) : setisSidebarOpen(true);
  };

  const dispatch = useDispatch();

  const getProfile = useSelector((state) => state.getProfile);
  const { profile } = getProfile;
  const vendorID = profile?.vendor && profile?.vendor?.id;

  const getVendorOrders = useSelector((state) => state.getVendorOrders);
  const { loading, data } = getVendorOrders;

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(fetchVendorOrders(vendorID));
  }, [dispatch, vendorID]);

  if (!profile) return <Redirect to="/login" />;
  return (
    <>
      <DashboardHeader
        vendorData={profile && profile.vendor}
        sidebarToggle={handleSidebar}
      />
      <div className="dashboard-container">
        <div className="d-flex sidebar">
          <SideNav
            open={isSidebarOpen}
            onClose={() => setisSidebarOpen(false)}
          />
        </div>
        <div className="w-100">
          <Switch>
            <Route
              exact
              path="/dashboard"
              render={() => (
                <Overview orderData={data} orderLoading={loading} />
              )}
            />
            <Route
              path={match.url + "/products"}
              render={() => <Products data={data} loading={loading} />}
            />
            <Route
              path={match.url + "/order"}
              render={() => (
                <Order
                  data={data}
                  loading={loading}
                  vendorData={profile.vendor && profile.vendor.id}
                />
              )}
            />
            <Route path={match.url + "/profile"} component={Profile} />
          </Switch>
        </div>
      </div>
      {/* <Newsletter /> */}
      <Footer />
    </>
  );
}

export default Routes;
