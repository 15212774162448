import React from "react";
import { useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { saveIndividualLeaseData } from "../../../../redux/actions/leaseActions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup
  .object()
  .shape({
    employer_name: yup.string().required(),
    employer_address: yup.string().required(),
    monthly_salary: yup.number().required(),
    monthly_expense: yup.number().required(),
    debt_payment: yup.number().required(),
    salary_account_number: yup
      .string()
      .required()
      .matches(phoneRegExp, "Account Number is invalid")
      .min(10, "Should be 10 digits")
      .max(10, "Should be 10 digits"),
    bvn: yup
      .string()
      .required()
      .matches(phoneRegExp, "BVN is invalid")
      .min(11, "BVN Should be 11 digits")
      .max(11, "BVN Should be 11 digits"),
    // phone_number: yup.string().required().matches(phoneRegExp, "Phone number is invalid").min(11, "Should be 11 digits").max(11, "Should be 11 digits"),
    // landmark_nearest_bus_stop: yup.string().required(),
    // next_of_kin_name: yup.string().required(),
    // relationship: yup.string().required(),
    // next_of_kin_house_address: yup.string().required(),
    // next_of_kin_phone_number: yup.string().required().matches(phoneRegExp, "Phone number is invalid").min(11, "Should be 11 digits").max(11, "Should be 11 digits"),
    // next_of_kin_email_address: yup.string().email().required()
  })
  .required();

function EmploymentInformation({ onSelect, onPrevious }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    saveIndividualLeaseData(data);
    onSelect();
  };

  return (
    <>
      <div>
        <div className="bg-white py-2 px-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h5 className="my-4 font-bold">Employment Details</h5>
            <Row>
              <Col xs={12} md={6} lg={6} className="py-2">
                <Form.Group className="mb-3" controlId="employment_type">
                  <Form.Label>
                    Employment Type <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="employment_type"
                    className="bg-light-grey"
                    {...register("employment_type")}
                  >
                    <option>Select a type</option>
                    <option value="permanent">Permanent</option>
                    <option value="contract">Contract</option>
                    <option value="selfemployed">Self Employed</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="occupation">
                  <Form.Label>
                    Occupation <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="occupation"
                    className="bg-light-grey"
                    {...register("occupation")}
                  />
                  {errors.employment_type && (
                    <p className="text-red">{errors.employment_type.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="employer-name">
                  <Form.Label>
                    Employer Name <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="employer-name"
                    className="bg-light-grey"
                    {...register("employer_name")}
                  />
                  {errors.employer_name && (
                    <p className="text-red">{errors.employer_name.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="employer-address">
                  <Form.Label>
                    Employer Address <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="employer-address"
                    className="bg-light-grey"
                    {...register("employer_address")}
                  />
                  {errors.employer_address && (
                    <p className="text-red">
                      {errors.employer_address.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="doe">
                  <Form.Label>
                    Date of Employment <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="doe"
                    className="bg-light-grey"
                    {...register("date_of_employment")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={6} className="py-2">
                <Form.Group className="mb-3" controlId="monthly-salary">
                  <Form.Label>
                    Monthly Salary <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="monthly-salary"
                    className="bg-light-grey"
                    {...register("monthly_salary")}
                  />
                  {errors.monthly_salary && (
                    <p className="text-red">{errors.monthly_salary.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="monthly-expense">
                  <Form.Label>
                    Monthly Expense <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="monthly_expense"
                    className="bg-light-grey"
                    {...register("monthly_expense")}
                  />
                  {errors.monthly_expense && (
                    <p className="text-red">{errors.monthly_expense.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="monthly-expense">
                  <Form.Label>
                    Debt Payment <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="debt_payment"
                    className="bg-light-grey"
                    placeholder="Enter 0 if none"
                    {...register("debt_payment")}
                  />
                  {errors.debt_payment && (
                    <p className="text-red">{errors.debt_payment.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="Account-number">
                  <Form.Label>
                    Salary Account Number <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="salary_account_no"
                    className="bg-light-grey"
                    {...register("salary_account_number")}
                  />
                  {errors.salary_account_number && (
                    <p className="text-red">
                      {errors.salary_account_number.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="bvn">
                  <Form.Label>
                    BVN <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="bvn"
                    className="bg-light-grey"
                    {...register("bvn")}
                  />
                  {errors.bvn && (
                    <p className="text-red">{errors.bvn.message}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <button onClick={onPrevious} className="border-0 mr-2 px-4 py-2">
                Previous
              </button>
              <button className="border-0 px-4 py-2">Next</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EmploymentInformation;
