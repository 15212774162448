import dayjs from 'dayjs'
import React from 'react'
import { Table } from 'react-bootstrap'
import arrowRight from '../../../Assets/arrow-right.png'

function RecentTransactionsTable({data}) {
    //console.log(data)
    return (
        <>
            <h5 className="m-0 text-bold">Recent Transaction</h5>
            <Table responsive="xl" className="table-spacing">
                <thead>
                    <tr>
                        <th className="text-muted">Date</th>
                        <th className="text-muted">Product</th>
                        {/* <th className="text-muted">Payment</th> */}
                        <th className="text-muted">Qty</th>
                        <th className="text-muted">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data.data.products.slice(0, 6).map((item, i) =>
                            <tr key={i}>
                                <td>{dayjs(item.created_at).format('D/MM/YYYY')}</td>              
                                <td>{item.product.name}</td>
                                {/* <td>{!item.product.loanable ? 'Instant' : 'Installment'}</td> */}
                                <td>{item.quantity}</td>
                                <td>{item.product.amount.toLocaleString('en')}</td>
                                <td><img src={arrowRight} alt="right arrow"/></td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    )
}

export default RecentTransactionsTable
