import React, { useState } from 'react'
import { Tabs, Tab} from 'react-bootstrap'
import AllProducts from './Products/AllProducts'
import SoldProducts from './Products/SoldProducts'
import LeasedProduct from './Products/LeasedProduct'
import Draft from './Products/Draft'
import NoData from './Overview/NoData'
import { useEffect } from 'react'
import { fetchVendorProducts } from '../../redux/actions/vendorActions'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../Reusable/Loader'


function ProductNav({ orderData }) {

    const dispatch = useDispatch()

    const getVendorProducts = useSelector(state => state.getVendorProducts)
    const {loading, data, error} = getVendorProducts

    //console.log(data && data.data.data)
    const productData = data && data.data.data

    useEffect(() => {
        dispatch(fetchVendorProducts())
    }, [dispatch])
    


    return (
        <>
            <Tabs defaultActiveKey="All Products" className='my-4' id="vendorProducts">
                <Tab eventKey="All Products" title="All Products" className='px-3 m-auto'>
                    {
                        loading 
                        ? <Loader/>
                        : data ? <AllProducts products={productData}/>
                        : <NoData name='Products'/>
                    }
                </Tab>
                <Tab eventKey="Leased Products" title="Lease Products" className='px-3 m-auto'>
                    {
                        <LeasedProduct soldProducts={productData} />
                        // orderData && !orderData.data.products.length ? <NoData name='Leased Products'/> : <LeasedProduct data={productData} />
                    }
                </Tab>
                <Tab eventKey="Sold Prdoucts" title="Sold Products" className='px-3 m-auto'>
                    {orderData && !orderData.data.products.length ? <NoData name="Sold Products"/> : <SoldProducts soldProducts={orderData} /> }
                </Tab>
                {/* <Tab eventKey="Draft" title="Drafts" className='px-3 m-auto'>
                    {!draftsData ? <NoData name="Drafts" /> : <Draft  />}
                </Tab> */}
            </Tabs>
        </>
    )
}

export default ProductNav;
