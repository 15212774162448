import React from 'react'
import Lottie from 'react-lottie';
import checkmark from '../../Assets/checkmark.json';
import { Modal } from 'react-bootstrap';

const RegisterModal = ({ show, onClose }) => {

    //CLOSE MODAL
    const turnOn = () => {
        onClose();
    }


    //CHECKMARK ANIMATION SETTINGS
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: checkmark,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    if (!show) return false
    return (
        <>
            <div className=''>
                <Modal show={show} onHide={turnOn} className='d-flex h-100 align-items-center'>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className='text-center'>
                        <Lottie
                            options={defaultOptions}
                            height={'100px'}
                            width={'100px'}
                        />
                        You have successfully registered on Leasehouz
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default RegisterModal
