import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'


const Message = ({ variant, children }) => {
    const Errors = ()=>{
        children.map((error, id)=>{
            return <li>error</li>
        })
    }
    const [show, setShow] = useState();

    setTimeout(() => {
        setShow(false)
    }, 2000);

    return (
        <Alert show={show} className='alert position-fixed z-index-100' variant={variant} >
            {children}
        </Alert>
    )
}

Message.defaultProps = {
    variant: 'info'
}
export default Message
