import axios from 'axios'
import * as types from '../types'
import { Cookies } from 'react-cookie'
import { baseurl } from '../../Utils/baseurl'

const cookies = new Cookies()

export let initialState = {}
export const saveIndividualLeaseData = (individualLeaseData) => {
    initialState = {
        ...initialState,
        ...individualLeaseData
    }

}

export const submitIndividualLeaseData = (leasedata) => async (dispatch) => {
    const token = cookies.get('token');
    let form_data = new FormData();

    for (var key in leasedata) {
        form_data.append(key, leasedata[key]);
    }
    try {

        dispatch({ type: types.SUBMIT_INDIVIDUAL_LEASE_DATA_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        };

        const { data } = await axios.post(`${baseurl}/loan/individuals`, form_data, config)

        dispatch({
            type: types.SUBMIT_INDIVIDUAL_LEASE_DATA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.SUBMIT_INDIVIDUAL_LEASE_DATA_FAIL,
            payload: error.response && error.response.data.message ? error.response.data : error,
        })
    }
}

export const saveCompanyLeaseData = (companyLeaseData) => {
    initialState = {
        ...initialState,
        ...companyLeaseData
    }
}


export const submitCompanyLeaseData = (companyData) => async (dispatch) => {
    const token = cookies.get('token');
    let companyForm_data = new FormData();

    for (var key in companyData) {
        companyForm_data.append(key, companyData[key]);
    }
    try {

        dispatch({ type: types.SUBMIT_COMPANY_LEASE_DATA_REQUEST })

        const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }

        const { data } = await axios.post(`${baseurl}/loan/company`, companyForm_data, config)
        dispatch({
            type: types.SUBMIT_COMPANY_LEASE_DATA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.SUBMIT_COMPANY_LEASE_DATA_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const fetchLeaseOrders = () => async (dispatch) => {
    const token = cookies.get('token');
    try {

        dispatch({ type: types.FETCH_LEASE_ORDERS_REQUEST })

        const config = { headers: { Authorization: `Bearer ${token}` } }

        const { data } = await axios.get(`${baseurl}/orders/loan`, config)
        dispatch({
            type: types.FETCH_LEASE_ORDERS_SUCCESS,
            payload: data.data.orders
        })

    } catch (error) {
        dispatch({
            type: types.SUBMIT_COMPANY_LEASE_DATA_FAIL,
            payload: error.response && error.response.data ? error.response.data : error.message,
        })
    }
}

export const payLeaseDeposit = (leaseData) => async (dispatch) => {
    const token = cookies.get('token');
    try {

        dispatch({ type: types.PAY_LEASE_DEPOSIT_REQUEST })

        const config = { headers: { Authorization: `Bearer ${token}` } }

        const { data } = await axios.post(`${baseurl}/transaction/loan-deposit`, leaseData, config)

        dispatch({
            type: types.PAY_LEASE_DEPOSIT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.PAY_LEASE_DEPOSIT_FAIL,
            payload: error.response && error.response.data ? error.response.data : error.message,
        })
    }
}

export const getLeaseHistory = (orderCode) => async (dispatch) => {
    const token = cookies.get('token');
    try {

        dispatch({ type: types.GET_LEASE_HISTORY_REQUEST })

        const config = { headers: { Authorization: `Bearer ${token}` } }

        const { data } = await axios.get(`${baseurl}/orders/loan/histories/${orderCode}`, config)

        dispatch({
            type: types.GET_LEASE_HISTORY_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: types.GET_LEASE_HISTORY_FAIL,
            payload: error.response && error.response.data ? error.response.data : error.message,
        })
    }
}