import dayjs from 'dayjs';
import React from 'react'
import {Table,Row,Col, Badge} from 'react-bootstrap'
import PaginationNumber from '../../Reusable/Pagination';

function SoldProducts({soldProducts}) {
    //console.log(soldProducts)
    return (
        <> 
           <Row className="bg-white m-auto">
              <Col xs={12} lg={12} className=''>
                <Table responsive="xl" className="table-spacing">
                    <thead>
                        <tr className="">
                            <th className="text-muted">Date</th>
                            <th className="text-muted">Product</th>
                            {/* <th className="text-muted">Payment</th> */}
                            <th className="text-muted">Qty</th>
                            <th className="text-muted">Amount</th>
                            <th className="text-muted">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            soldProducts && soldProducts.data.products.map((item, i) =>
                                <tr key={i}>
                                    <td>{dayjs(item.product.created_at).format('DD/MM/YYYY')}</td>              
                                    <td>{item.product.name}</td>
                                    {/* <td>{!item.product.loanable ? 'Instant' : 'Installment'}</td> */}
                                    <td>{item.quantity}</td>
                                    <td>{item.product.amount.toLocaleString('en')}</td>
                                    <td>
                                        <Badge pill className=
                                            {( item.product.status_id === 1 ? 'bg-success' : item.product.status_id === 2 ? 'bg-warning' : item.product.status_id === 8 ? 'bg-warning text-light-grey' : item.product.status_id === 4 || 6 ? 'bg-success' : null ) +
                                            " text-light py-2 px-2"}>
                                            { 
                                                item.product.status_id === 1
                                                ? 'Active'
                                                : item.product.status_id === 2
                                                ? 'Inactive'
                                                : item.product.status_id === 4
                                                ? 'Paid'
                                                : item.product.status_id === 6
                                                ? 'Completed'
                                                : item.product.status_id === 7
                                                ? 'Loan'
                                                : item.product.status_id === 8
                                                ? 'In progress'
                                                : null
                                            }
                                        </Badge>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
               </Table>
               </Col>
            </Row>
            {
                soldProducts && <PaginationNumber pageNumber={soldProducts.data.cart.last_page}/>
            }
        </>
    )
}

export default SoldProducts;