import dayjs from "dayjs";
import React from "react";
import { Row, Col, Badge, Table } from "react-bootstrap";
import Loader from "../../Components/Reusable/Loader";
import PaginationNumber from "../../Components/Reusable/Pagination";
import NoData from "../../Components/Vendor/Overview/NoData";
import arrowRight from "../../Assets/arrow-right.png";

function Order({ data, loading }) {
  return (
    <>
      <div className="bg-light-grey py-5">
        <div className="px-3 m-auto">
          {loading ? (
            <Loader />
          ) : data && data.data.products.length ? (
            <>
              <h5 className="text-bold pb-2">Orders</h5>
              <Row className="bg-white m-auto">
                <Col xs={12} className="">
                  <Table responsive="xl" className="table-spacing">
                    <thead>
                      <tr className="">
                        <th className="text-muted">Date</th>
                        <th className="text-muted">Product</th>
                        <th className="text-muted">Quantity</th>
                        <th className="text-muted">Amount</th>
                        <th className="text-muted">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.data.products.map((item, i) => {
                        return (
                          <tr key={i} className="cursor-pointer">
                            <td>
                              {dayjs(item.product.created_at).format(
                                "D/MM/YYYY"
                              )}
                            </td>
                            <td>{item.product.name}</td>
                            {/* <td>{!item.product.loanable ? 'Instant' : 'Installment'}</td> */}
                            <td>{item.quantity}</td>
                            <td>{item.product.amount.toLocaleString("en")}</td>
                            <td>
                              <Badge
                                pill
                                className={
                                  (item.product.status_id === 1
                                    ? "bg-success"
                                    : item.product.status_id === 2
                                    ? "bg-warning"
                                    : item.product.status_id === 8
                                    ? "bg-warning text-light-grey"
                                    : item.product.status_id === 4 || 6
                                    ? "bg-success"
                                    : null) + " text-light py-2 px-2"
                                }
                              >
                                {item.product.status_id === 1
                                  ? "Active"
                                  : item.product.status_id === 2
                                  ? "Inactive"
                                  : item.product.status_id === 4
                                  ? "Paid"
                                  : item.product.status_id === 6
                                  ? "Completed"
                                  : item.product.status_id === 7
                                  ? "Loan"
                                  : item.product.status_id === 8
                                  ? "In progress"
                                  : null}
                              </Badge>
                            </td>
                            <td className="cursor-pointer">
                              <img src={arrowRight} alt="right arrow" />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <PaginationNumber pageNumber={data.data.cart.last_page} />
            </>
          ) : (
            <NoData name="Orders" />
          )}
        </div>
      </div>
    </>
  );
}

export default Order;
