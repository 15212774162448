import React from 'react'

function Leases() {
    return (
        <>
            <div className='mt-4 height-300 overflow-y'>
                <p>We offer different kinds of leasing services:</p>
                <h5>Finance Leasing:</h5>
                <p>
                    We finance the purchase of various kinds of equipment for the possession and use of our customers. Here, our target market is made up of salaried individuals, SMEs and Corporate organizations. In return, we accept rental payments made over an agreed period. The payments are structured to meet the cash flows of the customers, thus ensuring a convenient repayment plan. Upon full and final payment, the ownership of the asset is transferred to the customer or any other party nominated by the client.
                </p>
                <p>Primarily, the asset under finance forms the security for the deal, thus a separate collateral is not required. The asset under finance is insured at all times and the customer is open to enjoy other combined protection plans and services within the lease period.</p>
                <p>This type of lease allows the customer to possess and use of the assets from the onset without necessarily paying in full for it. The customer enjoys the ease of a convenient repayment plan thus improving her cash flows and lifestyle.</p>
                <h5>Personal Leasing</h5>
                <p> These are for salaried individuals working in various organizations acceptable to our company.</p>
                <h5>Group Lease (Administration)</h5>
                <p>Salaried Individuals belonging to a group, where the group or a related        party undertakes to administer repayments on behalf of her members.</p>
                <h5>Group Lease (Guaranteed)</h5>
                <p>Salaried individuals belonging to a group, where the group or a related party agrees to guarantee the leases. The Group lease arrangement is a way of providing for staff members of organizations and ultimately contributes to staff satisfaction.</p>
                <h5>Corporate Lease</h5>
                <p>SMEs and corporate organizations.</p>
            </div>
        </>
    )
}

export default Leases
