import React, { useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  saveProductAttributes,
  editProductAttributes,
} from "../../../redux/actions/vendorActions";
import Loader from "../../Reusable/Loader";
import Message from "../../Reusable/Message";

function AddAttributes({
  open,
  onClose,
  productId,
  attributeId,
  attributeColor,
  attributeCondition,
  attributeYear,
}) {
  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const saveAttribute = useSelector((state) => state.saveAttribute);
  const { saveAttributeResponse, error, loading } = saveAttribute;

  const updateAttribute = useSelector((state) => state.updateAttribute);
  const { updatedAttributes, err, load } = updateAttribute;
  console.log(err);
  console.log(updatedAttributes);

  const onSubmit = (data) => {
    data.product_id = productId;
    if (attributeId) {
      dispatch(editProductAttributes(data, attributeId));
    } else dispatch(saveProductAttributes(data));
  };

  useEffect(() => {
    if (
      saveAttributeResponse !== undefined &&
      saveAttributeResponse.length !== 0
    ) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAttributes]);

  useEffect(() => {
    if (updatedAttributes !== undefined && updatedAttributes.length !== 0) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAttributes]);

  if (!open) return null;
  return (
    <>
      <Modal show={open} onHide={onClose} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {attributeId
              ? "Update Product Attribute"
              : "Add Product Attributes"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-0">
          <Form className="w-100" onSubmit={handleSubmit(onSubmit)}>
            {saveAttributeResponse !== undefined &&
            saveAttributeResponse.length !== 0 ? (
              <Message variant={"success"}>
                {"Attributes added successfully!"}
              </Message>
            ) : null}
            {error ? (
              <Message variant={"danger"}>
                {"Error adding attributes, please try again!"}
              </Message>
            ) : null}
            {updatedAttributes !== undefined &&
            updatedAttributes.length !== 0 ? (
              <Message variant={"success"}>
                {"Attributes updated successfully!"}
              </Message>
            ) : null}
            {err ? (
              <Message variant={"danger"}>
                {"Error updating attributes, please try again!"}
              </Message>
            ) : null}
            <Row className="bg-white m-auto">
              <Col xs={12} className="py-2">
                <Form.Group className="mb-3" controlId="color">
                  <Form.Label>
                    Color of product <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    {...register("color", { required: "true" })}
                    type="text"
                    name="color"
                    defaultValue={attributeColor}
                    className="bg-light-grey"
                    placeholder="e.g Red, Green, Pink"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="condition">
                  <Form.Label>
                    Condition <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    {...register("condition", { required: "true" })}
                    type="text"
                    name="condition"
                    defaultValue={attributeCondition}
                    className="bg-light-grey"
                    placeholder="e.g New, Pre-owned"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="year_of_make">
                  <Form.Label>
                    Year of make <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    {...register("year_of_make", { required: "true" })}
                    type="text"
                    name="year_of_make"
                    defaultValue={attributeYear}
                    className="bg-light-grey"
                    placeholder="e.g 2018"
                  />
                </Form.Group>
                <Form.Group className="float-right">
                  <Button variant="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  {attributeId !== null ? (
                    <Button type="submit" variant="primary" className="ml-2">
                      {load ? <Loader /> : "Update"}
                    </Button>
                  ) : (
                    <Button type="submit" variant="primary" className="ml-2">
                      {loading ? <Loader /> : "Save"}
                    </Button>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddAttributes;
