import React, { useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { Row } from "react-bootstrap";
import Breadcrumbs from "../Components/Reusable/Breadcrumb";
import { Container } from "react-bootstrap";
import Header from "../Components/Reusable/Header";
import Footer from "../Components/Reusable/Footer";
import Newsletter from "../Components/Reusable/Newsletter";
import NewPagination from "../Components/Reusable/Pagination/NewPagination";
import { useDispatch, useSelector } from "react-redux";
import { getProductsBySubCategory } from "../redux/actions/productActions";
import Product from "../Components/Reusable/Product";
import Loader from "../Components/Reusable/Loader";

const ProductList = () => {
  const location = useLocation();
  const pageNumberLimit = 5;
  const [page, setPage] = useState(1);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);

  const subcategoryid = location.state.data.id;

  const dispatch = useDispatch();

  const getproducts = useSelector((state) => state.getProducts);
  const { products, loading } = getproducts;

  const onPageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const onPrevClick = () => {
    if ((page - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    setPage((prev) => prev - 1);
  };

  const onNextClick = () => {
    if (page + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setPage((prev) => prev + 1);
  };

  const paginationAttributes = {
    page,
    maxPageLimit,
    minPageLimit,
    total: Math.ceil(
      products?.products?.meta.total / products?.products?.meta.per_page
    ),
  };

  useEffect(() => {
    dispatch(getProductsBySubCategory(subcategoryid, page));
  }, [dispatch, subcategoryid, page]);

  const active = products?.products?.data?.filter(
    (status) => status.status_id !== 2 && status.status_id
  );

  if (location.state.data.id === undefined) return <Redirect to="/" />;
  return (
    <>
      <div className="bg-light-grey w-100">
        <Container className="py-3 mb-5" fluid="md">
          <Header />
          {/* <Products products = {location.state}/> */}
          <Breadcrumbs
            subCategory={location.state.category}
            products={location.state.data.name}
            active={location.state.data.name}
          />
          {loading ? (
            <Loader />
          ) : products.length !== 0 ? (
            <>
              <div className="my-5">
                <div className="d-flex justify-content-between ">
                  <h3 className="underline-heading text-bold">
                    {location.state.data.name}
                  </h3>
                </div>
                <Row className="mb-2">
                  {active &&
                    active.map((product) => {
                      return (
                        <Product
                          key={product.id}
                          name={product.name}
                          price={product.amount}
                          like={"favorite_border"}
                          productImage={product?.banners[0]?.url}
                          productInfo={product}
                          productCategory={location.state.category}
                          productSubcategory={location.state.data.name}
                          id={product.id}
                        />
                      );
                    })}
                </Row>
              </div>
            </>
          ) : (
            <p className="mt-4">There are no products for this subcategory</p>
          )}
          {products.length === 0 ? null : (
            <NewPagination
              {...paginationAttributes}
              onPrevClick={onPrevClick}
              onNextClick={onNextClick}
              onPageChange={onPageChange}
            />
          )}
        </Container>
        <Newsletter />
        <Footer />
      </div>
    </>
  );
};

export default ProductList;
