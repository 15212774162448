import dayjs from 'dayjs'
import React from 'react'
import { Badge, Modal, Table } from 'react-bootstrap'

function ShowLeaseHistory({ open, onClose, historyData }) {


    if(!open) return null
    return (
        <>
            <Modal show={open} onHide={onClose} scrollable size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Lease History</Modal.Title>
                </Modal.Header>
                <Modal.Body className='m-0'>
                    <Table responsive='lg' className="table-spacing" style={{ overflowX: 'scroll'}} >
                        <thead>
                            <tr className="">
                                <th className="text-muted">Payment Date</th>
                                <th className="text-muted">Amount</th>
                                <th className="text-muted">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                historyData.map(item =>{
                                    return  <tr key={item.id}>
                                                <td>{dayjs(item.payment_date).format('D/MM/YYYY')}</td>
                                                <td>NGN {item.amount.toLocaleString('en')}</td>
                                                {
                                                        <td>
                                                            <Badge pill className=
                                                                {( item.status_id === 1 ? 'bg-success' : item.status_id === 2 ? 'bg-warning' : item.status_id === 8 ? 'bg-warning text-light-grey' : item.status_id === 4 || 6 ? 'bg-success' : null ) +
                                                                " text-light py-2 px-2"}>
                                                                { 
                                                                    item.status_id === 1
                                                                    ? 'Active'
                                                                    : item.status_id === 2
                                                                    ? 'Unpaid'
                                                                    : item.status_id === 4
                                                                    ? 'Paid'
                                                                    : item.status_id === 6
                                                                    ? 'Completed'
                                                                    : item.status_id === 7
                                                                    ? 'Loan'
                                                                    : item.status_id === 8
                                                                    ? 'In progress'
                                                                    : null
                                                                }
                                                            </Badge>
                                                        </td>
                                                    }
                                            </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ShowLeaseHistory
