import axios from 'axios'
import * as types from '../types'
import { Cookies } from 'react-cookie'
import { baseurl } from '../../Utils/baseurl'

const cookies = new Cookies()
const token = cookies.get('token');


export const getCartItems = () => async (dispatch) => {
    const token = cookies.get('token');
    try {

        dispatch({ type: types.GET_CART_ITEMS_REQUEST })

        const config = { headers: { Authorization: `Bearer ${token}` } }

        const { data } = await axios.get(`${baseurl}/cart`, config)

        dispatch({
            type: types.GET_CART_ITEMS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.GET_CART_ITEMS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const addItemToCart = (product_id, quantity, color) => async (dispatch, getState) => {
    try {
        dispatch({ type: types.ADD_CART_ITEMS_REQUEST })

        const requestHeader = {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
        };

        const { data } = await axios.post(`${baseurl}/cart`, { product_id, quantity, color }, requestHeader)
        dispatch({
            type: types.ADD_CART_ITEMS_SUCCESS, payload: data
        })

    } catch (error) {
        dispatch({
            type: types.ADD_CART_ITEMS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const deleteAllCartItems = (tracking_code) => async (dispatch, getState) => {
    try {
        dispatch({ type: types.REMOVE_ALL_CART_ITEMS_REQUEST })

        const requestHeader = {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
        };

        const { data } = await axios.delete(`${baseurl}/carts/${tracking_code}`, requestHeader)

        dispatch({
            type: types.REMOVE_ALL_CART_ITEMS_SUCCESS, payload: data
        })

    } catch (error) {
        dispatch({
            type: types.REMOVE_ALL_CART_ITEMS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const deleteCartItem = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: types.REMOVE_CART_ITEM_REQUEST })

        const requestHeader = {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
        };

        const { data } = await axios.delete(`${baseurl}/cart/${id}`, requestHeader)
        dispatch({
            type: types.REMOVE_CART_ITEM_SUCCESS, payload: id
        })

    } catch (error) {
        dispatch({
            type: types.REMOVE_CART_ITEM_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const updateCartItem = (id, quantity) => async (dispatch) => {
    try {
        dispatch({ type: types.UPDATE_CART_ITEM_REQUEST })

        const requestHeader = {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
        };

        const { data } = await axios.get(`${baseurl}/cart/${id}/${quantity}`, requestHeader)
        dispatch({
            type: types.UPDATE_CART_ITEM_SUCCESS, payload: data
        })

    } catch (error) {
        dispatch({
            type: types.UPDATE_CART_ITEM_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}