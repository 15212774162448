import React, { useEffect } from "react";
import Chart from "../../Components/Vendor/Overview/Chart";
import { Row, Col } from "react-bootstrap";
import ProductInfoCard from "../../Components/Vendor/Overview/ProductInfoCard";
import RecentTransactionsTable from "../../Components/Vendor/Overview/RecentTransactionsTable";
import RecentUploadsTable from "../../Components/Vendor/Overview/RecentUploadsTable";
import NoData from "../../Components/Vendor/Overview/NoData";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVendorMetrics,
  fetchVendorProducts,
} from "../../redux/actions/vendorActions";
import Loader from "../../Components/Reusable/Loader";

function Overview({ orderData, orderLoading }) {
  const dispatch = useDispatch();

  const getVendorProducts = useSelector((state) => state.getVendorProducts);
  const { loading, data } = getVendorProducts;

  useEffect(() => {
    dispatch(fetchVendorProducts());
  }, [dispatch]);

  const getMonth = (e) => {
    const date = e.target.value.split("-");
    const data = {
      year: parseInt(date[0]),
      month: parseInt(date[1]),
    };
    dispatch(fetchVendorMetrics(data));
  };

  const getVendorMetrics = useSelector((state) => state.vendorMetrics);
  const { metrics } = getVendorMetrics;

  return (
    <>
      <div className="bg-light-grey p-4">
        <Row>
          <Col xs={12} md={7} lg={9}>
            <div className="chart-wrapper height-300 bg-white w-100 rounded-3">
              <Chart onSelect={getMonth} />
            </div>
          </Col>
          <Col xs={12} md={5} lg={3}>
            <ProductInfoCard metrics={metrics} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={8} className="mt-5">
            {orderLoading ? (
              <Loader />
            ) : orderData && orderData.data.products.length === 0 ? (
              <NoData name="Recent Transactions" />
            ) : (
              <RecentTransactionsTable data={orderData} />
            )}
          </Col>
          <Col xs={12} lg={4} className="mt-5">
            {loading ? (
              <Loader />
            ) : data && data.data.data.length === 0 ? (
              <NoData name="Recent Uploads" />
            ) : (
              <RecentUploadsTable
                uploads={data && data.data.data}
                loading={loading}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Overview;
