import React from "react";
import { Pagination } from "react-bootstrap";

function PaginationNumber({ pageNumber }) {
  let active = 1;
  const pageCount = new Array(pageNumber).fill("test");
  //   const handleClick = () => console.log("clicked");

  return (
    <>
      <Pagination className="justify-content-end mt-2">
        <Pagination.Prev />
        {pageCount.map((number, i) => {
          return (
            <Pagination.Item key={i} active={i + 1 === active}>
              {i + 1}
            </Pagination.Item>
          );
        })}
        <Pagination.Next />
      </Pagination>
    </>
  );
}

export default PaginationNumber;
