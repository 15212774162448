import axios from 'axios'
import * as types from '../types'
import { Cookies } from 'react-cookie'
import { baseurl } from '../../Utils/baseurl'

const cookies = new Cookies()

export const updateVendorProfile = (formdata, id) => async (dispatch) => {
    const token = cookies.get('token');
    try {
        dispatch({
            type: types.VENDOR_PROFILE_UPDATE_REQUEST,
        })
        const requestOptions = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        };
        const { data } = await axios.post(`${baseurl}/vendor/${id}`, formdata, requestOptions)

        dispatch({
            type: types.VENDOR_PROFILE_UPDATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.VENDOR_PROFILE_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const fetchVendorProducts = () => async (dispatch) => {
    const token = cookies.get('token');
    try {
        dispatch({
            type: types.VENDOR_FETCH_PRODUCTS_REQUEST,
        })
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };
        const { data } = await axios.get(`${baseurl}/products/vendors/all`, requestOptions)

        dispatch({
            type: types.VENDOR_FETCH_PRODUCTS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.VENDOR_FETCH_PRODUCTS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const createVendorProduct = (formdata) => async (dispatch) => {
    const token = cookies.get('token');
    try {
        dispatch({
            type: types.VENDOR_CREATE_PRODUCT_REQUEST,
        })
        const requestOptions = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        };
        const { data } = await axios.post(`${baseurl}/product`, formdata, requestOptions)

        dispatch({
            type: types.VENDOR_CREATE_PRODUCT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.VENDOR_CREATE_PRODUCT_FAIL,
            payload: error.response && error.response.data.errors.quantity[0]
                ? error.response.data.errors.quantity[0]
                : error.response.data.message,
        })
    }
}
export const deleteVendorProduct = (id) => async (dispatch) => {
    const token = cookies.get('token');
    try {
        dispatch({
            type: types.VENDOR_DELETE_PRODUCT_REQUEST,
        })
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };
        const { data } = await axios.delete(`${baseurl}/product/${id}`, requestOptions)

        dispatch({
            type: types.VENDOR_DELETE_PRODUCT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.VENDOR_DELETE_PRODUCT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const fetchVendorOrders = (id) => async (dispatch) => {
    const token = cookies.get('token');
    try {
        dispatch({
            type: types.VENDOR_FETCH_ORDERS_REQUEST,
        })
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };
        const { data } = await axios.get(`${baseurl}/orders/vendors/products/${id}`, requestOptions)

        dispatch({
            type: types.VENDOR_FETCH_ORDERS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.VENDOR_FETCH_ORDERS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const fetchVendorMetrics = (date) => async (dispatch) => {
    const token = cookies.get('token');
    try {
        dispatch({
            type: types.VENDOR_FETCH_METRICS_REQUEST,
        })
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };
        const { data } = await axios.post(`${baseurl}/products/vendors/metrics`, date, requestOptions)

        dispatch({
            type: types.VENDOR_FETCH_METRICS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.VENDOR_FETCH_METRICS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const saveProductAttributes = (attributedata) => async (dispatch) => {
    const token = cookies.get('token');
    try {
        dispatch({
            type: types.SAVE_PRODUCT_ATTRIBUTE_REQUEST,
        })
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };
        const { data } = await axios.post(`${baseurl}/attributes/products`, attributedata, requestOptions)

        dispatch({
            type: types.SAVE_PRODUCT_ATTRIBUTE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.SAVE_PRODUCT_ATTRIBUTE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}
export const editProductAttributes = (attributedata, attributeId) => async (dispatch) => {
    const token = cookies.get('token');
    try {
        dispatch({
            type: types.EDIT_PRODUCT_ATTRIBUTE_REQUEST,
        })
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };
        const { data } = await axios.patch(`${baseurl}/attribute/products/${attributeId}`, attributedata, requestOptions)

        dispatch({
            type: types.EDIT_PRODUCT_ATTRIBUTE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: types.EDIT_PRODUCT_ATTRIBUTE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}