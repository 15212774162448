import React from "react";
import image from "../../Assets/suv.png";
import { Link, useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

const Product = ({
  name,
  price,
  productImage,
  productInfo,
  productCategory,
  productSubcategory,
  id,
}) => {
  const history = useHistory();

  //OPEN PRODUCT DETAILS PAGE
  const showProductDetails = (id) => {
    history.push(`/product/${id}`);
  };

  return (
    <div className="">
      <Col
        xs={12}
        md={4}
        lg={6}
        style={{ margin: "0 auto" }}
        className="mw-360  mt-4 cursor-pointer"
      >
        <Link
          to={{
            pathname: `/product/${id}`,
            state: {
              data: productInfo,
              category: productCategory,
              subcategory: productSubcategory,
            },
          }}
        >
          <div className="bg-white p-5">
            <img
              src={productImage || image}
              className="w-100 height-150 flex-fill"
              alt=""
            />
          </div>
        </Link>
        <Row className="mt-3 d-flex justify-content-between">
          <Col xs={8} className="w-50">
            <p className="">{name}</p>
            <p className="text-blue">
              <span className="text-dark-grey">Price:&nbsp;</span>
              &#8358; {price.toLocaleString("en")} <br />
              <span className="text-dark-grey">
                As low as{" "}
                <span className="text-blue">
                  &#8358;{" "}
                  {price <= 2000000
                    ? Math.round(parseInt(price) / 13).toLocaleString("en")
                    : price > 2000000 && price <= 3500000
                    ? Math.round(parseInt(price) / 18).toLocaleString("en")
                    : price > 3500000 && price <= 15000000
                    ? Math.round(parseInt(price) / 21.5).toLocaleString("en")
                    : price > 15000000
                    ? Math.round(parseInt(price) / 27).toLocaleString("en")
                    : null}
                </span>{" "}
                /month
              </span>
            </p>
          </Col>
          <Col xs={4} className="text-left">
            <button className="border-0  my-2 p-2">
              <Link
                className="text-decoration-none text-white"
                to="#"
                onClick={() => showProductDetails(id)}
              >
                Purchase
              </Link>
            </button>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default Product;
