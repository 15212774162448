import * as types from '../types'

export const getCategoriesReducer = (state = { categories: [] }, action) => {
    switch (action.type) {
        case types.PRODUCT_CATEGORIES_REQUEST:
            return {
                loading: true, categories: []
            }
        case types.PRODUCT_CATEGORIES_SUCCESS:
            return {
                loading: false, categories: action.payload.data
            }
        case types.PRODUCT_CATEGORIES_FAIL:
            return {
                loading: false, error: action.payload
            }

        default:
            return state;
    }
}

export const getSubCategoriesReducer = (state = { subcategories: [] }, action) => {
    switch (action.type) {
        case types.PRODUCT_SUBCATEGORIES_REQUEST:
            return {
                loading: true, subcategories: []
            }
        case types.PRODUCT_SUBCATEGORIES_SUCCESS:
            return {
                loading: false, subcategories: action.payload.data
            }
        case types.PRODUCT_SUBCATEGORIES_FAIL:
            return {
                loading: false, error: action.payload
            }

        default:
            return state;
    }
}

export const getProductsBySubCategoriesReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case types.PRODUCTS_BY_SUBCATEGORIES_REQUEST:
            return {
                loading: true, products: []
            }
        case types.PRODUCTS_BY_SUBCATEGORIES_SUCCESS:
            return {
                loading: false, products: action.payload.data
            }
        case types.PRODUCTS_BY_SUBCATEGORIES_FAIL:
            return {
                loading: false, error: action.payload
            }

        default:
            return state;
    }
}

export const getProductsByTagReducer = (state = { productsbytags: [] }, action) => {
    switch (action.type) {
        case types.PRODUCTS_BY_TAGS_REQUEST:
            return {
                loading: true, productsbytags: []
            }
        case types.PRODUCTS_BY_TAGS_SUCCESS:
            return {
                loading: false, productsbytags: action.payload
            }
        case types.PRODUCTS_BY_TAGS_FAIL:
            return {
                loading: false, error: action.payload
            }

        default:
            return state;
    }
}

export const getProductsByIdReducer = (state = { productsbyid: [] }, action) => {
    switch (action.type) {
        case types.PRODUCTS_BY_ID_REQUEST:
            return {
                loading: true, productsbyid: []
            }
        case types.PRODUCTS_BY_ID_SUCCESS:
            return {
                loading: false, productsbyid: action.payload
            }
        case types.PRODUCTS_BY_ID_FAIL:
            return {
                loading: false, productError: action.payload
            }
        default:
            return state;
    }
}
export const getSearchProductReducer = (state = { searchedResult: [] }, action) => {
    switch (action.type) {
        case types.SEARCH_PRODUCT_REQUEST:
            return {
                loading: true, searchedResult: []
            }
        case types.SEARCH_PRODUCT_SUCCESS:
            return {
                loading: false, searchedResult: action.payload
            }
        case types.SEARCH_PRODUCT_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}

export const getAttributeReducer = (state = { attribute: [] }, action) => {
    switch (action.type) {
        case types.PRODUCT_ATTRIBUTE_REQUEST:
            return {
                loading: true, attribute: []
            }
        case types.PRODUCT_ATTRIBUTE_SUCCESS:
            return {
                loading: false, attribute: action.payload.data
            }
        case types.PRODUCT_ATTRIBUTE_FAIL:
            return {
                loading: false, error: action.payload
            }

        default:
            return state;
    }
}

export const shippingInfoReducer = (state = { shipping: [] }, action) => {
    switch (action.type) {
        case types.SHIPPING_INFO_REQUEST:
            return {
                loading: true, shipping: []
            }
        case types.SHIPPING_INFO_SUCCESS:
            return {
                loading: false, shipping: action.payload
            }
        case types.SHIPPING_INFO_FAIL:
            return {
                loading: false, error: action.payload
            }

        default:
            return state;
    }
}

export const getBrandsReducer = (state = { brands: [] }, action) => {
    switch (action.type) {
        case types.FETCH_BRANDS_REQUEST:
            return {
                loading: true, brands: []
            }
        case types.FETCH_BRANDS_SUCCESS:
            return {
                loading: false, brands: action.payload
            }
        case types.FETCH_BRANDS_FAIL:
            return {
                loading: false, error: action.payload
            }

        default:
            return state;
    }
}

export const outrightPaymentReducer = (state = {}, action) => {
    switch (action.type) {
        case types.OUTRIGHT_PAYMENT_REQUEST:
            return {
                load: true
            }
        case types.OUTRIGHT_PAYMENT_SUCCESS:
            return {
                load: false, payment: action.payload
            }
        case types.OUTRIGHT_PAYMENT_FAIL:
            return {
                load: false, error: action.payload
            }
        case types.LEASEHOUZ_PAYMENT_REQUEST:
            return {
                loading: true
            }
        case types.LEASEHOUZ_PAYMENT_SUCCESS:
            return {
                loading: false, payment: action.payload
            }
        case types.LEASEHOUZ_PAYMENT_FAIL:
            return {
                loading: false, isError: action.payload
            }
        default:
            return state;
    }
}

export const processLeasehouzPaymentReducer = (state = {}, action) => {
    switch (action.type) {
        case types.PROCESS_LEASEHOUZ_PAYMENT_REQUEST:
            return {
                loading: true
            }
        case types.PROCESS_LEASEHOUZ_PAYMENT_SUCCESS:
            return {
                loading: false, processedpayment: action.payload
            }
        case types.PROCESS_LEASEHOUZ_PAYMENT_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}
export const paymentChannelReducer = (state = { channel: [] }, action) => {
    switch (action.type) {
        case types.PAYMENT_CHANNEL_REQUEST:
            return {
                loading: true
            }
        case types.PAYMENT_CHANNEL_SUCCESS:
            return {
                loading: false, channel: action.payload.data
            }
        case types.PAYMENT_CHANNEL_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}
export const verifyPaymentReducer = (state = { verified: [] }, action) => {
    switch (action.type) {
        case types.VERIFY_PAYMENT_REQUEST:
            return {
                loading: true
            }
        case types.VERIFY_PAYMENT_SUCCESS:
            return {
                loading: false, verified: action.payload.data
            }
        case types.VERIFY_PAYMENT_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}
export const fetchUserOrdersReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case types.USER_FETCH_ORDERS_REQUEST:
            return {
                loading: true
            }
        case types.USER_FETCH_ORDERS_SUCCESS:
            return {
                loading: false, orders: action.payload.data
            }
        case types.USER_FETCH_ORDERS_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}
export const fetchUserOrdersByTrackingCodeReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case types.ORDERS_BY_TRACKING_CODE_REQUEST:
            return {
                loading: true
            }
        case types.ORDERS_BY_TRACKING_CODE_SUCCESS:
            return {
                loading: false, products: action.payload.data
            }
        case types.ORDERS_BY_TRACKING_CODE_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}
// export const subCategoriesReducer = (state = {subcategories: [] }, action) => {
//     switch(action.type) {
//         case types.FETCH_SUBCATEGORIES_REQUEST:
//             return {
//                 loading: true
//             }
//         case types.FETCH_SUBCATEGORIES_SUCCESS:
//             return {
//                 loading: false, subcategories: action.payload.data
//             }
//         case types.FETCH_SUBCATEGORIES_FAIL:
//             return {
//                 loading: false, error: action.payload
//             }
//         default:
//             return state;
//     }
// }