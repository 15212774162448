import React, {useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

function FileUpload({onChange}) {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*",
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const removeImage = file => () => {
    const newImage = [...files]
    newImage.splice(newImage.indexOf(file), 1)
    setFiles(newImage)
  }

  const thumbs = files.map(file => (
    <div className="thumbnail-div" key={file.name}>
      <div className="d-flex mx-3">
        <img src={file.preview} className="thumbnail-img"  alt=""/>
        <span onClick={removeImage(file)} className="material-icons text-bold text-dark cursor-pointer">close</span>
      </div>
    </div>
  ));

  useEffect(() => {
    onChange(files[0])
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    }
  }, [files, onChange])
  return (
    <>
      <div className="bg-light-grey height-auto">
        <div className="d-flex flex-column justify-content-center py-3">
          <div {...getRootProps()} className="cursor-pointer my-3 p-4 border-dashed align-self-center" onClick={open}>
            <input {...getInputProps()} />
            <div className="text-center">
              <span className="material-icons center text-muted width-20">upload</span>
            </div>
            <p className="text-muted">click to upload Image</p>
          </div>
          <div className="align-self-center">
            {thumbs}
          </div>
        </div>
      </div>
    </>
  );
}

export default FileUpload;
