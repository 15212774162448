import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Header from "../Components/Reusable/Header";
import Footer from "../Components/Reusable/Footer";
import Newsletter from "../Components/Reusable/Newsletter";
// import PaginationNumber from "../Components/Reusable/Pagination";
import { Pagination } from "../Components/Reusable/Pagination/Pagination";
import { useSelector } from "react-redux";
import Product from "../Components/Reusable/Product";
import Loader from "../Components/Reusable/Loader";

const SearchResult = () => {
  const location = useLocation();
  const term = location.search;
  const searchTerm = term.split("=")[1];

  const [page, setPage] = useState(1);
  const handlePages = (updatePage) => setPage(updatePage);

  const getSearchResult = useSelector((state) => state.fetchSearchResult);
  const { searchedResult, loading } = getSearchResult;

  const active = searchedResult?.data?.data?.filter(
    (status) => status.status_id !== 2 && status.status_id
  );

  let Total = searchedResult?.data?.total;
  let TotalPerPage = searchedResult?.data?.per_page;
  const totalPages = Math.ceil(Total / TotalPerPage);

  console.log(searchedResult);

  return (
    <>
      <div className="bg-light-grey w-100">
        <Container className="py-3 mb-5" fluid="md">
          <Header page={page} searchTerm={searchTerm} />
          {searchedResult?.data?.data.length === 0 ? (
            <h3 className="my-5 text-bold text-center">
              There are no products matching your search, please try again
            </h3>
          ) : loading ? (
            <Loader />
          ) : searchedResult?.data?.data.length !== 0 ? (
            <>
              <div className="my-5">
                <div className="d-flex justify-content-between ">
                  <h3 className="underline-heading text-bold">
                    Here are the search result(s) for {searchTerm}{" "}
                  </h3>
                </div>
                <Row className="mb-2">
                  {active &&
                    active?.map((product) => {
                      return (
                        <Product
                          key={product.id}
                          name={product.name}
                          price={product.amount}
                          like={"favorite_border"}
                          productImage={product.url}
                          productInfo={product}
                          id={product.product_id}
                        />
                      );
                    })}
                </Row>
              </div>
            </>
          ) : null}
          {searchedResult?.data?.data.length === 0 ? null : (
            // <PaginationNumber pageNumber={searchedResult?.data?.last_page} />
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </Container>
        <Newsletter />
        <Footer />
      </div>
    </>
  );
};

export default SearchResult;
