import React, { useEffect, useState } from "react";
import { Col, Badge, Table } from "react-bootstrap";
import arrowRight from "../Assets/arrow-right.png";
import { useDispatch } from "react-redux";
import { fetchUserOrders } from "../redux/actions/productActions";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import NoData from "../Components/Vendor/Overview/NoData";
import Loader from "../Components/Reusable/Loader";
import TransactionInfoModal from "./TransactionInfoModal";

function Transactions() {
  const [openModal, setopenModal] = useState(false);
  const [code, setcode] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserOrders());
  }, [dispatch]);

  const userOrders = useSelector((state) => state.userOrders);
  const { orders, loading } = userOrders;

  const showTransactionDetails = (code) => {
    setopenModal(true);
    setcode(code);
  };

  return (
    <>
      <TransactionInfoModal
        code={code}
        open={openModal}
        onClose={() => setopenModal(false)}
      />
      {loading ? (
        <Loader />
      ) : orders?.orders && orders?.orders?.data?.length ? (
        <>
          <div className="bg-white m-auto">
            <Col xs={12} md={12} lg={12} className="">
              <Table responsive="lg" className="table-spacing">
                <thead>
                  <tr className="">
                    <th className="text-muted">Date</th>
                    <th className="text-muted">Payment</th>
                    <th className="text-muted">Amount</th>
                    <th className="text-muted">Shipping</th>
                    <th className="text-muted">Status</th>
                    <th className="text-muted"></th>
                  </tr>
                </thead>
                <tbody>
                  {orders &&
                    orders.orders &&
                    orders.orders.data.map((item) => (
                      <tr key={item.id}>
                        <td>{dayjs(item.created_at).format("D/MM/YYYY")}</td>
                        <td>
                          {item.is_loan === 0 ? "Instant" : "Installment"}
                        </td>
                        <td>{item.amount.toLocaleString("en")}</td>
                        <td>{item.shipping_fee.toLocaleString("en")}</td>
                        {
                          <td>
                            <Badge
                              pill
                              className="bg-success text-light py-2 px-2"
                            >
                              {item.status_id === 1
                                ? "Active"
                                : item.status_id === 4
                                ? "Paid"
                                : item.status_id === 6
                                ? "Completed"
                                : item.status_id === 7
                                ? "Loan"
                                : null}
                            </Badge>
                          </td>
                        }
                        <td className="cursor-pointer">
                          <img
                            src={arrowRight}
                            onClick={() =>
                              showTransactionDetails(item.tracking_code)
                            }
                            alt="right arrow"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </div>
        </>
      ) : (
        <NoData name="Orders" />
      )}
    </>
  );
}

export default Transactions;
