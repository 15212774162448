import React from 'react'

function FinancialIntermediary() {
    return (
        <>
            <div className="mt-4 height-300 overflow-y">
                <p>We offer various other financial services to the market, working with carefully selected partners. These include:</p>
                <h5>General Insurance</h5>
                <p>We ensure the provision of comprehensive insurance on various kinds of assets. Aside the required insurance on lease assets from us, we are delighted to handle your requests to purchase various insurance products offered on our platform. We are in relationship with the top insurers in Nigeria providing the required service. Talk to us for your car insurance, plant and machinery cover, life, job loss and other protection plans that you desire.</p>
                <h5>Medical Insurance</h5>
                <p>In strategic alliances with top medical health providers, we provide attractive medical insurance products on sale to individuals, SMEs and corporate clients. Get in touch with us and we can provide this and other services independently or in addition to other products as a bundled service.</p>
                <p>Most businesses are unable to meet their set targets. They are either rejected by financiers and other institutions, unaware of products and services that can improve their performance, not privy to systems and structures that can take delivery of their set goals and eventually make them very attractive to financiers and investors. At AssetKap, we are ready to work with you to  solve these problems.</p>
                
            </div> 
        </>
    )
}

export default FinancialIntermediary
