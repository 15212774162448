export const bio = [
    {
        id: 1,
        details: [
            'An asset finance expert, he has varied experience in equipment leasing, asset management, funds management, structured trade finance, project finance, credit administration, private equity and investments.',
            'With about 2 decades of working experience, he has led different successful startup operations in asset finance. He was a pioneer staff of the Vehicle and Asset Finance (VAF) team of Stanbic IBTC Bank Plc.(a member of the Standard Bank Group). He joined the bank in 2007 and rose to become the Country Head of the VAF team represented across Standard bank locations globally. The portfolio grew to become the leading vehicle and asset finance business in Nigeria.Prior to this time, he had worked at the legacy Habib Bank group and two other leasing companies namely CornerstoneLeasing and Enterprise Leasing.He has managed an average portfolio size in excess of NGN30bn.',
            'He has been involved in various local and international training programs and exposed to international best practice. He is a membe of the Equipment Leasing Association of Nigeria (ELAN).',
            'Key achievements would include his involvement in several successful startup operations of leasing companies and the leasing department of the bank. A key resource in asset finance, he was involved in the development, set up and running of different entities and schemes. He was part of the team that established Caterpillar finance (Catfin) in Nigeria. The Coscharis auto finance scheme that was powered by Stanbic IBTC bank and Access bank and the John Deere Financial collaboration with TATA and Stanbic IBTC bank are other examples. He led the VAF team of Stanbic IBTC bank to win several awards (back-to-back) as the best auto financing bank in Nigeria.'
        ]
    },
    {
        id: 2,
        details: [
            'With over 10 (ten) years working experience, Tosin is hands-on in equipment leasing, creditadministration, and fleet management. He has worked at Aquila Leasing, where he handled various lease related transactions. Under fleet management, he has provided services to a number of multinationals and large corporate organizations namely Airtel, Ericsson, Stanbic IBTC Bank, Access Bank, Union Bank, Nigerian Bottling Company, Huawei, Seven up Bottling Company, IHS and Wheat baker Hotel.',
            'He started out his leasing career at the erstwhile Cornerstone Leasing (a member of the Cornerstone group) where he was actively involved in growing the lease portfolio. He has also handled various other credit related deals.',
            'Over the years, he has worked with top industry personnel and has gained valuable experience in financial services. His experience in leasing and credit administration is of immense benefit to the company.'
        ]
    },
    {
        id: 3,
        details: [
            'With a B.Sc. Degree in Microbiology from the University if Calabar, Martina has worked in different organisations. Over the last 7 years, she has developed herself and gained valuable experience in financial services, operations, and client care.',
            'She started out as a marketing executive working in Fema microfinance bank, where she conducted market research on consumer behaviour, planned market initiatives and leveraged on available networks to promote business development. She also worked at Airtel Nigeria as a sales executive. Here, she sold all company products to both existing and new customers. She joined Coolbuck Limited, an online lending platform that provides unsecured loans, via a team of local and international professionals using a Fintech platform. Here, she was involved in the direct sales of company products.',
            'Our Consultant, Treasury, Martina works with other team members to always maintain corporate liquidity and financial stability within the business. She engages with existing and potential investors and funding partners to constantly generate enough funding.',
            'She is saddled with the responsibility to develop funding channels, ensuring that there is enough funding/ cash flow to embark on deals and to meet other company’s obligations. Aside the management of the portfolio, she works to support the growth of the same.'
        ]
    },
    {
        id: 4,
        details: [
            'With a BSc. Degree in Accounting from Joseph Ayo Babalola University, Ikeji -Arakeji, Osun state, Ore has worked in several organisations. These include Chupet Pharmaceuticals, Lagos, Drillpet International Limited, Port Harcourt and ETL Engineering Services, Lagos.',
            'Over the years, she has executed accounting transactions posting daily company sales records, issuing and posting payments, reconciliations, cash management, payroll management, other statutory payments, bank account management and reporting.',
            'Ore supports the rest of the team, ensuring that our clients are DELIGHTED at all times. She oversees the process forexecuting all transactions adequately, recording all deals on our system, handling collections, budgeting, management accounts, audit management, our cash management process, payroll management and other related accounting services.',
            'Her background in accounting, combined with her work experience is valuable to our team.'
        ]
    },
    {
        id: 5,
        details: [
            'Saheed Oladiran is a fellow of the Institute of Chartered Accountants of Nigeria (ICAN) and an associate of Investment Advisers and Portfolio Managers (AIAPM). Saheed has over Twenty (20) years working experience in various areas of accounting and finance such as financial reporting, debt management and collections, employees’ compensation, reconciliation, credit intermediation, restructuring and strategic tax management in the financial services sector.',
            'He started as an audit trainee working with Delloitte and later moved to Jeruti Industrial Services Limited. He has also worked with Greenwich Trust Limited as Chief Financial Officer, the erstwhile Cornerstone Leasing & Investments Limited as Head, Finance & Investments as well as Capital Bancorp Plc. as an accountant.',
            'He is a well-trained accountant and an asset to the team.'
        ]
    }
]