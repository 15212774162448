import React from 'react'
import './CSS/main.css'
import 'tippy.js/dist/tippy.css';
import { Container } from 'react-bootstrap'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Marketplace from './Pages/Marketplace'
import Login from './Components/Onboarding/Login'
import Register from './Components/Onboarding/Register'
import Verification from './Components/Onboarding/Verification'
import ForgotPassword from './Components/Onboarding/ForgotPassword'
import ResetPassword from './Components/Onboarding/ResetPassword'
import ResetEmailModal from './Components/Onboarding/ResetEmailModal'
import ProductList from './Pages/ProductLists'
import ProductDetails from './Pages/ProductDetails'
import SearchResult from './Pages/SearchResult'
import InstantPayment from './Pages/InstantPayment'
import TermsAndConditions from './Pages/TermsAndConditions'
import Routes from './Pages/Vendor/Routes'
import UserRoutes from './Pages/UserRoutes'
import paymentSuccess from './Components/Payments/Instant/PaymentSuccess'
import IndividualLeaseApplication from './Components/Payments/Lease/Personal/IndividualLeaseApplication'
import CompanyLeaseApplication from './Components/Payments/Lease/Corporate/CompanyLeaseApplication'
import AboutUs from './Pages/AboutUs'
import OurServices from './Pages/OurServices'
import OurPartners from './Pages/OurPartners'
import ContactUs from './Pages/ContactUs'
import SubCategories from './Components/Home/SubCategories';
// import './App.css';

const App = () => {

  return (
    <Router>
      {/* <Switch> */}
      <Route path='/' component={Marketplace} exact />
      <Container className='themed-container'>
        <Route path='/login' component={Login} />
        <Route path='/register' component={Register} />
        <Route path='/verify' component={Verification} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/email-sent' component={ResetEmailModal} />
        <Route path='/reset-password/:token' component={ResetPassword} />
      </Container>
      <Route path='/category/:name' component={SubCategories} />
      <Route path='/subcategory/:products' component={ProductList} />
      <Route path='/product/:id' component={ProductDetails} />
      <Route path='/products' component={SearchResult} />
      <Route path='/payment/instant/' component={InstantPayment} />
      <Route path='/lease/individual/' component={IndividualLeaseApplication} />
      <Route path='/lease/company/' component={CompanyLeaseApplication} />
      <Route path='/dashboard' component={Routes} />
      <Route path='/user' component={UserRoutes} />
      <Route path='/about' component={AboutUs} />
      <Route path='/services' component={OurServices} />
      <Route path='/partners' component={OurPartners} />
      <Route path='/contact' component={ContactUs} />
      {/* <Route path='/user/transactions' component={Transactions}/>  */}
      <Route path='/success' component={paymentSuccess} />
      <Route path='/terms' component={TermsAndConditions} />
      {/* </Switch> */}
    </Router>
  );
}

export default App;
