import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { useHistory } from 'react-router-dom'

function Breadcrumbs(props) {
    
    const history = useHistory()

    return (
        <>
            <Breadcrumb className='my-2'>
                {
                    props.category && <Breadcrumb.Item href='/' >Category</Breadcrumb.Item>
                }
                {
                    props.subCategory ? <Breadcrumb.Item className='underline-heading' onClick={() => { history.goBack(1) }} active={props.subCategory === props.active}> {props.subCategory} </Breadcrumb.Item>
                    : null
                }
                {
                    props.products ? <Breadcrumb.Item onClick={() => { history.goBack(1) }} active={props.products === props.active}>{props.products}</Breadcrumb.Item>
                    : null
                }
                {
                    props.product ? <Breadcrumb.Item  active={props.product === props.active}>{props.product}</Breadcrumb.Item> : null
                }
                
            </Breadcrumb>
        </>
    )
}

export default Breadcrumbs
