import React from "react";
import { Modal } from "react-bootstrap";
// import linkedin from "../../Assets/linkedin.png";
// import facebook from "../../Assets/facebook.png";
import { bio } from "./TeamBioData";

function TeamInfoModal({ open, onClose, teamInfo }) {
  if (!open) return null;
  return (
    <>
      <Modal
        show={open}
        scrollable="true"
        onHide={onClose}
        centered
        animation={true}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{teamInfo.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="w-auto">
          <div className="width-150 height-150 rounded-full mx-auto">
            <img
              src={teamInfo.image}
              alt=""
              className="cursor-pointer rounded-full position-relative m-auto block object-fit-cover"
              style={{ width: "100%", height: "100%" }}
            />
          </div>

          <h4 className="text-center text-bold mt-4">
            {teamInfo.id === 1
              ? "Chief Executive Officer (CEO)"
              : teamInfo.id === 5
              ? "Head, Finance Advisory Services"
              : teamInfo.position}
          </h4>
          <div className="mt-4">
            {bio.map((data) => {
              return data.details.map((detail) => {
                return data.id === teamInfo.id && <p key={data.id}>{detail}</p>;
              });
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <div className="w-100 mt-4 d-flex align-items-center">
            <h6 className="mx-4">Connect with {teamInfo.name}</h6>
            <img
              src={linkedin}
              alt=""
              className=" width-40 cursor-pointer"
              onClick={() => window.open(teamInfo.linkedin, "_blank")}
            />
            <img
              src={facebook}
              alt=""
              className=" width-40 mx-3 cursor-pointer"
            />
          </div> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TeamInfoModal;
