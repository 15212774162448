import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  allSubcategories,
  clearAllSubcategories,
  getProductsBySubCategory,
} from "../../redux/actions/productActions";
import Breadcrumbs from "../Reusable/Breadcrumb";
import Footer from "../Reusable/Footer";
import Header from "../Reusable/Header";
import Loader from "../Reusable/Loader";
import Newsletter from "../Reusable/Newsletter";
import Product from "../Reusable/Product";

function SubCategories() {
  const [filteredData, setFilteredData] = useState();
  const [loading, setloading] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const split = location.pathname.split("/");
  const { sub_categories } = location?.state?.data;
  const productsData = useSelector((state) => state.getProducts);
  const { products } = productsData;
  console.log(sub_categories);

  console.log(allSubcategories);
  const filtered = {};
  const finalFiltered = [];
  const filterProducts = () => {
    for (let i = 0; i < sub_categories.length; i++) {
      for (let j = 0; j < allSubcategories.length; j++) {
        if (
          allSubcategories[j].length === 0 &&
          !(sub_categories[i].name in filtered)
        ) {
          //if(sub_categories[i].id === allSubcategories[j][0].product_sub_category_id){
          let filteredtitle = (filtered[sub_categories[i].name] =
            sub_categories[i]);
          let filteredsubtitle = (filtered[sub_categories[i].name].products =
            []);
          finalFiltered.push(filteredtitle);
          //}
        } else if (
          allSubcategories[j].length !== 0 &&
          !(sub_categories[i].name in filtered)
        ) {
          if (
            sub_categories[i].id ===
            allSubcategories[j][0]?.product_sub_category_id
          ) {
            let filteredtitle = (filtered[sub_categories[i].name] =
              sub_categories[i]);
            let filteredsubtitle = (filtered[sub_categories[i].name].products =
              allSubcategories[j]);
            finalFiltered.push(filteredtitle);
          }
        } else if (
          allSubcategories[j].length !== 0 &&
          sub_categories[i].name in filtered
        ) {
          if (
            sub_categories[i].id ===
            allSubcategories[j][0].product_sub_category_id
          ) {
            filtered[sub_categories[i].name].products = allSubcategories[j];
          }
        }
      }
    }
    setloading(false);
    dispatch(clearAllSubcategories());
  };

  const fetchProducts = async () => {
    setloading(true);
    for (let subcategory of sub_categories) {
      dispatch(getProductsBySubCategory(subcategory.id));
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [allSubcategories, location]);

  useEffect(() => {
    if (allSubcategories.length === sub_categories.length) {
      filterProducts();
    }
    setFilteredData(finalFiltered);
  }, [products, location]);

  const extraStyle = {
    padding: "200px 0",
  };

  return (
    <>
      <div className="bg-light-grey w-100">
        <Container className="py-3 mb-5" fluid="md">
          <Header />
          <Breadcrumbs
            subCategory={"Product Categories"}
            products={split[2]}
            active={split[2]}
          />
          <div>
            {loading ? (
              <Loader extraStyle={extraStyle} />
            ) : (
              filteredData?.map((subcategory, index) => {
                return (
                  <div key={index} className="my-5">
                    <div className="d-flex justify-content-between ">
                      <h5 className="underline-heading">{subcategory.name}</h5>
                      {subcategory?.products.length > 0 && (
                        <Link
                          className="text-decoration-none"
                          to={{
                            pathname: `/subcategory/${subcategory.name}`,
                            state: { data: subcategory, category: split[2] },
                          }}
                        >
                          <div className="d-flex text-blue cursor-pointer mt-1">
                            <p className="text-blue">View more</p>
                            <span className="material-icons">
                              arrow_forward
                            </span>
                          </div>
                        </Link>
                      )}
                    </div>
                    {subcategory?.products.length ? (
                      <Row className="mb-2">
                        {subcategory?.products?.map((product, i) => {
                          return (
                            <Product
                              key={product.id}
                              name={product.name}
                              price={product.amount}
                              like={"favorite_border"}
                              id={product.id}
                              //productInfo = {productsbytags.data}
                              productImage={product.banners[0]?.url}
                            />
                          );
                        })}
                      </Row>
                    ) : (
                      <p>There are no products in this subcategory</p>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </Container>
      </div>
      <Newsletter />
      <Footer />
    </>
  );
}

export default SubCategories;
