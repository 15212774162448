import React, { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchUserOrdersByTrackingCode } from "../redux/actions/productActions";

function TransactionInfoModal({ open, onClose, code }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (code) {
      dispatch(fetchUserOrdersByTrackingCode(code));
    }
  }, [dispatch, code]);

  const order = useSelector((state) => state.userOrdersByTrackingCode);
  const { products } = order;

  if (!open) return null;
  return (
    <>
      <Modal
        className=""
        style={{ overflowY: "scroll", top: 0, right: 0, position: "fixed" }}
        show={open}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Details
          </Modal.Title>
          <span
            onClick={onClose}
            className="material-icons cursor-pointer font-bold"
          >
            close
          </span>
        </Modal.Header>
        <div className="">
          {products &&
            products?.order?.ordered_items &&
            products?.order?.ordered_items.map((item) => {
              return (
                <Row className="bg-white border-bottom m-1 my-2 p-3 ">
                  <Col xs={12} md={3} className="">
                    <div className="mw-300">
                      <img
                        src={item?.product?.banners[0]?.url}
                        alt="product"
                        className="w-100 "
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={3}>
                    <h5>{item.product?.name}</h5>
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    lg={2}
                    className="flex-on-mobile my-2 quantity "
                  >
                    <p className="">
                      <strong>Quantity:</strong> {item.quantity}
                    </p>
                  </Col>
                  <Col xs={12} md={2} className="my-2 text-blue">
                    <h5>&#8358;{item.product?.amount?.toLocaleString("en")}</h5>
                  </Col>
                </Row>
              );
            })}
          <Row className="bg-white border-bottom m-1 my-2 p-3 "></Row>
        </div>
      </Modal>
    </>
  );
}

export default TransactionInfoModal;
