import React, { useState } from 'react'
import { Alert, Container,  Tab, Tabs } from 'react-bootstrap'
import Footer from '../../../Reusable/Footer'
import Header from '../../../Reusable/Header'
import AssetInformation from '../Personal/AssetInformation'
import SupportingDocuments from '../Personal/SupportingDocuments'
import CompanyProfile from './CompanyProfile'
import DirectorProfile from './DirectorProfile'

function CompanyLeaseApplication() {
    const [key, setKey] = useState("Company Profile")
    const [alert, setAlert] = useState(true)

    return (
        <>
            <div className="bg-light-grey w-100">
                <Container className='py-3' fluid='md'>
                    <Header />
                    <div className="py-4">
                        <h4 className='font-bold'>Corporate Lease Application</h4>
                        <p className='pt-3'>Please ensure that you fill the form correctly (providing every information requested). The complete information is 
                            required to enable us make an informed decision.
                        </p>
                        <Alert show={alert} variant="warning" className='mt-3 w-100'>
                            <div className="d-flex justify-content-between">
                                <p>All fields with <span className="text-red">*</span> are required.</p>
                                <span onClick={() => setAlert(false)} className="material-icons cursor-pointer">close</span>
                            </div>
                        </Alert>
                        <Tabs onSelect={(k) => setKey(k)}  activeKey={key} className='my-4' id="individualapplication" >
                            <Tab eventKey="Company Profile" title="Company Profile">
                                <CompanyProfile onSelect={() => setKey('Director Profile')} />
                            </Tab>
                            <Tab eventKey="Director Profile" title="Director Profile">
                                <DirectorProfile onPrevious={() => setKey('Company Profile')} onSelect={() => setKey('Asset Information')}/>
                            </Tab>
                            <Tab eventKey="Asset Information" title="Asset Information">
                                <AssetInformation onPrevious={() => setKey('Director Profile')} onSelect={() => setKey('Supporting Documents')} />
                            </Tab>
                            <Tab eventKey="Supporting Documents" title="Supporting Documents">
                                <SupportingDocuments onPrevious={() => setKey('Asset Information')} leaseType={'company'}  />
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
                <Footer/> 
            </div>
        </>
    )
}

export default CompanyLeaseApplication
