import React from 'react';
import ReactDOM from 'react-dom';
//import './bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux'
import { ConfigureStore } from './redux/store'

const store = ConfigureStore()

ReactDOM.render(
  
    <Provider store={store}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Provider>,
  document.getElementById('root')
);
