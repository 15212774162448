import * as types from '../types'

const initialState = {}

export const userLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_LOGIN_REQUEST:
            return {
                loading: true
            }
        case types.USER_LOGIN_SUCCESS:
            return {
                loading: false, userInfo: action.payload

            }
        case types.USER_LOGIN_FAIL:
            return {
                loading: false, error: action.payload
            }
        case types.CLEAR_ERRORS:
            return {
                loading: false,
                error: null
            }
        case types.USER_LOGOUT:
            return {}

        default:
            return state;
    }
}

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case types.USER_REGISTER_REQUEST:
            return {
                loading: true
            }
        case types.USER_REGISTER_SUCCESS:
            return {
                loading: false, userInfo: action.payload
            }
        case types.USER_REGISTER_FAIL:
            return {
                loading: false, error: action.payload
            }
        case types.CLEAR_ERRORS:
            return {
                loading: false, error: null
            }
        case types.USER_LOGOUT:
            return {}

        default:
            return state;
    }
}

export const userVerifyReducer = (state = {}, action) => {
    switch (action.type) {
        case types.VERIFY_REQUEST:
            return {
                loading: true
            }
        case types.VERIFY_SUCCESS:
            return {
                loading: false, userInfo: action.payload
            }
        case types.VERIFY_FAIL:
            return {
                loading: false, error: action.payload
            }
        case types.CLEAR_ERRORS:
            return {
                loading: false, error: null
            }
        default:
            return state;
    }
}

export const resendCodeReducer = (state = {}, action) => {
    switch (action.type) {
        case types.RESEND_CODE_REQUEST:
            return {
                loading: true
            }
        case types.RESEND_CODE_SUCCESS:
            return {
                loading: false, userInfo: action.payload
            }
        case types.RESEND_CODE_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}

export const forgotPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case types.FORGOT_PASSWORD_REQUEST:
            return {
                loading: true
            }
        case types.FORGOT_PASSWORD_SUCCESS:
            return {
                loading: false, userInfo: action.payload
            }
        case types.FORGOT_PASSWORD_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}

export const resetPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case types.RESET_PASSWORD_REQUEST:
            return {
                loading: true
            }
        case types.RESET_PASSWORD_SUCCESS:
            return {
                loading: false, userInfo: action.payload
            }
        case types.RESET_PASSWORD_FAIL:
            return {
                loading: false, error: action.payload
            }
        case types.CLEAR_ERRORS:
            return {
                ...state, loading: false, error: null
            }
        default:
            return state;
    }
}

export const createVendorReducer = (state = {}, action) => {
    switch (action.type) {
        case types.CREATE_VENDOR_REQUEST:
            return {
                loading: true
            }
        case types.CREATE_VENDOR_SUCCESS:
            return {
                loading: false, vendor: action.payload
            }
        case types.CREATE_VENDOR_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}
export const getUserProfileReducer = (state = { profile: [] }, action) => {
    switch (action.type) {
        case types.GET_USER_PROFILE_REQUEST:
            return {
                loading: false, profile: action.payload.data
            }
        case types.GET_USER_PROFILE_FAILED:
            return {
                loading: false, error: action.payload.data
            }
        default:
            return state;
    }
}
export const getStatesReducer = (state = { states: [] }, action) => {
    switch (action.type) {
        case types.GET_STATES_REQUEST:
            return {
                loading: true, states: []
            }
        case types.GET_STATES_SUCCESS:
            return {
                loading: false, states: action.payload
            }
        case types.GET_STATES_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}
export const getCountriesReducer = (state = { countries: [] }, action) => {
    switch (action.type) {
        case types.GET_COUNTRIES_REQUEST:
            return {
                loading: true, countries: []
            }
        case types.GET_COUNTRIES_SUCCESS:
            return {
                loading: false, countries: action.payload
            }
        case types.GET_COUNTRIES_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}

export const updateBioReducer = (state = {}, action) => {
    switch (action.type) {
        case types.UPDATE_BIO_REQUEST:
            return {
                load: true
            }
        case types.UPDATE_BIO_SUCCESS:
            return {
                load: false, userBio: action.payload
            }
        case types.UPDATE_BIO_FAIL:
            return {
                load: false, err: action.payload
            }
        default:
            return state;
    }
}
export const updateProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case types.UPDATE_PROFILE_REQUEST:
            return {
                loading: true
            }
        case types.UPDATE_PROFILE_SUCCESS:
            return {
                loading: false, userProfile: action.payload
            }
        case types.UPDATE_PROFILE_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}

export const changePasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case types.CHANGE_PASSWORD_REQUEST:
            return {
                loading: true
            }
        case types.CHANGE_PASSWORD_SUCCESS:
            return {
                loading: false, updatedPassword: action.payload
            }
        case types.CHANGE_PASSWORD_FAIL:
            return {
                loading: false, error: action.payload
            }
        default:
            return state;
    }
}