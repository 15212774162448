import React, { useEffect, useState } from "react";
import { Editor} from "react-draft-wysiwyg";
import { EditorState, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


function RichEditor({onChange, defaultValue}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const description = editorState.getCurrentContent().getPlainText()

  useEffect(() => {
    onChange(description)
  }, [description]);
  return (
    <>
      <div className='bg-light-grey' style={{ minHeight: '300px' }}>
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbar={{
              options: ['inline', 'blockType', 'list', 'textAlign', 'history','colorPicker', 'embedded', 'emoji'],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              emoji: { inDropdown: false },
              colorPicker: { inDropdown: false },
          }}
        />
      </div>
    </>
  );
}

export default RichEditor;