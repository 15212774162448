import React from "react";
import { Table } from "react-bootstrap";
import arrowRight from "../../../Assets/arrow-right.png";
import dayjs from "dayjs";

function RecentUploadsTable({ uploads }) {
  console.log(uploads);

  return (
    <>
      <h5 className="text-bold m-0">Recent Uploads</h5>
      <Table responsive="xl" className="table-spacing">
        <thead>
          <tr>
            <th className="text-muted">Date</th>
            <th className="text-muted">Product Name</th>
          </tr>
        </thead>
        <tbody>
          {uploads &&
            uploads.map((upload) => {
              return (
                <tr key={upload.id}>
                  <td>{dayjs(upload.created_at).format("D MMM YYYY")}</td>
                  <td>{upload.name}</td>
                  <td>&#8358; {upload.amount}</td>
                  {/* <td>
                    <img src={arrowRight} alt="right arrow" />
                  </td> */}
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
}

export default RecentUploadsTable;
