import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Container, Form, Alert } from "react-bootstrap";
import {
  Redirect,
  useHistory,
  useParams,
  useLocation,
  Link,
} from "react-router-dom";
import { addItemToCart } from "../../redux/actions/cartActions";
import {
  getProductAttribute,
  getProductsById,
} from "../../redux/actions/productActions";
import Message from "../Reusable/Message";
import Loader from "../Reusable/Loader";
import UserChoiceModal from "../Payments/Lease/UserChoiceModal";
import { Cookies } from "react-cookie";

// import Swiper core and required modules
import SwiperCore, { Pagination, Scrollbar, Autoplay, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/components/thumbs/thumbs.scss";
//import EligibilityCalculatorModal from '../Payments/Lease/EligibilityCalculatorModal';
import Tippy from "@tippyjs/react";
import { useForm } from "react-hook-form";

// install Swiper modules
SwiperCore.use([Pagination, Scrollbar, Autoplay, Thumbs]);

const ProductInfo = () => {
  const [alert, setAlert] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [amount, setamount] = useState();
  const [initialPayment, setinitialPayment] = useState(0);
  const [leaseBalance, setleaseBalance] = useState("");
  const [periodicPayments, setperiodicPayments] = useState("");
  const [debtServiceRatio, setdebtServiceRatio] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { register, watch } = useForm();

  // GET PRODUCT ID FROM URL
  const product_id = useParams();
  const { id } = product_id;
  //console.log(location)

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  //GET PRODUCT ATTRIBUTES RESPONSE DATA
  const getAttribute = useSelector((state) => state.getAttribute);
  const { attribute } = getAttribute;

  //GET PRODUCT DETAILS BY ID
  const productsById = useSelector((state) => state.getProductsById);
  const { productsbyid } = productsById;

  //GET PRODUCT ATTRIBUTES
  useEffect(() => {
    dispatch(getProductAttribute(id));
    dispatch(getProductsById(id));
    setamount(productsbyid.amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  //GET ADD TO CART RESPONSE DATA
  const cart = useSelector((state) => state.cart);
  const { addToCartData, cartLoading, error } = cart;

  const cookies = new Cookies();

  //ADD ITEM TO CART
  const quantity = productsbyid && productsbyid.quantity;
  console.log(quantity);
  const color = attribute && attribute.color;
  const addToCart = (id, quantity, color) => {
    const token = cookies.get("token");
    if (token) {
      dispatch(addItemToCart(id, 1, color));
    } else {
      history.push({
        pathname: "/login",
        state: { url: location.pathname },
      });
    }
  };

  //SHOW USER CHOICE MODAL
  const handleShowModal = () => {
    setModalState(true);
  };

  //TOGGLE CALCULATOR VISIBILITY
  // const toggleCalculator = () =>{
  //     showCalculator === false ? setshowCalculator(true) : setshowCalculator(false)
  // }

  const paymentPercentage = watch("initialPayment");
  const paymentsPerYear = watch("periodOfPayment");
  const loanRepayment = watch("loanRepayment");
  const monthlyIncome = watch("monthlyIncome");
  let leaseRate = Math.pow(1.04, -paymentsPerYear);
  const assetInfo = {};
  useEffect(() => {
    let initialPayment = (paymentPercentage / 100) * productsbyid?.amount;
    setleaseBalance(productsbyid?.amount - initialPayment);
    setinitialPayment(initialPayment);
    setperiodicPayments(
      Math.round((leaseBalance * 0.04) / parseFloat(1 - leaseRate))
    );
    setdebtServiceRatio(
      (parseInt(periodicPayments) + (parseInt(loanRepayment) || 0)) /
        monthlyIncome
    );
    setamount(productsbyid && productsbyid.amount);
    assetInfo.asset = productsbyid && productsbyid.name;
    assetInfo.price = productsbyid && productsbyid.amount;
    assetInfo.vendor = productsbyid.vendor && productsbyid.vendor.store_name;
    assetInfo.leasePeriod = paymentsPerYear;
    assetInfo.productID = id;
    localStorage.setItem("assetInfo", JSON.stringify(assetInfo));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watch,
    productsbyid,
    paymentPercentage,
    leaseBalance,
    paymentsPerYear,
    periodicPayments,
    loanRepayment,
    monthlyIncome,
    leaseRate,
  ]);

  if (!productsbyid) return <Redirect to="/" />;
  return (
    <>
      {/* <EligibilityCalculatorModal price={productsbyid && productsbyid.amount} open={showCalculator} onClose={()=>setshowCalculator(false)}/> */}
      <UserChoiceModal open={modalState} onClose={() => setModalState(false)} />
      {productsbyid && productsbyid.loanable === true && (
        <Alert show={alert} variant="info" className="mt-3 w-100">
          <div className="d-flex justify-content-between">
            <p>
              Please calculate your eligibility before applying for a lease.
            </p>
            <span
              onClick={() => setAlert(false)}
              className="material-icons cursor-pointer"
            >
              close
            </span>
          </div>
        </Alert>
      )}

      <div className="">
        <div className="d-flex flex-wrap justify-content-between">
          {/* <Breadcrumbs 
                        category = 'Category'
                        subCategory = {location.state.category}
                        products = {location.state.subcategory}
                        product = {productInformation.name}
                        active = {productInformation.name}
                    /> */}
          {/* <div className='my-2 mt-4 d-flex float-right'>
                        <img src={vendor && vendor.banner} alt="user" className='object-fit-cover border-light-grey rounded-circle width-50 height-50' />
                        <span className='font-size-lg text-bold ml-3 mt-2'>{vendor && vendor.store_name}</span>
                    </div> */}
        </div>

        <Container className="mw-400 mx-auto" fluid>
          <Row>
            <Col>
              {addToCartData ? (
                <Message
                  variant={"success"}
                >{`Added ${productsbyid.name} to your cart`}</Message>
              ) : error ? (
                <Message variant={"danger"}>{error || alert}</Message>
              ) : null}
            </Col>
          </Row>
        </Container>
        <Row>
          <Col xs={12} md={4}>
            <Swiper
              spaceBetween={100}
              slidesPerView={1}
              centeredSlides
              autoplay={{ delay: 3000 }}
              speed={3000}
              pagination={{ clickable: true }}
              thumbs={{ swiper: thumbsSwiper }}
              className="product-slide"
            >
              {productsbyid.banners &&
                productsbyid.banners.map((image, i) => {
                  return (
                    <SwiperSlide key={image.id} className="">
                      <div className="mw-500 m-auto pt-4">
                        <img
                          src={image.url}
                          alt=""
                          className=" w-100 block m-auto"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Col>
          <Col xs={12} md={3} className="thumbs-column">
            <Swiper
              id="thumbs"
              onSwiper={setThumbsSwiper}
              spaceBetween={5}
              slidesPerView={3}
              className="thumbs-wrapper"
            >
              {productsbyid.banners &&
                productsbyid.banners.map((image, i) => {
                  return (
                    <SwiperSlide key={image.id} className=" height-200">
                      <div className="mw-200 py-4">
                        <img src={image.url} alt="" className="w-100" />
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Col>
          <Col xs={12} md={5} className="flex-wrap mt-3 border-left-white">
            <h4>{productsbyid.name}</h4>
            <div
              dangerouslySetInnerHTML={{ __html: productsbyid?.description }}
            ></div>
            {attribute && attribute.condition && (
              <div className="d-flex">
                <p className="text-bold">Status:&nbsp;</p>
                <span> {attribute.condition}</span>
              </div>
            )}
            {attribute && attribute.year_of_make && (
              <div className="d-flex">
                <p className="text-bold">Year of make:&nbsp;</p>
                <span>{attribute && attribute.year_of_make}</span>
              </div>
            )}
            <p>
              <span className="text-bold">Payment mode: </span>
              {productsbyid && productsbyid.loanable === false
                ? "Instant"
                : "Installment"}
            </p>
            <h3 className="text-blue">
              &#8358;
              {productsbyid.amount && productsbyid.amount.toLocaleString("en")}
            </h3>
            <p>
              Quantity in stock: (
              {productsbyid.quantity === 1 ? (
                <span className="text-red">out of stock</span>
              ) : (
                productsbyid.quantity
              )}
              )
            </p>
            {attribute && attribute.color && (
              <p>Color: {attribute && attribute.color}</p>
            )}
            {productsbyid.quantity > 1 && (
              <Button
                onClick={() => addToCart(id, quantity, color)}
                className="bg-blue text-white border-blue rounded-3 py-2 px-5 mr-2 "
              >
                <Link
                  to="/payment/instant/"
                  className="text-white text-decoration-none"
                >
                  {" "}
                  Outright purchase{" "}
                </Link>
              </Button>
            )}
            {productsbyid.quantity > 1 && (
              <Button
                onClick={handleShowModal}
                className="bg-blue text-white border-blue rounded-3 py-2 px-5 my-1 mr-2"
              >
                Financing
              </Button>
            )}
            {productsbyid.quantity > 1 && productsbyid.loanable === false ? (
              <Button
                variant={"outline-primary"}
                className="bg-transparent rounded-3 py-2 px-5 my-1"
                type="button"
                onClick={() => addToCart(id, quantity, attribute?.color)}
              >
                {cartLoading ? <Loader /> : "Add to cart"}
              </Button>
            ) : null}
          </Col>
        </Row>
        {productsbyid && productsbyid.loanable === true && (
          <Row className="my-5 d-flex">
            <Col xs={12} md={6} className="my-3 order-2-mobile">
              <h5 className="font-bold">Lease Eligibility Calculator</h5>
              <p className="my-3">
                Please fill the fields below to accurately calculate your lease
                eligibility:
              </p>
              <Form className="mt-4">
                <Form.Group className="mb-3" controlId="product-value">
                  <div className="d-flex">
                    <Form.Label>Asset Value </Form.Label>
                    <Tippy content="This is the selling price of the product">
                      <span className="material-icons cursor-pointer font-size-base mt-1 ml-1">
                        help
                      </span>
                    </Tippy>
                  </div>
                  {productsbyid.amount && (
                    <Form.Control
                      disabled
                      type="text"
                      defaultValue={productsbyid.amount.toLocaleString("en")}
                      className="bg-light-grey"
                    />
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="initial-payment">
                  <div className="d-flex">
                    <Form.Label>Initial Payment (%) </Form.Label>
                    <Tippy content="The initial payment to be made, a percentage of the product value">
                      <span className="material-icons cursor-pointer font-size-base mt-1 ml-1">
                        help
                      </span>
                    </Tippy>
                  </div>
                  <Form.Control
                    {...register("initialPayment")}
                    name="initialPayment"
                    as="select"
                    className="bg-grey"
                  >
                    <option> Select a payment percentage</option>
                    <option value="10"> 10% </option>
                    <option value="20"> 20% </option>
                    <option value="30"> 30% </option>
                    <option value="40"> 40% </option>
                    <option value="50"> 50% </option>
                    <option value="60"> 60% </option>
                    <option value="70"> 70% </option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="monthly-salary">
                  <Form.Label>Monthly Salary</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("monthlyIncome")}
                    name="monthlyIncome"
                    className="bg-grey"
                    placeholder="Enter your monthly income"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="lease-period">
                  <div className="d-flex">
                    <Form.Label>Period of Lease</Form.Label>
                    <Tippy content="The number of months you will be required to make payment for the lease">
                      <span className="material-icons cursor-pointer font-size-base mt-1 ml-1">
                        help
                      </span>
                    </Tippy>
                  </div>
                  <Form.Control
                    value={paymentsPerYear}
                    {...register("periodOfPayment")}
                    as="select"
                    name="periodOfPayment"
                    className="bg-grey"
                  >
                    <option> </option>
                    <option value="6"> 6 Months</option>
                    <option value="12"> 12 Months </option>
                    <option value="18"> 18 Months </option>
                    <option value="24"> 24 Months </option>
                    <option value="36"> 36 Months </option>
                    <option value="48"> 48 Months </option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="repayment-frequency">
                  <div className="d-flex">
                    <Form.Label>Other Lease Repayment</Form.Label>
                    <Tippy content="Other loan repayment you might have with other companies. This is to take into consideration other responsibilities you might have">
                      <span className="material-icons cursor-pointer font-size-base mt-1 ml-1">
                        help
                      </span>
                    </Tippy>
                  </div>
                  <Form.Control
                    type="number"
                    {...register("loanRepayment")}
                    name="loanRepayment"
                    className="bg-grey"
                    placeholder="Enter amount"
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col xs={12} md={6} className="my-3 order-1-mobile border-bottom">
              <div className="border-bottom">
                <h5 className="font-bold mb-4">
                  To pre-qualify, you must be eligible on all of the variables
                  below:
                </h5>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <span className="material-icons text-blue mr-2">
                      task_alt
                    </span>
                    <p>Income</p>
                  </div>
                  {monthlyIncome >= 120000 ? (
                    <p className="font-bold text-green">Eligible</p>
                  ) : (
                    <p className="font-bold text-red">Ineligible</p>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <span className="material-icons text-blue mr-2">
                      task_alt
                    </span>
                    <p>ITI (Income To Installment)</p>
                  </div>
                  {(periodicPayments / monthlyIncome) * 100 <= 35 ? (
                    <p className="font-bold text-green">Eligible</p>
                  ) : (
                    <p className="font-bold text-red">Ineligible</p>
                  )}
                </div>
                <div className="d-flex justify-content-between ">
                  <div className="d-flex">
                    <span className="material-icons text-blue mr-2">
                      task_alt
                    </span>
                    <p>DSR (Debt Service Ratio)</p>
                  </div>
                  {debtServiceRatio * 100 <= 50 ? (
                    <p className="font-bold text-green">Eligible</p>
                  ) : (
                    <p className="font-bold text-red">Ineligible</p>
                  )}
                </div>
              </div>
              <div>
                <h5 className="font-bold my-4">Lease Summary</h5>
                <div className="d-flex justify-content-between">
                  <p>Number of payments (per months)</p>
                  <p className="font-bold ">{paymentsPerYear}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Initial Payment (Amt)</p>
                  <p className="font-bold ">
                    &#8358;
                    {initialPayment ? initialPayment.toLocaleString("en") : 0}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Lease Balance</p>
                  <p className="font-bold ">
                    &#8358;
                    {leaseBalance ? leaseBalance.toLocaleString("en") : 0}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Periodic Payments</p>
                  <p className="font-bold ">
                    &#8358;
                    {periodicPayments && paymentsPerYear
                      ? periodicPayments.toLocaleString("en")
                      : 0}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>DSR (Debt Service Ratio)</p>
                  <p className="font-bold ">
                    {debtServiceRatio
                      ? Math.round(debtServiceRatio * 100)
                      : null}
                    %
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default ProductInfo;
