import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Cart from "../../Payments/Instant/Cart";
import ShippingDetails from "../../Payments/Instant/ShippingDetails";
import Payment from "../../Payments/Instant/Payment";
import TrackOrder from "../../Payments/Instant/TrackOrder";
import NoData from "../../Vendor/Overview/NoData";
// import PaymentSuccess from './PaymentSuccess'

function PaymentNav() {
  const [key, setKey] = useState("Cart");
  const [shippingDetails, setShippingDetails] = useState({
    fee: "",
    city: "",
    landmark_nearest_bus_stop: "",
    address: "",
    state_id: "",
  });
  return (
    <>
      <Tabs
        onSelect={(k) => setKey(k)}
        activeKey={key}
        className="my-4"
        id="payment"
      >
        <Tab eventKey="Cart" title="Cart">
          <Cart onSelect={() => setKey("Shipping")} />
        </Tab>
        <Tab eventKey="Shipping" title="Shipping Details">
          <ShippingDetails
            onSelect={() => setKey("Payment")}
            shippingDetails={shippingDetails}
            setShippingDetails={setShippingDetails}
          />
        </Tab>
        <Tab eventKey="Payment" title="Payment">
          <Payment shippingDetails={shippingDetails} />
        </Tab>
        <Tab eventKey="Track Order" title="Track Order">
          {/* <PaymentSuccess /> */}
          {false ? <NoData time="Current" type="Order" /> : <TrackOrder />}
        </Tab>
      </Tabs>
    </>
  );
}

export default PaymentNav;
