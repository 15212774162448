import React, { useEffect } from 'react'
import { Navbar, NavDropdown, Form } from 'react-bootstrap'
import Logo from '../../Assets/leasehouz-logo.jpg'
import Avatar from '../../Assets/blank-avatar.png'
import Loader from '../Reusable/Loader'


function DashboardHeader({ sidebarToggle, vendorData }) {
  //console.log(vendorData)
 
  if(!vendorData) return null
  return (
    <>
      <Navbar className="d-flex justify-content-between py-4 px-3 w-100" expand="md">
        <Navbar.Brand href="/">
          <img src={Logo} className="logo" alt="Leasehouz-Logo"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="vendor-navbar" onClick={sidebarToggle} />
        {/* {
          collapse === false 
          ? <Navbar.Toggle aria-controls="vendor-navbar" onClick={handleMenu} collapsed={collapse} />
          : <span className='material-icons' onClick={handleMenu}>notifications</span>
        } */}
        
        <Navbar.Collapse id='vendor-navbar' className='justify-content-end' >
          <div className="d-flex flex-wrap justify-content-end">
            {/* <Form className=" mt-3 position-relative order-2-mobile">
              <Form.Control
                type="search"
                placeholder=""
                className="rounded-half bg-lighter-grey px-5 w-100"
                aria-label="Search"
              />
              <span className="material-icons position-absolute overview-search text-dark-grey">search</span>
            </Form> */}
            <div className="d-flex p-1 mt-2 order-1-mobile">
              {/* <span className="material-icons mx-2 notification-icon">notifications</span> */}
              <img src={vendorData.banner} alt="vendor-avatar" className="vendor-avatar mt-1 rounded-circle height-50 width-50" />
              <NavDropdown title={vendorData.store_name} className="text-bold text-center mt-1" id="collapsible-nav-dropdown nav-dropdown">
                <NavDropdown.Item href="/dashboard/profile">Profile</NavDropdown.Item>
                <NavDropdown.Item href="/">Marketplace</NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>    
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default DashboardHeader;
