import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Message from "../Components/Reusable/Message";
import Loader from "../Components/Reusable/Loader";
import { Row, Col, Form } from "react-bootstrap";
import Avatar from "../Assets/blank-avatar.png";
import { getUserProfile } from "../redux/actions/authActions";
import { getStates } from "../redux/actions/authActions";
import { getCountries } from "../redux/actions/authActions";
import { updateBio } from "../redux/actions/authActions";
import { updateProfile } from "../redux/actions/authActions";
import { Cookies } from "react-cookie";
import { useHistory } from "react-router-dom";

function UserProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("token");

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getCountries());
    dispatch(getStates(160));
  }, [dispatch]);

  //FETCH USER PROFILE
  const getProfile = useSelector((state) => state.getProfile);
  const { profile } = getProfile;

  const allStates = useSelector((state) => state.getStates);
  const { states } = allStates;

  const userCountries = useSelector((state) => state.userCountries);
  const { countries } = userCountries;

  const updateadditionalDetailse = useSelector(
    (state) => state.updateadditionalDetailse
  );
  const { userProfile, loading, error } = updateadditionalDetailse;
  console.log(profile);

  const updatepersonalDetails = useSelector(
    (state) => state.updatepersonalDetails
  );
  const { userBio, load, err } = updatepersonalDetails;

  //UPLOAD FILE IMAGE
  const avatarImageChange = (e) => {
    let img = document.querySelector("#avatar");
    img.onload = () => {
      URL.revokeObjectURL(img.src);
    };
    img.src = URL.createObjectURL(e.target.files[0]);
    setpersonalDetails({ ...personalDetails, avatar: e.target.files[0] });
  };

  const avatarInputRef = useRef(null);
  const uploadAvatar = () => {
    avatarInputRef.current.click();
  };

  //SET BIO, PROFILE AND ERROR STATE
  const [errors, setErrors] = useState({});

  const [personalDetails, setpersonalDetails] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    avatar: "",
    email: "",
  });
  const { first_name, last_name, phone, avatar } = personalDetails;

  const [additionalDetails, setadditionalDetails] = useState({
    state_id: "",
    country_id: "",
    address: "",
    next_of_kin_name: "",
    next_of_kin_phone: "",
  });
  const { state_id, country_id, address, next_of_kin_name, next_of_kin_phone } =
    additionalDetails;

  useEffect(() => {
    setpersonalDetails({
      first_name: profile.first_name,
      last_name: profile.last_name,
      phone: profile.phone,
      email: profile.email,
      avatar: profile.avatar,
    });
  }, [profile]);

  useEffect(() => {
    setadditionalDetails({
      country_id: profile?.profile?.country_id,
      state_id: profile?.profile?.state_id,
      address: profile?.profile?.address,
      next_of_kin_name: profile?.profile?.next_of_kin_name,
      next_of_kin_phone: profile?.profile?.next_of_kin_phone,
    });
  }, [profile.profile]);

  //form validation
  const findFormErrors = () => {
    const regPhone = /^([0]{1})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7,8})$/g;
    const newErrors = {};
    if (!first_name || first_name === "")
      newErrors.first_name = "First Name cannot be empty!";

    if (!last_name || last_name === "")
      newErrors.last_name = "Last Name cannot be empty!";

    if (!regPhone.test(phone) || phone === "")
      newErrors.phone = "Phone number field cannot be empty or less than 11!";

    if (!avatar || avatar === "")
      newErrors.avatar = "Please Upload Profile image!";

    return newErrors;
  };

  const findForm2Errors = () => {
    const regPhone = /^([0]{1})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7,8})$/g;
    const newErrors = {};
    if (!address || address === "")
      newErrors.address = "Address cannot be empty!";

    if (!next_of_kin_name || next_of_kin_name === "")
      newErrors.next_of_kin_name = "Next of kin name required!";

    if (!regPhone.test(next_of_kin_phone) || next_of_kin_phone === "")
      newErrors.next_of_kin_phone =
        "Phone number field cannot be empty or less than 11!";

    return newErrors;
  };

  const getFormValues = (e) => {
    setpersonalDetails({ ...personalDetails, [e.target.name]: e.target.value });
    setadditionalDetails({
      ...additionalDetails,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "country_id") {
      dispatch(getStates(e.target.value));
    }

    // Check and see if errors exist and remove them from the error object:
    if (!!errors[e.target.name])
      setErrors({
        ...errors,
        [e.target.name]: null,
      });
  };

  //UPDATE PERSONAL DETAILS
  const updatePersonalDetails = (e) => {
    e.preventDefault();
    let newErrors = findFormErrors();
    if (newErrors.avatar) {
      setErrors(newErrors);
    } else {
      dispatch(updateBio(personalDetails));
    }
  };

  //UPDATE ADDITIONAL INFORMATION
  const updateProfileForm = (e) => {
    e.preventDefault();
    const newErrors = findForm2Errors();
    if (newErrors.length) {
      setErrors(newErrors);
    } else {
      if (!token) {
        history.push({
          pathname: "/login",
          state: {
            url: "/user",
          },
        });
      } else {
        dispatch(
          updateProfile(
            parseInt(state_id),
            parseInt(country_id),
            address,
            next_of_kin_name,
            next_of_kin_phone
          )
        );
      }
    }
  };

  useEffect(() => {
    if (userProfile !== undefined) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile !== undefined]);

  return (
    <>
      <Col>
        <div className="d-flex justify-content-between">
          <div className="avatar-wrapper ml-1">
            <img
              src={profile && profile.avatar !== "" ? profile.avatar : Avatar}
              alt="user-avatar"
              className="width-100 height-100 object-fit-cover opacity-1 rounded-circle "
              id="avatar"
            />
            <div className="camera-icon-wrapper">
              <input
                style={{ display: "none" }}
                type="file"
                ref={avatarInputRef}
                onChange={avatarImageChange}
                name="avatar"
                id=""
              />
              <span className="material-icons" onClick={uploadAvatar}>
                camera_alt
              </span>
            </div>
          </div>
          {errors.avatar ? (
            <Message variant="danger"> {errors.avatar} </Message>
          ) : null}
        </div>
        <hr />

        <Col className="pt-2 px-0">
          <h5 className="text-bold">Personal Details</h5>
          {err ? (
            <Message variant="danger">Failed to update Bio!</Message>
          ) : userBio ? (
            <Message variant="success">Bio updated successfully!</Message>
          ) : null}
          <Form className="mt-4">
            <Row>
              <Col className="pl-0 profile-form-mobile" xs={12} lg={6}>
                <Form.Group as={Col} className="mb-3" id="first-name">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    id="first_name"
                    onChange={getFormValues}
                    value={(profile && profile.first_name) || ""}
                    isInvalid={!!errors.first_name}
                    className="bg-light-grey"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className="mb-3" id="last-name">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    id="last_name"
                    onChange={getFormValues}
                    value={(profile && profile.last_name) || ""}
                    isInvalid={!!errors.last_name}
                    className="bg-light-grey"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="pl-0 profile-form-mobile" xs={12} lg={6}>
                <Form.Group as={Col} className="mb-3" id="email-address">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={getFormValues}
                    value={(profile && profile.email) || ""}
                    isInvalid={!!errors.email}
                    className="bg-light-grey"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className="mb-3" id="phone">
                  <Form.Label> Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    id="phone"
                    onChange={getFormValues}
                    value={(profile && profile.phone) || ""}
                    isInvalid={!!errors.email}
                    className="bg-light-grey"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                  <div className="float-right my-3 ">
                    <button
                      className="bg-blue text-white border-blue rounded-3 py-1 px-4 mt-2"
                      onClick={updatePersonalDetails}
                    >
                      {" "}
                      {load ? <Loader /> : "Save Details"}{" "}
                    </button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Col>
      <Col>
        <h5 className="text-bold"> Additional information</h5>
        {error ? (
          <Message variant="danger">Failed to update profile!</Message>
        ) : userProfile ? (
          <Message variant="success">Profile updated successfully!</Message>
        ) : null}
        <Form className="mt-4">
          <Row>
            <Col className="pl-0 profile-form-mobile" xs={12} lg={6}>
              <Form.Group as={Col} className="mb-3" id="country">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  as="select"
                  name="country_id"
                  value={parseInt(country_id)}
                  className="bg-light-grey p-2 profile-input"
                  onChange={getFormValues}
                >
                  <option>Select country</option>
                  {countries.map((country, id) => {
                    return (
                      <option value={country.id} key={id}>
                        {country.name}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.state}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} className="mb-3" id="state">
                <Form.Label> State</Form.Label>
                <Form.Control
                  as="select"
                  name="state_id"
                  value={parseInt(state_id)}
                  className="bg-light-grey"
                  onChange={getFormValues}
                >
                  <option>Select state</option>
                  {states.map((state, id) => {
                    return (
                      <option value={state.id} key={id}>
                        {state.name}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.country}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="pl-0 profile-form-mobile" xs={12} lg={6}>
              <Form.Group as={Col} className="mb-3" id="address">
                <Form.Label>Next of Kin Name</Form.Label>
                <Form.Control
                  type="text"
                  name="next_of_kin_name"
                  id="NoK"
                  onChange={getFormValues}
                  defaultValue={profile?.profile?.next_of_kin_name || ""}
                  isInvalid={!!errors.next_of_kin_name}
                  placeholder="Enter name of next of kin"
                  className="bg-light-grey p-2 profile-input"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.next_of_kin_name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} className="mb-3" id="next-of-kin-phone">
                <Form.Label> Next of Kin Phone</Form.Label>
                <Form.Control
                  type="tel"
                  name="next_of_kin_phone"
                  id="NoKPhone"
                  onChange={getFormValues}
                  defaultValue={profile?.profile?.next_of_kin_phone || ""}
                  isInvalid={!!errors.next_of_kin_phone}
                  placeholder="Enter next of kin phone number"
                  className="bg-light-grey p-2 profile-input"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.next_of_kin_phone}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} className="mb-3" id="address">
                <Form.Label>Next of Kin Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  id="Address"
                  onChange={getFormValues}
                  defaultValue={profile?.profile?.address || ""}
                  isInvalid={!!errors.address}
                  placeholder="Enter next of kin address"
                  className="bg-light-grey p-2 profile-input"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
                <div className="float-right m-top ">
                  <button
                    className="bg-blue text-white border-blue rounded-3 py-1 px-4"
                    onClick={updateProfileForm}
                  >
                    {loading ? <Loader /> : "Save Additional Info"}
                  </button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Col>
    </>
  );
}

export default UserProfile;
