import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { saveIndividualLeaseData } from "../../../../redux/actions/leaseActions";
import { getShippingInfo } from "../../../../redux/actions/productActions";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    identification_type: yup.string().required().default(""),
  })
  .required();

function AssetInformation({ onPrevious, onSelect }) {
  const [propTenureError, setPropTenureError] = useState("");
  const [payFreqError, setPayFreqError] = useState("");
  const [payDayError, setPayDayError] = useState("");
  const [shippingError, setShippingError] = useState("");

  const dispatch = useDispatch();

  let assetInfo = JSON.parse(localStorage.getItem("assetInfo"));

  const { register, handleSubmit, getValues } = useForm();

  useEffect(() => {
    dispatch(getShippingInfo());
  }, [dispatch]);

  const shippingInfo = useSelector((state) => state.shippingInfo);
  const { shipping } = shippingInfo;

  const onSubmit = (data, e) => {
    // get values one by one
    const { proposed_tenure, payment_frequency, payment_date, shipping_id } =
      getValues();
    if (proposed_tenure === "Select a tenure") {
      setPropTenureError("Please select a tenure");
      return false;
    }
    if (payment_frequency === "Select a tenure") {
      setPayFreqError("Please select a frequency");
      return false;
    }
    if (payment_date === "Select a date") {
      setPayDayError("Please select date");
      return false;
    }
    if (shipping_id === "Select shipping type") {
      setShippingError("Please select shipping type");
      return false;
    }

    saveIndividualLeaseData(data);
    onSelect();
  };
  const onError = (error, e) => {
    alert(error);
    // do something if error occurs
  };

  const days = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

  const percentage = [10, 20, 30, 40, 50, 60, 70];

  return (
    <>
      <div>
        <div className="bg-white py-2 px-4">
          <form onSubmit={(e) => handleSubmit(onSubmit, onError)(e)}>
            <h5 className="my-4 font-bold">Asset Details</h5>
            <Row>
              <Col xs={12} md={6} lg={6} className="py-2">
                <Form.Group className="mb-3" controlId="asset">
                  <Form.Label>Asset Name</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={assetInfo && assetInfo.asset}
                    name="asset"
                    className="bg-light-grey"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label>Selling Price</Form.Label>
                  <Form.Control
                    type="number"
                    {...register("amount")}
                    defaultValue={assetInfo && assetInfo.price}
                    name="amount"
                    className="bg-light-grey"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="repayment-method">
                  <Form.Label>
                    {" "}
                    Deposit Percentage <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    as="select"
                    {...register("deposit_percentage")}
                    name="deposit_percentage"
                    className="bg-light-grey"
                  >
                    <option>Select a percentage</option>
                    {percentage.map((value) => {
                      return (
                        <option defaultValue={value} key={value}>
                          {value}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="Tenure">
                  <Form.Label>
                    {" "}
                    Proposed Tenure (months) <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="Tenure"
                    className="bg-light-grey"
                    {...register("proposed_tenure", { required: true })}
                    onMouseEnter={() => setPropTenureError("")}
                  >
                    <option>Select a tenure</option>
                    <option defaultValue="6">6</option>
                    <option defaultValue="12">12</option>
                    <option defaultValue="18">18</option>
                  </Form.Control>
                  {propTenureError && (
                    <p className="text-red">{propTenureError}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="Payment-Frequency">
                  <Form.Label>
                    {" "}
                    Payment Frequency <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="Payment_Frequency"
                    className="bg-light-grey"
                    {...register("payment_frequency", { required: true })}
                    onMouseEnter={() => setPayFreqError("")}
                  >
                    <option>Select a tenure</option>
                    <option value="monthly">Monthly</option>
                    <option value="quaterly">Quaterly</option>
                  </Form.Control>
                  {payFreqError && <p className="text-red">{payFreqError}</p>}
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={6} className="py-2">
                <Form.Group className="mb-3" controlId="preferred-payment-date">
                  <Form.Label>
                    Preferred Payment Date <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="payment-date"
                    className="bg-light-grey"
                    {...register("payment_date", { required: true })}
                    onMouseEnter={() => setPayDayError("")}
                  >
                    <option>Select a date</option>
                    {days.map((day) => {
                      return (
                        <option value={day} key={day}>
                          {day}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {payDayError && <p className="text-red">{payDayError}</p>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="repayment-method">
                  <Form.Label>
                    {" "}
                    Repayment Method <span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    {...register("payment_channel_id")}
                    name="payment_channel_id"
                    className="bg-light-grey"
                  >
                    <option>Select a repayment method </option>
                    {/* <option value={'3'}>Post-dated Cheques</option>
                                        <option value={'2'}>Payment Order</option> */}
                    <option value={"1"}>Online Payment/Paystack</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="shipping_id">
                  <Form.Label>
                    Preferred Shipping Type<span className="text-red">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="shipping_id"
                    className="bg-light-grey"
                    {...register("shipping_id", { required: true })}
                    onMouseEnter={() => setShippingError("")}
                  >
                    <option>Select shipping type</option>
                    {shipping.map((type) => {
                      return (
                        <option value={type.id} key={type.id}>
                          {type.name} shipping ({type.from} to {type.to}{" "}
                          {type.duration})
                        </option>
                      );
                    })}
                  </Form.Control>
                  {shippingError && <p className="text-red">{shippingError}</p>}
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="insurance">
                                    <Form.Label> Which of the existing insurance covers do you have currently?</Form.Label>
                                    <Form.Control  as="select" name='insurance' className='bg-light-grey'>
                                        <option>Select an insurance</option>
                                        <option value='Life'>Life</option>
                                        <option value="Health">Health</option>
                                        <option value="home">Home</option>
                                        <option value="vehicle">Vehicle</option>
                                        <option value="others">Others</option>
                                        <option value="none">None</option>
                                    </Form.Control>
                                </Form.Group> */}
                <Form.Group className="mb-3" controlId="insurer">
                  <Form.Label>Insurer</Form.Label>
                  <Form.Control
                    type="text"
                    name="asset"
                    className="bg-light-grey"
                    {...register("insurance_cover")}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="insurer">
                  <Form.Label>Additional Instructions</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="asset"
                    className="bg-light-grey"
                    {...register("additional_instructions")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <button
                onClick={onPrevious}
                type="button"
                className="border-0 mr-2 px-4 py-2"
              >
                Previous
              </button>
              <button className="border-0 px-4 py-2">Next</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AssetInformation;
