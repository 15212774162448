import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getProductCategories } from "../../redux/actions/productActions";
import { Link } from "react-router-dom";

const ProductCategories = () => {
  const dispatch = useDispatch();

  // HERE IS WHERE I BROUGHT IN THE CATEGORY STATE FROM THE ACTION. UNCOMMENT TO USE IT THEN REMOVE FROM LINE 3 TO LINE 9
  const getCategories = useSelector((state) => state.getCategories);
  const { categories } = getCategories;

  useEffect(() => {
    if (!categories?.length) dispatch(getProductCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <div className="bg-white">
        <Container className="py-3 pt-4" fluid="md">
          <div className="d-flex justify-content-between ">
            <h3 className="underline-heading">Product Categories</h3>
          </div>
          <Row className="mt-4">
            {categories && categories.length
              ? categories &&
                categories.length &&
                categories.map((item, i) => {
                  return (
                    <Col
                      key={item.id}
                      xs={12}
                      lg={6}
                      className="py-5 w-100 d-flex justify-content-between border-bottom"
                    >
                      <Row className="">
                        <Col xs={6} lg={6}>
                          <Link
                            className="text-decoration-none text-dark-grey"
                            to={{
                              pathname: `/category/${item.name}`,
                              state: { data: item },
                            }}
                          >
                            <h4 className="cursor-pointer">{item.name}</h4>
                          </Link>
                          {item.sub_categories.map((subcategory, index) => {
                            return (
                              <Link
                                key={subcategory.name}
                                className="text-decoration-none text-dark-grey"
                                to={{
                                  pathname: `subcategory/${subcategory.name}`,
                                  state: {
                                    data: categories[i].sub_categories[index],
                                    category: categories[i].name,
                                  },
                                }}
                              >
                                <span className="block">
                                  {subcategory.name}
                                </span>
                              </Link>
                            );
                          })}
                        </Col>
                        <Col xs={6} lg={6}>
                          <img
                            src={item.banner}
                            className="w-100 align-items-center"
                            alt=""
                          />
                        </Col>
                      </Row>
                    </Col>
                  );
                })
              : ""}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProductCategories;
