import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import Loader from "../../Reusable/Loader";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getCartItems } from "../../../redux/actions/cartActions";
import { getUserProfile } from "../../../redux/actions/authActions";
import { getStates } from "../../../redux/actions/authActions";
import {
  outrightPayment,
  leasehouzPayment,
  processLeasehouzPayment,
} from "../../../redux/actions/productActions";
import Message from "../../Reusable/Message";
import { useHistory } from "react-router";
import Paystack from "../../../Assets/paystack-white.png";

const Payment = ({ shippingDetails }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const [isShow, invokeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const closeModal = () => {
    return invokeModal(false);
  };

  useEffect(() => {
    dispatch(getCartItems());
    dispatch(getUserProfile());
    dispatch(getStates(160));
  }, [dispatch]);

  //FETCH CART ITEMS
  const cart = useSelector((state) => state.cart);
  const { cartItems, trackingCode, cartLoading, totalAmount } = cart;

  //FETCH DIRECT PAYMENT RESPONSE DATA
  const directPayment = useSelector((state) => state.directPayment);
  const { payment, load, error } = directPayment;

  // const getLeaseHouzPayment = useSelector((state) => state.directPayment);
  // const { leasehuzpayment, loading, isError } = getLeaseHouzPayment;

  const shippingInfo = useSelector((state) => state.shippingInfo);
  const { shipping } = shippingInfo;

  const paymentChannel = useSelector((state) => state.paymentChannel);
  const { channel } = paymentChannel;

  // const getProfile = useSelector((state) => state.getProfile);
  // const { profile } = getProfile;

  let paystackId = channel && channel[0]?.id;
  let leazePayId = channel && channel[1]?.id;

  let shippingID =
    shipping &&
    shipping.filter((data) => {
      return data.price === parseInt(shippingDetails.fee);
    });
  console.log(shippingID[0]?.id);

  const initiatePaystackPayment = () => {
    const paymentData = {
      payment_channel_id: paystackId,
      tracking_code: trackingCode,
      state_id: parseInt(shippingDetails.state_id),
      city: shippingDetails.city,
      street_address: shippingDetails.address,
      shipping_id: shippingID[0].id,
      landmark_nearest_bus_stop: shippingDetails.landmark_nearest_bus_stop,
      description: shippingDetails.description,
      is_loan: false,
    };
    dispatch(outrightPayment(paymentData));
    console.log(paymentData);
  };

  const initiateLeaseHouzPayment = () => {
    setIsLoading(true);
    const paymentData = {
      payment_channel_id: leazePayId,
      tracking_code: trackingCode,
      state_id: parseInt(shippingDetails.state_id),
      city: shippingDetails.city,
      street_address: shippingDetails.address,
      shipping_id: shippingID[0].id,
      landmark_nearest_bus_stop: shippingDetails.landmark_nearest_bus_stop,
      description: shippingDetails.description,
      is_loan: false,
    };
    dispatch(leasehouzPayment(paymentData));
    console.log(paymentData);
  };

  if (payment?.data?.payment?.url) {
    window.open(payment?.data?.payment?.url, "_self");
  }

  useEffect(() => {
    if (payment?.data?.payment?.banks) {
      setIsLoading(false);
      invokeModal(true);
    }
  }, [payment?.data?.payment?.banks]);

  let banksData = payment?.data?.payment?.banks;

  const onSubmit = (data) => {
    setLoading(true);
    // const boolData = {
    //   is_loan: false,
    // };
    const formData = new FormData();
    formData.append("receipt", data.receipt[0]);
    formData.append("bank_id", data.bank_id);
    formData.append("payment_channel_id", leazePayId);
    formData.append("tracking_code", trackingCode);
    formData.append("state_id", parseInt(shippingDetails.state_id));
    formData.append("city", shippingDetails.city);
    formData.append("street_address", shippingDetails.address);
    formData.append("shipping_id", shippingID[0]?.id);
    // formData.append("is_loan", 0);
    formData.append("description", shippingDetails.description);
    formData.append(
      "landmark_nearest_bus_stop",
      shippingDetails.landmark_nearest_bus_stop
    );
    dispatch(processLeasehouzPayment(formData));
  };

  const getProcessedLeaseHouzPayment = useSelector(
    (state) => state.getProcessedLeaseHouzPayment
  );
  const { processedpayment } = getProcessedLeaseHouzPayment;

  useEffect(() => {
    setTimeout(() => {
      if (processedpayment) {
        setLoading(false);
        if (
          processedpayment.data &&
          processedpayment.data.data.is_deposit === true
        ) {
          history.push("/user/lease");
        } else {
          history.push("/user/transactions");
        }
      }
    }, 2000);
  }, [history, processedpayment]);

  return (
    <>
      <Modal show={isShow}>
        <Modal.Header closeButton onClick={closeModal}>
          <Modal.Title>Pay and upload Proof</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body className="mx-2">
            <div className=" mb-3 d-flex justify-content-between">
              <p className="text-dark font-weight-bold">
                Amount : {payment?.data?.payment?.amount?.toLocaleString("en")}
              </p>
            </div>
            <Form.Group className="mb-3" controlId="bank">
              <Form.Label>Bank </Form.Label>
              <Form.Control
                as="select"
                name="bank_id"
                className="bg-light-grey"
                {...register("bank_id")}
              >
                <option>Select a bank</option>
                {banksData?.map((item) => {
                  return (
                    <option
                      key={item.id}
                      value={item.id}
                      className="text-darkgray"
                    >
                      {item.bank_name} ({item.account_number}{" "}
                      {item.account_name})
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="asset">
              <Form.Label>Upload Proof of Payment</Form.Label>
              <Form.Control
                {...register("receipt")}
                type="file"
                accept=" image/png, image/jpg, image/jpeg"
                name="receipt"
                className="bg-light-grey"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              {Loading ? <Loader /> : "Send Receipt"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {error ? <Message variant="danger">{error}</Message> : null}
      <Row className="bg-white p-3 my-4">
        <Col xs={12} md={6} className="py-2">
          <h5>Order Summary </h5>
          <div className="my-5">
            {cartLoading ? (
              <Loader />
            ) : cartItems && cartItems.length !== 0 ? (
              cartItems.map((item) => {
                return (
                  <div
                    key={item.product_id}
                    className="d-flex justify-content-between mb-4"
                  >
                    <div className="d-flex">
                      <img src={item.banner} alt="" className="mr-3 w-30" />
                      <div>
                        <p className="mb-0">{item.product.name}</p>
                        <p className="mt-2">
                          Quantity:{" "}
                          <span className="text-bold">{item.quantity}</span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-blue text-bold">
                        &#8358;{item.product.amount.toLocaleString("en")}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              ""
            )}
          </div>
          <div className="pay-sum-divider"></div>
          <div className="d-flex justify-content-between my-3">
            <h5>Delivery Details</h5>
            {/* <p><span><img src={pen} alt='Edit pen'/></span>{' '}Edit</p> */}
          </div>
          <div>
            <p>City : {shippingDetails.city} </p>
            <p>Address : {shippingDetails.address} </p>
            <p>Landmark: {shippingDetails.landmark_nearest_bus_stop}</p>
          </div>
        </Col>
        <Col xs={12} md={6} className="py-2 shipping-address">
          <h5>Payment Details</h5>
          <div className="pay-sum-divider mt-5"></div>
          <div className="d-flex justify-content-between">
            <p className="text-muted">Product Total</p>
            <p className="text-muted">
              &#8358;{totalAmount && totalAmount.toLocaleString("en")}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="text-muted">Shipping fee</p>
            <p className="text-muted">
              &#8358;{parseInt(shippingDetails.fee).toLocaleString("en")}
            </p>
          </div>
          <div className="pay-sum-divider"></div>
          <div className="d-flex justify-content-between">
            <p className="text-muted">Total</p>
            <p className="text-muted">
              &#8358;
              {(
                parseInt(totalAmount) + parseInt(shippingDetails.fee)
              ).toLocaleString("en")}
            </p>
          </div>
          <div className="d-flex flex-column my-3">
            <button
              className="border-0 rounded py-3"
              onClick={initiatePaystackPayment}
            >
              {!load ? "Pay with Paystack" : <Loader />}
              <img
                className="ml-3 width-25"
                src={Paystack}
                alt="paystack logo"
              />
            </button>
            <button
              className="d-flex justify-content-center border-0 rounded my-3 py-3"
              onClick={initiateLeaseHouzPayment}
            >
              {!isLoading ? "Pay to Leasehouz" : <Loader />}
              <span className="material-icons ml-3">credit_score</span>
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Payment;
